<template>
    <div class="formation">

        <div class="demande-formation">

        </div>

        <div class="elearning">
            <img src="../assets/logogth.png" class="logo">
            <div class="box">
                <h1>Se connecter</h1>
                <form action="">
                    <label for="Email ou Numéro de téléphone">Email ou Numéro de Téléphone</label>
                    <input type="text" maxlength="128" name="email" v-model="user.email" />
                    <label for="Mot de passe">Mot de passe</label>
                    <input type="text" name="password" v-model="user.password" />
                    <button>Continue</button>
                </form>
            </div>
        </div>

    </div>
</template>
  
<script>
export default {
    name: "FormationView",
    data() {
        return {
            user: {
                email: null,
                password: null
            }
        }
    }
}
</script>
  
<style scoped>
.formation {
    margin : 0;
    padding : 0;
    height: 60vh;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
}

.demande-formation {
    width : 50%;
    height : 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #ddd;
}

.elearning {
    width : 50%;
    height : 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #ddd;
}

.elearning img {
    height: 80px;
    margin-bottom: 10px!important;
}

.elearning .box {
    width : 350px;
    height : 300px;
    border-radius: 8px;
    border: 2px #ddd solid;
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.elearning .box h1 {
    margin-bottom: 10px!important;
    font-size: x-large;
    font-weight: bold;
    font-family: "Amazon Ember",Arial,sans-serif;
}


.elearning .box label {
    margin-bottom: 5px!important;
}

.elearning .box input[type=text], .elearning .box input[type=password] {
    width: 300px;
    height: 40px;
    margin-bottom: 5px!important;
    background-color: #dddddd54;
    border-radius: 5px;
    padding : 5px;
    border: 1px solid;
    outline : 0px;
}

.elearning .box input[type=text]:hover, .elearning .box input[type=password]:hover {
    background-color: white;
    outline: 0px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    outline: 0px;
}


#app > div.formation > div.elearning > div > form > button {
    margin-bottom: 5px!important;
    margin-top: 20px!important;
    background-color: #0057e3;
    color: white;
    font-size: small;
    font-weight: bold;
    font-family: "Amazon Ember",Arial,sans-serif;
    cursor: pointer;
    width: 300px;
    height: 40px;
    border-radius: 5px;
}





</style>
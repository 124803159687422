<template>
  <div>
    <div class="imgg">
      <div class="text-overlay w-full text-center text-white text-3xl font-extrabold">
        <h2> Condidature Spontané GTHCONSULT</h2>
      </div>
    </div>

  <div class="container">
    <p class="text-center text-white">{{ msgCandidature }}</p>

    <div class="w-full Responsive mx-auto mt-10">

      <div class="flex flex-wrap">

        <div class="w-full sm:w-1/2 px-2 mb-4">
          <input type="text" v-model="nom" class="w-full border border-gray-300 rounded-md px-4 py-2" placeholder="Nom">
        </div>

        <div class="w-full sm:w-1/2 px-2 mb-4">
          <input v-model="prenom" type="text" class="w-full border border-gray-300 rounded-md px-4 py-2" placeholder="Prénom">
        </div>

        <div class="w-full sm:w-1/2 px-2 mb-4">
          <input type="email" v-model="email" class="w-full border border-gray-300 rounded-md px-4 py-2" placeholder="Email">
        </div>

        <div class="w-full sm:w-1/2 px-2 mb-4">
          <input type="tel" v-model="telephone" class="w-full border border-gray-300 rounded-md px-4 py-2" placeholder="Téléphone">
        </div>

        <div class="w-full sm:w-1/2 px-2 mb-4">
          <label for="file" class="block mb-2 text-left font-bold text-white">Joindre mon CV <span class="text-red-700">*</span></label>
          <div class="relative">
            <input type="file" multiple="multiple" id="file" ref="file" @change="previewFile">
            <div class="flex items-center justify-center border border-gray-300 bg-white rounded-md px-4 py-2 cursor-pointer">
              <span class="mr-2 text-xs font-bold text-white">Choisir un fichier</span>
              <span class="text-gray-500">{{ file ? file.name : 'Aucun fichier sélectionné' }}</span>
            </div>
          </div>
        </div>

        <div class="w-full px-2 mb-4">
          <textarea v-model="motivation" class="w-full border border-gray-300 rounded-md px-4 py-2" rows="4" placeholder="Votre motivation"></textarea>
        </div>

        <div class="w-full px-2 mb-4">
          <label class="flex items-center">
            <input type="checkbox" class="mr-4">
            <span class="text-white">Oui, accepter les conditions</span>
          </label>
        </div>

        <div class="w-full px-2 mb-4">
          <p class="text-sm text-left text-white">Les données recueillies à l’occasion de l’examen de votre candidature font l’objet d’un traitement informatique conformément à la réglementation relative à la protection des données destiné précisément à la gestion du processus de recrutement des futurs collaborateurs.</p>
          <p class="text-sm text-left mt-4 text-white">Le Groupe GTHCONSULT, en sa qualité de responsable de traitement, collecte et traite les informations présentes sur les documents que vous transmettez sur notre plateforme.</p>
        </div>

        <div class="w-full px-2 grid justify-center">
          <button @click="envoyer()" class="w-64 bg-red-500 hover:bg-red-800 text-white font-bold py-2 px-4 rounded">
            Envoyer ma candidature
          </button>
        </div>

      </div>
    </div>
  </div>

  <loading v-model:active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"/>

</div>
</template>

<script>
import Candidature from '@/connection/Candidature';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  name: "SpontaneCandidature",
  data() {
    return {
      file : null,
      isLoading : false,
      msgCandidature : null
    }
  },

  components : {
    Loading
  },

  methods: {

    envoyer() {
      this.isLoading = true;
      Candidature.envoyer(this.nom, this.prenom, this.telephone, this.email, this.motivation, this.file)
      .then((resutlt) => {
          
          if(resutlt.status == true) {
            this.nom = null;
            this.prenom = null;
            this.telephone = null;
            this.email = null;
            this.motivation = null;
            this.file = null;
            this.isLoading = false;
            this.msgCandidature = resutlt.msg;
          }
      })
      .catch((error) => {
          console.log(error);
      });
    },

    previewFile() {
        this.file = this.$refs.file.files[0];
    }

  }
}
</script>

<style scoped>
*{
  font-family: Cairo,Verdana,Tahoma,Arial;
}
.imgg {
  width: 100%;
  height: 500px;
  overflow:hidden;
  position: relative;
  background-image: url("https://gthpdf.fra1.digitaloceanspaces.com/candidature-min.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
}
.text-overlay {
  padding: 5%;
  margin: auto;
  position: absolute;
  z-index: 999;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: rgb(1, 1, 1, 0.6);
}
.text-overlay > h2 {
color : #56be25;
font-size: 40px;
}
.container {
  height: auto;
  min-width: 100%;
  background: #0057E3 url("https://risk-control.fr/wp-content/uploads/2021/01/Graphisme.svg") no-repeat center right 80px;
  background-size: auto 100%;
  padding: 10px;
  padding-bottom: 20px;
}

.Responsive {
  width: 700px;
}

@media (max-width: 740px) {
  .Responsive {
    width: 100%;
  }
}
</style>

import axios from 'axios'
import config from "@/config"

// class Interlocuteur
class Interlocuteur {

    //--------------------------------------- login Interlocuteur --------------------------------------------
    static login(email, password) {


        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/interlocuteurs/login`,
            {
                email : email,
                password : password
            },
            {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }
    // ------------------------------------------ Profile Interlocuteur ---------------------------------
    static profile(token){
        return new Promise((resolve, reject) => {

            axios.defaults.headers.common["Authorization"] = token;
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+token
                },
            };

            axios.get(`${config.launch}/interlocuteurs/profile`, axiosConfig)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

}

export default Interlocuteur;

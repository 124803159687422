<template>
    <div class="parent">

             <button class="retour" @click="retour">Retour</button>

            <label for="Liste Formations">Liste Formations</label>

            <!-- Start formaions -->
            <select v-model="formateurId">
                <option v-for="formateur in formateurs" :key="formateur._id" :value="formateur._id"> {{ formateur.nom }} {{ formateur.prenom }}</option>
            </select>
            <!-- End formaions -->

            <!-- Start formaions -->
            <select v-model="schemaFormation[0].titre" @change="selectFormation">
                <option v-for="formation in formations" :key="formation._id" :value="formation.titre"> {{ formation.titre }}</option>
            </select>
            <!-- End formaions -->

            <ul v-if="flagFormation">

                <li v-for="i in schemaLecon.length" :key="i">

                    <select v-model="schemaLecon[i - 1].titre">
                        <option v-for="lecon in lecons" :key="lecon._id" :value="lecon.titre"> {{ lecon.titre }}</option>
                    </select>

                    <ul v-if="schemaProgramme[i - 1] != null ">
                        <li v-for="j in schemaProgramme[i - 1].length" :key="j">

                            <select v-model="schemaProgramme[i-1][j-1].titre" @change="selectProgramme">
                                <option v-for="programme in programmes" :key="programme._id" :value="programme.titre"> {{ programme.titre }}</option>
                            </select>

                            <ul class="medias">
                                <li v-for="media in medias" :key="media.titre"><input type="checkbox" @input="checkedMedia(media.titre , i, j)">{{ media.titre }}</li>
                            </ul>

                        </li>
                        <button class="programme" @click="ajouterProgramme(i)">Ajouter Programme</button>
                    </ul>

                </li>

                <button class="lecon" @click="ajouterLecon">Ajouter Lecon</button>

            </ul>

        <!--  Start Confirmation Model   -->
        <ErrorModel v-if="flagErrorModel == true" :text="text" :titre="titre" />
        <!--  End Confirmation Model   -->

        <!--  Start Info Delete Model   -->
        <LandingModel v-if="flagLandingModel == true" />
        <!--  End Info Delete Model   -->

        <button v-if="this.flagFormation" class="enregistre" @click="enregistre">Enregistre</button>

    </div>
</template>
    
<script>

import Formations from "@/connection/Formations";
import Formateurs from "@/connection/Formateurs";
import Programmes from "@/connection/Programmes";
import Medias from "@/connection/Medias";
import ErrorModel from "@/components/models/ErrorModel.vue";
import LandingModel from "@/components/models/LandingModel.vue";
import Lecons from "@/connection/Lecons";
import MesFormations from "@/connection/MesFormations";

export default {
    name: "NouveauFormation",
    data() {
        return {
            content : [null],
            formations: [],
            formation : null,
            flagFormation : false,
            flagLecon: false,
            lecons: [],
            schemaFormation : [{ ref : null, titre : null}],
            schemaLecon : [],
            schemaProgramme : [
                [{ ref : null, refLecon : null, titre : null, medias : [] }]
            ],
            programmes : [],
            medias : [],
            formateurId : null,
            formateurs : []
        }
    },
    components: {
        ErrorModel,
        LandingModel
    },
    methods: {

        checkedMedia(value , i, j) {
           if(this.schemaProgramme[i-1][j-1][0].medias.includes(value)) {
            const index = this.schemaProgramme[i-1][j-1][0].medias.indexOf(value);
            this.schemaProgramme[i-1][j-1][0].medias.splice(index, 1);

           } else {
            this.schemaProgramme[i-1][j-1][0].medias.push(value);
           }
        },

        retour() {
            return this.$router.go(this.$router.currentRoute);
        },

        generateReference() {
            return Math.random().toString(36).slice(-8);
        },

        selectFormation() {

            this.flagFormation = true;
            // ajouter un reference
            this.schemaFormation[0].ref = this.generateReference();

            Lecons.read()
                .then((result) => {

                    // liste formations
                    this.lecons = result.data.lecons;
                    if(this.schemaLecon.length == 0) {

                        this.schemaLecon.push({ ref : null, refFormation : null, titre : null })
                        this.schemaLecon[0].ref = this.generateReference();
                        this.schemaLecon[0].refFormation = this.schemaFormation[0].ref;
                        this.schemaLecon[0].titre = this.lecons[0].titre;


                        Programmes.read()
                        .then((result) => {
                            this.programmes = result.data.programmes;
                            const programme = new Array({ ref : this.generateReference(), refLecon : this.schemaLecon[0].ref, titre : result.data.programmes[0].titre, medias : [] });
                            this.schemaProgramme[0][0] = programme;
                            // console.log("Schema programmes")
                            // console.log(this.schemaProgramme[0][0])
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                        
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

        },

        ajouterLecon() {

            this.schemaLecon.push({ ref : null, refFormation : null, titre : null });
            this.schemaLecon[this.schemaLecon.length - 1].ref = this.generateReference();
            this.schemaLecon[this.schemaLecon.length - 1].refFormation = this.schemaFormation[0].ref;
            this.schemaLecon[this.schemaLecon.length - 1].titre = this.lecons[0].titre;

            const programme = new Array({ ref : this.generateReference(), refLecon : this.schemaLecon[this.schemaLecon.length - 1].ref, titre : this.programmes[0].titre, medias : [] });
            const one = [{ ref : null, refLecon : null, titre : null, medias : [] }];
            this.schemaProgramme.push(one)
            this.schemaProgramme[this.schemaLecon.length - 1][0] = programme;
            // this.schemaProgramme[this.schemaLecon.length - 1][0] = programme;

        },

        ajouterProgramme(i) {
            const programme = new Array({ ref : this.generateReference(), refLecon : this.schemaLecon[i - 1].ref, titre : this.programmes[0].titre, medias : [] });
            const n = String((i- 1));
            this.schemaProgramme[n].push(programme)
        },

        selectProgramme() {
            // console.log(refProgramme);
            Medias.read()
            .then((result) => {
                this.medias = result.data.medias;
            })
            .catch((error) => {
                console.log(error)
            });
        },

        enregistre() {

            MesFormations.create(this.schemaFormation, this.schemaLecon, this.schemaProgramme, this.formateurId)
            .then((result) => {
                if(result.data.success == true) {
                    return this.$router.go(this.$router.currentRoute);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        }
    },
    created() {

        // Formateurs
        Formateurs.read()
        .then((result) => {
            this.formateurs = result.data.formateurs;
        })
        .catch((error) => {
            console.log(error);
        });

        // Formations
        Formations.read()
            .then((result) => {
                // instance
                this.formation = result.data.formations[0].titre;
                // liste formations
                this.formations = result.data.formations;
            })
            .catch((error) => {
                console.log(error)
            });
    }
}
</script>
    
<style scoped>
.parent {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: beige;
}

.parent ul {
    margin-left: 40px;
    margin-top: 40px;
}

.parent ul li{
    display: flex;
    flex-direction: row;
}
.lecon {
    background-color: green;
    color: white;
    padding: 15px;
    margin-top: 5px;
}
.programme {
    background-color: blue;
    color: white;
    padding: 15px;
    margin-top: 5px;
}

select {
    margin-top: 2px;
    margin-bottom: 2px;
}

.retour {
    background-color: red;
    color: white;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 160px;
}

.enregistre {
    background-color: green;
    color: beige;
    padding: 5px;
    width: 120px;
    margin-top: 20px;
    text-align: center;
    
}

.medias {
    width: fit-content;
    height: 150px;
    padding: 5px;
    overflow: auto;
}

</style>
<template>
  <div class="parent">

    <div class="imgg">
      <div class="text-overlay w-full text-center text-white text-3xl font-extrabold">
        <h2>Contrôle d’equipement de travail</h2>
      </div>
    </div>

    <div class="w-full bg-white-100 text-left p-4 ">LE GROUPE <span class="text-orange-700 font-extrabold font">|</span> Contrôle réglementaire et volontaire <span class="text-orange-700 font-extrabold font">|</span> Contrôle d’equipement de travail</div>

    <div class="w-full bg-zinc-100 p-10" id="Objectif">
      <div class=" w-4/6 mx-auto mt-10 text-center text-zinc-500  ">
        <h2 class="titre text-xl font-extrabold text-black mb-10">METTRE EN CONFORMITÉ DE VOS ÉQUIPEMENTS ET INSTALLATIONS TECHNIQUE AU REGARD DE LA RÉGLEMENTATION ET AUX NORMES EN VIGUEURS</h2>
        <p class="resume">Les équipements de travail et les moyens de protection mis en service ou utilisés dans les établissements doivent être équipés, installés, utilisés, réglés et maintenus de manière à préserver la sécurité et la santé des travailleurs.
         Les équipements et les installations techniques doivent faire l’objet de contrôles, de vérifications ou d’inspections selon la réglementation en vigueur pour assurer leurs conformités réglementaires, leurs performances et leurs utilisations en toute sécurité.
        </p>
      </div>
    </div>

    <div class="bottom-div">
      <div class="left-content">
        <ul>
          <li><a href="#" @click="scrollToContent('Objectif')">Objectif</a></li>
          <li><a href="#" @click="scrollToContent('obligation-reglementaire')">Obligation réglementaire</a></li>
          <li><a href="#" @click="scrollToContent('missions')">Les missions</a></li>

        </ul>
      </div>

      <div class="right-content">

        <div id="obligation-reglementaire" class="content-section mb-16">
          <h3 class="text-2xl font-bold text-blue-700 ">CONTRÔLE D’EQUIPEMENT DE TRAVAIL</h3>
          <div>
              <h5 class="font-bold text-slate-800 mt-6 mb-4">Objectif : </h5>
              <div class="w-5/6  text-left text-zinc-500">
                <div class=" text-zinc-400 font-bold mb-2 ">GTHCONSULT vous propose des prestations pour les vérifications des équipements de travail dont l’objectif est : </div> 	
                <div class="flex gap-2 mb-2 items-start">  <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> •  </div>		Assurez la sécurité de vos collaborateurs ;</div> 
                <div class="flex gap-2 mb-2 items-start">  <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> •  </div>   Faire vérifier la conformité de vos équipements ;	</div> 
                
              </div>
         </div>

         <div id="missions">
          <h5 class="font-bold text-slate-800 mt-6 mb-4">Obligation réglementaire : </h5>
          <div class="w-5/6  text-left text-zinc-500">
            <div class="flex gap-2 mb-2 items-start">  <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> -  </div>		Code de travail. </div> 
             </div>
         </div>

         <div>
          <h5 class="font-bold text-slate-800 mt-6 mb-4">Retrouvez ci-dessous l'ensemble des missions afférentes à la conformité des équipements de travail assurées par GTHCONSULT:</h5>
          <div class="w-5/6  text-left text-zinc-500">
            <table class="table-auto border  p-4">
             
              <tbody class="border  p-2">
                <tr v-for="(item, index) in tableDataFifth" :key="index" class="border  p-2 hover:bg-zinc-100 ">
                  <td class="border  p-2 ">{{ item.disignation }}</td>
                  <td class="border  p-2 ">{{ item.code }}</td>
                </tr>
               
              </tbody>
            </table>	 
            </div>
         </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueScrollTo from 'vue-scrollto';

export default {
  
  name: "NosValeurs",
   data() {
    return {
     
      tableData: [
        { disignation: "Vérification réglementaire initial d’installation électrique ", code: "ELEC-VRI" },
        { disignation: "Vérification réglementaire périodique d’installation électrique", code: "	ELEC-VRP" },
        { disignation: "Vérification réglementaire périodique d’installation photovoltaïque", code: "ELEC-VRPPV" },
        { disignation: "Vérification réglementaire périodique d’installation parafoudre - paratonnerre ", code: "ELEC-VRPPP" },
        { disignation: "Vérification de levée des réserves suite vérification réglementaire d’installation électrique", code: "ELEC-VLRVR" },
        { disignation: "Vérification d'installation électrique par thermographie infrarouge sans Q19", code: "ELEC-VTH" },
        { disignation: "Vérification d'installation électrique par thermographie infrarouge avec Q19", code: "ELEC- VTHQ19" },
        { disignation: "Vérification d’installation électrique par analyseur de réseau	ELEC- VAR", code: "v" },
        { disignation: "Diagnostique d’une installation électrique", code: "ELEC- D" },
        { disignation: "Assistance technique installation électrique", code: "ELEC- AT" },
      ],
      tableDataTwo: [
        { disignation: " Vérification réglementaire de mise ou remise en service d’appareils et accessoires de levage ", code: "LEV-VRMRS" },
        { disignation: "Vérification réglementaire périodique d’appareils et accessoires de levage", code: "LEV- VRP" },
        { disignation: "Vérification de levée des réserves suite Vérification réglementaire d’appareils et accessoires de levage", code: "LEV- VLRVR" },
        { disignation: "Diagnostique d’appareils et accessoires de levage	 ", code: "LEV- DIAG" },
       
      ],

      tableDataThre: [
        { disignation: "  Vérification Générale Périodique Trimestriel machine ", code: "MAC-VGPT" },
        { disignation: "Vérification Générale Périodique Annuelle machine", code: "MAC-VGPA" },
        { disignation: "Vérification de la conformité CE machine", code: "MAC-VCCE" },
        { disignation: "Analyses des risques d'une machine	 ", code: "MAC-AR" },
       
      ],

      tableDataFourth: [
        { disignation: "  Vérification Générale Périodique Trimestriel machine ", code: "MAC-VGPT" },
        { disignation: "Vérification Générale Périodique Annuelle machine", code: "MAC-VGPA" },
        { disignation: "Vérification de la conformité CE machine", code: "MAC-VCCE" },
        { disignation: "Analyses des risques d'une machine	 ", code: "MAC-AR" },
       
      ],
      
      tableDataFifth: [
        { disignation: "  Vérification Générale Périodique Trimestriel machine ", code: "MAC-VGPT" },
        { disignation: "Vérification Générale Périodique Annuelle machine", code: "MAC-VGPA" },
        { disignation: "Vérification de la conformité CE machine", code: "MAC-VCCE" },
        { disignation: "Analyses des risques d'une machine	 ", code: "MAC-AR" },
       
      ],
	
	


	
                                                                                                         
                                                                                                                      


    };
  },
  methods: {
    scrollToContent(link) {
      VueScrollTo.scrollTo(`#${link}`, 500, { easing: 'ease-in-out' });
    }
  }
};
</script>

<style scoped>
h5 {
    font-size: 20px;
    color: #2fd820;
}
.titre {
    text-align: center;
    font-size: xx-large;
    font-weight: 700;
    font-size: 1.77777778rem;
    line-height: 120%;
    color: #00153C;
}
.resume {
    font-family: Cairo,Verdana,Tahoma,Arial;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #616161;
    text-align: justify;
}

.badget {
    margin-top: 20px;
    font-family: Cairo,Verdana,Tahoma,Arial;
    font-style: normal;
    font-weight: 200;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0em;
    color: #616161;
}


.imgg {
  width: 100%;
  height: 700px;
  overflow: hidden;
  position: relative;
  background-image: url("https://gthpdf.fra1.digitaloceanspaces.com/man-safety-min.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.text-overlay {
  padding: 5%;
  margin: auto;
  position: absolute;
  z-index: 999;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(1, 1, 1, 0.6);
}

.text-overlay h1 {
    width:fit-content;
}
.text-overlay > h2 {
  color: #56be25;
  font-size: 40px;
}

.bottom-div {
  display: flex;
  flex-wrap: wrap;
}



.left-content {
  width: 25%;
  position: sticky;
  display: grid;
  align-items: center;
  top: 0;
  height: calc(100vh - 30px);
  padding: 10px;
}

.left-content ul{
  background: white;
  padding: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}.left-content ul li a{
 font-size: 12px;
}
.left-content ul li {
  padding-left: 12px;
  padding: 5px;
 }
 .left-content ul li:hover {
  background: rgb(235, 234, 234);
 }
 .left-content ul li:hover a{
  color: rgb(84, 72, 253);
 }

.right-content {
  width: 75%;
}

.content-section {
  margin-top: 20px;
}


@media screen and (max-width:1090px) {
  .bottom-div{
    display: grid;
  }
  .left-content{
    width: 80%;
    height: auto;
    margin-top: 50px;
    margin: auto;
    position: relative;
  }
  .right-content{
    width: 80%;
    margin: auto;
  }
  
}
</style>
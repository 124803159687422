<template>
  <div class="parent">

    <div class="imgg">

      <div class="text-overlay w-full text-center text-white text-3xl font-extrabold">
        <h2>Offre emploi détails</h2>
      </div>

    </div>
    
    <div class="content">

        <div class="cont">
            <div class="paragraph">
              <h1>{{ titre }}</h1>
              <p>(MAR) Moroc | (SEN) Sénégal | (TUN) Tunisie | (FRA) France</p>
              <div class="line"></div>
              <p>{{ new Date(date).toLocaleDateString() }}</p>
            </div>
        <div class="description">
   
        <h2>Description</h2>
        <p class="text1">{{ description }}</p>

        <h2>Missions</h2>
        <p class="text3">
          {{ missions }}
        </p>
        <h2>Qualifications:</h2>
        <p class="text4">
          {{ qualifications }}
        </p>
        <h2>Profils</h2>
        <p class="text5">
          {{ profils }}
        </p>

        <h2>Localisation</h2>
        <p class="text5">
          {{ localisation }}
        </p>

        <h2>Salaire</h2>
        <p class="text5">
          {{ salaire }}
        </p>

        <h2>La date limite de candidature</h2>
        <p class="text5">
          {{ new Date(dateLimiteCandidature).toLocaleDateString() }}
        </p>

    </div>

    </div>
      <div class="formulaire">
        <div class="titr" >
          <h2>POSTULER POUR UN EMPLOI</h2>
        </div>

        <div v-if="msgCandidature != null" class="bg-white-900 text-center py-4 lg:px-4 mt-4 mb-4">
          <div class="p-2 bg-green-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
            <span class="font-semibold mr-2 text-left flex-auto">{{ msgCandidature }}</span>
          </div>
        </div>

        <div class="form" v-if="msgCandidature == null">
            <div>
              <ul class="rows">
              <li class="label">
                <label>Nom et Prénom</label>
              </li>
              <li class="inp1">
                <ul class="fullName">
                    <li><input type="text" v-model="nom" placeholder="nom" style="padding-left:10px ;"></li>
                    <li><input type="text" v-model="prenom" placeholder="prenom" style="padding-left:10px ;"></li>
                </ul>
              </li>
            </ul>
            <ul class="rows">
              <li class="label"><label>Email</label></li>
              <li class="input"><input type="email" v-model="email" placeholder="email" style="padding-left:10px ;"></li>
            </ul>
            <ul class="rows">
              <li class="label"><label>Téléphone</label></li>
              <li class="input"><input type="tel" v-model="telephone" placeholder="Téléphone" style="padding-left:10px ;"></li>
            </ul>

            <ul class="rows">
              <li class="label"><label>CV</label></li>
              <li class="input"><input type="file" multiple="multiple" id="file" ref="file" @change="previewFile"></li>
            </ul>

            <button class="boutton" @click="postuler()">POSTULER</button>

            </div>
        </div>
        
      </div>
     
    </div>

    <loading v-model:active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"/>

  </div>
</template>

<script>
import Offres from "@/connection/Offres"
import CandidatureOrganise from "@/connection/CandidatureOrganise"
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';


export default {
    name : "OffreEmploisDetailsView",
    data() {
        return {
          date : null,
          dateLimiteCandidature : null,
          description : null,
          localisation : null,
          missions : null,
          profils :null,
          qualifications : null,
          salaire : null,
          titre : null,
          nom : null,
          prenom : null,
          telephone : null,
          email : null,
          file : null,
          isLoading : false,
          msgCandidature : null
        }
    },

    components : {
      Loading
    },

    methods: {

      previewFile() {
        this.file = this.$refs.file.files[0];
      },

      postuler() {
        this.isLoading = true;
        CandidatureOrganise.postuler(this.nom, this.prenom, this.telephone, this.email, this.file, this.titre)
        .then((resutlt) => {

          console.log(resutlt)

            if(resutlt.status == true) {
              this.nom = null;
              this.prenom = null;
              this.telephone = null;
              this.email = null;
              this.file = null;
              this.isLoading = false;
              this.msgCandidature = resutlt.msg;
            }


        })
        .catch((error) => {
            console.log(error);
        });
      }
      
    },
    mounted() {

      Offres.selectJob(this.$route.params.jobId)
      .then((result) => {

          this.date = result.data.date;
          this.dateLimiteCandidature = result.data.dateLimiteCandidature;
          this.description = result.data.description;
          this.localisation = result.data.localisation;
          this.missions = result.data.missions;
          this.profils = result.data.profils,
          this.qualifications = result.data.qualifications;
          this.salaire = result.data.salaire;
          this.titre = result.data.titre;
      })
      .catch((error) => {
          console.log(error.message)
      });

    }
}
</script>

<style scoped>
*{
  margin: 0px;
  padding: 0px;
}
    .imgg {
        width: 100%;
        height: 300px;
        overflow:hidden;
        position: relative;
        background-image: url("https://gthpdf.fra1.digitaloceanspaces.com/mentions-legales-min.jpg"); 
        background-size: 100%;
        background-repeat: no-repeat;
    }

    .text-overlay {
        padding: 5%;
        margin: auto;
        position: absolute;
        z-index: 999;
        text-align: center;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background-color: rgb(1, 1, 1, 0.6);
    }
    .text-overlay > h2 {
        color : #56be25;
        font-size: 40px;
    }
    .cont{
      width: 80%;
      margin-left: 10%;
      margin-top: 30px;
      /* border: 1px solid black; */
    }
    
    .paragraph {
      text-align: center;
      width: 60%;
      margin-left: 20%;
      margin-bottom: 120px;
    }
   .paragraph h1{
    color: rgb(36, 48, 100);
    font-size: 25px;
    font-weight: bold;
   } 
   .line{
    height: 1px;
    width: 90%;
    border:1px solid rgb(0,0,0,0.3);
    margin-left:5% ;
    margin-top: 20px;
   }
   .content h2{
    color: rgb(207,31,33);
    font-size: 20px;
    font-weight: bold;
    margin: 20px 0px;
   }
   li{
    margin-left: 20px;
   }
   .formulaire{
    margin-top: 40px;
    width: 90%;
    margin-left: 5%;
   }
   .formulaire .titr{
    
     border:none; 
    background-color: rgba(36, 48, 100,0.5);
    height: 70px;
   }
   .titr h2{
    margin: 0px 10px;
    padding: 20px 0px;
   }
   .form{
    padding-top: 10px;
    background-color: whitesmoke;
    padding-bottom: 50px;
   }
   .form input{
    /* border: 2px solid rgba(207,31,33,0.4); */
    border-radius: 5px;
    height: 40px;
    margin-top: 10px;
   }
   .label{
    width: 100px;
    margin-top: 10px;
   }
   .input,.inp1{
    width:600px;
   }
   .input{
    padding-left: 20px;
    
   }
   .input input{
    width: 79%;
   }
   .form .rows{
    display: flex;
    flex-direction: row;
   justify-content: center;

   }
   .fullName, .dateTime{
    display: flex;
    flex-direction: row;
  } 
  .dateTime input{
    width: 230px;
  }
  .boutton{
    color: #FFF;
    background-color: rgb(207,31,33);
    width: 130px;
    padding: 5px;
    border-radius: 10px;
    margin-left: 45%;
    margin-top: 50px;
    
  }
  @media (max-width:600px){
    .form .rows{
    display: flex;
    flex-direction: column;
   justify-content: center;

   }
   .input,.inp1{
    width:300px;
   }
   .dateTime{
    display: flex;
    flex-direction: column;
   }
   .boutton{
    margin-left: 25%;
   }
  }
  @media (max-width:900px){
    .form .rows{
    display: flex;
    flex-direction: column;
   justify-content: center;

   }
   .input,.inp1{
    width:500px;
   }
  
   .boutton{
    margin-left: 25%;
   }
  }

</style>
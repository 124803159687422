import axios from 'axios'
import config from "@/config"

// class Messages
class Demandes {

    //--------------------------------------- create Demandes --------------------------------------------
    static envoyer(nom, prenom, telephone, email, etat, moment, objet, motif, numeroAaffaire, ville, message) {

        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/demandes/envoyer`,
            {
                nom : nom,
                prenom : prenom,
                telephone : telephone,
                email : email, 
                etat : etat,
                moment : moment,
                objet : objet, 
                motif : motif,
                numeroAaffaire : numeroAaffaire,
                ville : ville,
                message : message
            },
            {
                headers: {
                     'Content-Type': 'application/json'
                }
             })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });

        })
    }
}


export default Demandes;
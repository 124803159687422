import axios from 'axios'
import config from "@/config"


class Actualites {

    // get Actualites
    static read() {
        return new Promise((resolve, reject) => {
            axios.get(`${config.launch}/actualites/read`, {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    static select(titre) {
        return new Promise((resolve, reject) => {
            axios.get(`${config.launch}/actualites/select/${titre}`,
            {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }
}

export default Actualites;
import axios from 'axios'
import config from "@/config"

class Rapports {

    // get Rapport
    static displayRapport(filename) {
        return new Promise((resolve, reject) => {
            axios.get(`${config.launch}/rapports/display/${filename}`, {
                headers: this.headers,
                responseType: 'blob',
            })
            .then(response => {
                resolve(window.open(URL.createObjectURL(response.data)));
            })
            .catch(error => {
                reject(error.response.data);
            });
        })
    }

    // --------------------------------- Read new Rapports --------------------------------------------
    static readNewRapports(token) {
        return new Promise((resolve, reject) => {

            axios.defaults.headers.common["Authorization"] = token;
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+token
                },
            };

            axios.get(`${config.launch}/rapports/newRapports`, axiosConfig)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        })
    }
    //--------------------------------------------------------------------------------------------------
    // --------------------------------- Read new Rapports --------------------------------------------
    static readRapports(token) {
        return new Promise((resolve, reject) => {

            axios.defaults.headers.common["Authorization"] = token;
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+token
                },
            };

            axios.get(`${config.launch}/rapports/readRapports`, axiosConfig)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        })
    }
    //--------------------------------------------------------------------------------------------------


}


export default Rapports;
<template>
      <div class=' px-6 lg:px-16 py-16 relative' id="How-we-Work">
            <div class="flex flex-col md:flex-row justify-around items-center gap-5">
            <div v-for="ele in data" :key="ele.badge" class="shadow-md rounded-lg px-7  flex items-center flex-col max-w-[400px]" data-aos="flip-down">
                <div class="rounded-full w-max bg-gray-100 p-4 border-white border-[9px] boxs relative ">
                <img :src=ele.imUrl  alt="logo" class="" width="100" height="100"/>
                <p class="absolute top-0 right-0 bg-gradient-to-r from-blue-900 to-red-500 border-white border-[2px]  flex justify-center items-center rounded-full text-white font-[600] w-10 h-10  centred">{{ele.badge}}</p>
                </div>
                <p class="text-center flex items-center gap-3 text-xl font-bold text-blue-900 mt-5">{{ ele.title }}</p>
                <p class="py-5 text-gray-600 text-left  leading-8">{{ele.desc}}</p>

            </div>
            </div>
        </div>
</template>

<script>
export default {
    name : "HowHome",
    data() {
        return {
                data :[{
                    imUrl:'https://gthpdf.fra1.digitaloceanspaces.com/deplacements.png',
                    title:'Nos Déplacements',
                    desc:'GTHCONSULT agit sur tout le territoire marocain, et en Afrique pour répondre à vos besoins en termes des contrôles réglementaires des installations techniques, risque au travail et formations techniques. Nos équipes compétentes travaillent chaque jour pour veiller à la conformité et à la durabilité de votre établissement.',
                    badge:'01',
                },
                {
                    imUrl:'https://gthpdf.fra1.digitaloceanspaces.com/competences.png',
                    title:'Nos Compétences',
                    desc:'Nos équipes compétentes sauront vous écouter et vous conseiller pour assurer la sécurité et la santé de vos personnels. Vous bénéficierez d’un interlocuteur unique et privilégié tout au long du contrôle de vos installations techniques, d’assistante techniques, suivi de votre projet, de réhabilitation, de rénovation ou autre.',
                    badge:'02',
                },
                {
                    imUrl:'https://gthpdf.fra1.digitaloceanspaces.com/certifications.png',
                    title:'Nos Certifications',
                    desc:"Grâce à ses agréments et certifications et reconnaissances extérieures délivrées par les ministères d’emploi et de l'équipement et des transports et de l'énergie et des mines, GTHCONSULT est un bureau de contrôle Agréé par l’état marocain compétent et régulièrement audité pour continuer à améliorer son expertise",
                    badge:'03',
                }]
        }
    }

}
</script>

<style scoped>

</style>
<template>
  <div>
    <div class="imgg">
      <div class="text-overlay w-full text-center text-white text-3xl font-extrabold">
        <h2>{{ titrePrestation }}</h2>
      </div>
    </div>

  <div class="w-full flex justify-center items-center">
    <div class="bg-gray-200 p-8 w-full  shadow-md">
      <div class=" all flex items-center m-auto">
        <div class="leftdiv w-1/2 grid justify-center" data-aos="fade-right">
        </div>

        <div class="bg-white w-1/2 p-10 form" data-aos="fade-left">
        
          <div class="flex flex-col ">
            <div class="grid mb-4 ">
               <div class="flex mb-4 inputs2">
              <input type="text" v-model="name" class="mr-6  py-2 border-b border-gray-300 w-4/6" placeholder="Nom et pénom *">
              <input type="text" v-model="entreprise" class="mr-6  py-2 border-b border-gray-300 w-4/6" placeholder="Entreprise *">
              <input type="text" v-model="location" class=" py-2 border-b border-gray-300 w-4/6" placeholder="Département / Ville /Adresse *">
            </div>
            <div class="flex mb-4 inputs2">
              <input type="text" v-model="email" class="mr-6 py-2 border-b border-gray-300 w-4/6" placeholder="Adresse-email *">
              <input type="text" v-model="codePostal" class="mr-6 py-2 border-b border-gray-300 w-4/6" placeholder="Code postal *">
              <input type="text" v-model="telephone" class=" py-2 border-b border-gray-300 w-4/6" placeholder="Télèphone *">
            </div>
            </div>
           
            <div class="scrollable   text-xl p-2 mb-4 ">
              Demande de devis : {{ titrePrestation }} <br>
              Code mission : {{ codePrestation }} <br>
              
              Type d'inspection: {{ typePrestation }}<br>
              Catégorie : {{ categoriePrestation }}
             </div>
            <div class=" mb-4">
              <div class="flex  ">
              <input type="checkbox" class="mr-2 w-4" :checked="accepterPolitique" :value="accepterPolitique" @click="handelAccepterPolitique()">
              <label for="confidentialite" class="inline"> <span class="text-black"> Important:</span> en soumettant ce formulaire, j'accepte que les informations saisies soient utilisées pour permettre de me recontacter. <span class="text-black"> <a href="/politique-de-confidentialite">Politique de confidentialité </a></span><span class="text-red-500">*</span></label>
             </div>
              </div>
            <div class="captcha flex items-center gap-4">

            <button @click="sendDevis()" class="bg-blue-600 text-white h-16 mb-4 w-44 px-4 rounded-full">Envoyer</button>

          </div>
            <p v-if="flagSend" class="mt-2 text-red-400">
              Votre demande a été envoyée avec succès, veuillez vérifier votre E-mail
            </p>
            <p class="text-xs mt-2 text-zinc-400">
               Note: Champs obligatoires *, et vos informations ne seront utilisées que pour répondre à votre demande et pour vous envoyer une proposition commerciale, si celle-ci a été demandée au préalable. Vous ne serez pas abonné à notre newsletter et nous ne partagerons pas vos données. Pour en savoir plus, veuillez consulter notre "Politique de protection des données personnelles"
            </p>
          </div>
       
        </div>

      </div>
    </div>
  </div>

  <loading v-model:active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"/>
  <ErrorModel :titre="titreError" :text="textError" v-if="flagError" @closeErrorModel="closeErrorModel" @validerErrorModel="validerErrorModel"/>

</div>
</template>

<script>

import Prestations from "@/connection/Prestations"
import Devis from "@/connection/Devis"
import ErrorModel from "@/components/models/ErrorModel.vue"
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  data() {
    return {
      flagSend : false,
      isLoading : false,
      accepterPolitique :false,
      messageError : null,
      flagError : false,
      titrePrestation : null,
      codePrestation : null,
      typePrestation : null,
      categoriePrestation : null,
      name : null,
      entreprise : null,
      location : null,
      email : null,
      codePostal : null,
      telephone : null
    }
  },

  components : {
    ErrorModel,
    Loading
    
  },

  methods : {

    handelAccepterPolitique() {
      this.accepterPolitique = !this.accepterPolitique;
    },

    closeErrorModel() {
      this.flagError = false;
    },

    validerErrorModel() {
      this.flagError =false;
      this.accepterPolitique = true;
    },

    sendDevis() {

        if(this.titrePrestation && this.codePrestation && this.typePrestation && this.categoriePrestation && this.name && this.entreprise && this.location && this.codePostal && this.email && this.telephone && this.accepterPolitique == true) {
          this.flagError = false;
          this.isLoading = true;

            Devis.sendDevis(this.titrePrestation, this.codePrestation, this.typePrestation, this.categoriePrestation, this.name, this.entreprise, this.location, this.codePostal, this.email, this.telephone)
            .then((result) => {

              if(result.data.status === true){

                  this.name = null;
                  this.entreprise = null;
                  this.location = null;
                  this.email = null;
                  this.codePostal = null;
                  this.telephone = null;
                  this.accepterPolitique = false;
                  this.isLoading = false;
                  this.flagSend = true;

              }

            })
            .catch((error) => {
                console.log(error);
            });

        } else {
            this.flagError = true;
            this.titreError = "les champs obligatoires";
            this.textError = "Tous les champs sont obligatoires";
        }
    }
  },

  created() {
    this.titrePrestation = this.$route.params.titre;

    Prestations.getPrestationByTitre(this.titrePrestation)
      .then((result) => {
           this.codePrestation = result.data.prestation.prev;
           this.typePrestation = result.data.prestation.type;
           this.categoriePrestation = result.data.prestation.categorie;
      })
      .catch((error) => {
        console.log(error)
      });
  }
}
</script>

<style scoped>
*{
  font-family: Cairo,Verdana,Tahoma,Arial;
}
.imgg {
  width: 100%;
  height: 300px;
  overflow:hidden;
  position: relative;
  background-image: url("https://gthpdf.fra1.digitaloceanspaces.com/mentions-legales-min.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
}
.text-overlay {
  padding: 5%;
  margin: auto;
  position: absolute;
  z-index: 999;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: rgb(1, 1, 1, 0.6);
}

.text-overlay > h2 {
    color : #56be25;
    font-size: 40px;
  }

.all{
  height: auto;
}
.scrollable {
  width: 97%;
  max-height: 150px; 
  overflow-y: scroll;
  border: 1px solid black;
  scrollbar-width: thin; 
  scrollbar-color: #1a73e8 transparent; 
}
.recaptcha{
  border: 1px solid black;
}

@media screen  and (max-width:1200px) {
  .all{
    display: grid;
  }
  .leftdiv{
    margin: auto;
    width: 100%;
    margin-bottom: 20px;
  }
  .form{
    margin:auto;
    width: 70%;
  }
}
@media screen  and (max-width:980px) {
  .inputs2{
    display: grid;
  }
  .inputs2 input{
    width: 100%;
  }
  .demmande{
    font-size: 40px;
  }
  .captcha{
    display: grid;
  }
  label{
    font-size: 12px;
  }
}
</style>
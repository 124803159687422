<template>
  <div class="home">
    <SliderHome />
    <OurHome />
    <MetiersHome />
    <HowHome />
    <!-- <DescriptionHome /> -->
    <!-- <DifferentHome /> -->
    <CertificatsHome />
    <!-- <ItHome /> -->
    <!-- <NosValeursHome /> -->
    <AgrementsHome />
    <!-- <TemoignagesHome /> -->
    <ContactHome />
    <ActualitesHome />
  </div>
</template>

<script>
// @ is an alias to /src
import MetiersHome from '@/components/componentsHome/MetiersHome.vue'
import SliderHome from '@/components/componentsHome/SliderHome.vue'
// import DescriptionHome from '@/components/componentsHome/DescriptionHome.vue'
import OurHome from '@/components/componentsHome/OurHome.vue'
// import DifferentHome from '@/components/componentsHome/DifferentHome.vue'
import ActualitesHome from '@/components/componentsHome/ActualitesHome.vue'
import ContactHome from '@/components/componentsHome/ContactHome.vue'
import CertificatsHome from '@/components/componentsHome/CertificatsHome.vue'
// import ItHome from '@/components/componentsHome/ItHome.vue'
// import TemoignagesHome from '@/components/componentsHome/TemoignagesHome.vue'
import AgrementsHome from '@/components/componentsHome/AgrementsHome.vue'
// import NosValeursHome from '@/components/componentsHome/NosValeursHome.vue'
import HowHome from '@/components/componentsHome/HowHome.vue'

export default {
  name: 'HomeView',
  components: {
    SliderHome,
    MetiersHome,
    // DescriptionHome,
    OurHome,
    // DifferentHome,
    ActualitesHome,
    ContactHome,
    CertificatsHome,
    // ItHome,
    // TemoignagesHome,
    AgrementsHome,
    // NosValeursHome,
    HowHome

  }
}
</script>

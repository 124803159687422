import axios from 'axios'
import config from "@/config"

// class Messages
class Urls {

    //--------------------------------------- Get url --------------------------------------------
    static read(pathUrl) {


        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/urls/read`,
            {
                pathUrl : pathUrl
            },
            {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }
}

export default Urls;
<template>
  <div class="parent">
    
      <li v-for="job in displayedJobs" :key="job.id">
        <div class="jobs border-2 w-full grid p-4 mb-6 rounded-xl text-left" data-aos="fade-up">
          <div class="flex">
            <div class="img">
              <font-awesome-icon icon="fa-solid fa-briefcase" class="w-12 h-12 rounded-xl mr-8" />
            </div>
            <div class="grid">
              <div class="title text-sm font-bold text-3xl text-slate-900 pl-2">
                <h3>{{ job.titre }} / {{ job.experience }} / {{ job.domaine }}</h3>
              </div>
              <div class="company pl-2 flex gap-2 text-sm text-slate-500 truncate">
               <p>{{ job.description }}</p>
              </div>
            </div>
          </div>
          <div class="flex mt-4 justify-between text-xs items-center">
            <div class="flex gap-3">

              <div class="type bg-red-200 rounded-xl pl-2 pr-2">
                <p>{{ job.typeTravail }}</p>
              </div>

              <div class="remote bg-green-200 rounded-xl pl-2 pr-2">
                <p>Localisation : {{ job.localisation }}</p>
              </div>

              <div class="salary bg-gray-200 rounded-xl pl-2 pr-2">
                <p>Salaire : {{ job.salaire }}</p>
              </div>

              <div class="salary bg-blue-200 rounded-xl pl-2 pr-2">
                <p>Dernier délai le : {{ new Date(job.dateLimiteCandidature).toLocaleDateString() }}</p>
              </div>

             </div>

            <div @click="redericte(job._id)" class="view mr-3  cursor-pointer font-bold">
              Voir les détails <span class="flech">&#10230;</span> 
            </div>
          </div>
       
        </div>
       
        
      </li>

      <div class="pagination flex gap-4">
        <button @click="previousPage" :disabled="currentPage === 1">Précédente</button>
        <button v-for="page in totalPages" :key="page" @click="changePage(page)" :class="{ active: currentPage === page }">{{ page }}</button>
        <button @click="nextPage" :disabled="currentPage === totalPages">Suivant</button>
      </div>
  </div>
</template>

<script>
import Offres from "@/connection/Offres"
export default {
  data() {
    return {
      pageSize: 3,
      currentPage: 1,
      jobs: [],
    };
  },
  props : {

  },
  computed: {
    totalPages() {
      return Math.ceil(this.jobs.length / this.pageSize);
    },
    displayedJobs() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.jobs.slice(start, end);
    }
  },
  methods: {

    redericte(jobId) {
      return this.$router.push(`/offre-emplois-details/${jobId}`)
    },

    changePage(page) {
      this.currentPage = page;
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    }
  },
  mounted() {

        Offres.read()
        .then((result) => {
          result.data.forEach(element => {
              this.jobs.push(element);
          });
        })
        .catch((error) => {
          console.log(error);
        });
  }
};
</script>

<style  scoped>
.view{
  display: none;
}
.jobs:hover .view{
  display: block;
}
.jobs:hover{
  border: 2px solid red;
  border-left: 7px red solid;
}
@media screen  and (max-width:530px){
 
    .jobs{
    width: 85%;
    margin-left: 5%;
   }
   
   }
   @media screen  and (max-width:440px){
 
    h3{
      font-size: 12px;
    }
    p{
      font-size: 10px;
    }
   
   }
   @media screen  and (max-width:340px){
 
    h3{
      font-size: 10px;
      margin-left: 3px;
    }
    p{
      font-size: 8px;
    }
   
   }

</style>
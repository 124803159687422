import axios from 'axios'
import config from "@/config"


// class Messages
class Candidature {

    //--------------------------------------- envoyer Candidature --------------------------------------------
    static envoyer(nom, prenom, telephone, email, motivation, file) {

        var FormData = require('form-data');

        let form = new FormData();

        form.append(`file`, file);
        form.append(`nom`, nom);
        form.append(`prenom`, prenom);
        form.append(`telephone`, telephone);
        form.append(`email`, email);
        form.append(`motivation`, motivation);
    
          
        
        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/candidatures/envoyer`, form,
             {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                    resolve(response.data);
            })
            .catch(error => {
                    reject(error);
            });
    
        });
        
    }
}

export default Candidature;
import axios from 'axios'
import config from "@/config"


// class Messages
class CandidatureOrganise {

    //--------------------------------------- postuler Candidature --------------------------------------------
    static postuler(nom, prenom, telephone, email, file, titre) {

        var FormData = require('form-data');

        let form = new FormData();

        form.append(`file`, file);
        form.append(`nom`, nom);
        form.append(`prenom`, prenom);
        form.append(`telephone`, telephone);
        form.append(`email`, email);
        form.append(`titre`, titre);
    
          
        
        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/candidatureOrganises/postuler`, form,
             {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                    resolve(response.data);
            })
            .catch(error => {
                    reject(error);
            });
    
        });
        
    }
}

export default CandidatureOrganise;
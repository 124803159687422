import axios from 'axios'
import config from "@/config"

// class Devis
class Formateurs {

    //--------------------------------------- create Formateurs --------------------------------------------
    static create(formateur) {
        const { nom, prenom, email, password } = formateur;
        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/formateurs/create`,
            {
                nom : nom,
                prenom : prenom,
                email : email,
                password : password
            },
            {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
            .then(response => {
                    resolve(response);
            })
            .catch(error => {
                    reject(error);
            });
        })
    }

    static select(formateurId) {
        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/formateurs/select`,
            {
                formateurId : formateurId,
            },
            {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
            .then(response => {
                    resolve(response);
            })
            .catch(error => {
                    reject(error);
            });
        })
    }

    static read() {
        return new Promise((resolve, reject) => {
            axios.get(`${config.launch}/formateurs/read`, {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    static update(formateur, formateurId) {
        const { nom, prenom, email, password } = formateur;
        return new Promise((resolve, reject) => {
            axios.put(`${config.launch}/formateurs/update`,
            {
                formateurId : formateurId,
                nom : nom,
                prenom : prenom,
                email : email,
                password : password
            },
            {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
            .then(response => {
                    resolve(response);
            })
            .catch(error => {
                    reject(error);
            });
        })
    }

    static desactiver(formateurId) {
        return new Promise((resolve, reject) => {
            axios.put(`${config.launch}/formateurs/desactiver`,
            {
                formateurId : formateurId
            },
            {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
            .then(response => {
                    resolve(response);
            })
            .catch(error => {
                    reject(error);
            });
        })
    }

    static activer(formateurId) {
        return new Promise((resolve, reject) => {
            axios.put(`${config.launch}/formateurs/activer`,
            {
                formateurId : formateurId
            },
            {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
            .then(response => {
                    resolve(response);
            })
            .catch(error => {
                    reject(error);
            });
        })
    }


    static delete(formateurId) {
        return new Promise((resolve, reject) => {
            axios.delete(`${config.launch}/formateurs/delete/${formateurId}`,)
            .then(response => {
                    resolve(response);
            })
            .catch(error => {
                    reject(error);
            });
        })
    }


}


export default Formateurs;

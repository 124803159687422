import axios from 'axios'
import config from "@/config"

// class Messages
class Messages {

    //--------------------------------------- Envoyer Message --------------------------------------------
    static envoyer(nom, email, telephone, message) {

        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/messages/envoyer`,
            {
                nom : nom,
                email : email,
                telephone : telephone,
                message : message
            },
            {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }
}

export default Messages;
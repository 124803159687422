<template>

<div class="container grid ml-10">
  <div v-for="mission in displayedMissions" :key="mission" class="all  mr-20 border-b-2">
  
          <div class="flex  p-4 cursor-pointer contenuu">
              <div class="w-1/3">
                <img src="https://gthpdf.fra1.digitaloceanspaces.com/7.a4904722.jpg" alt="Product Image" class="w-72 h-52 mr-2">
              </div>
              <div class="w-2/3 px-4   content" >
                <h2 class="text-xl font-medium mb-11 mt-6 text-left Titre">{{ mission.titre }}</h2>
                <div class="flex justify-between items-center mb-4  fotterContent">
                  <span class="text-left text-xs w-96  Description">{{ mission.description}}<br></span>
                  <button class=" btn bg-blue-500 text-white px-4 py-2 rounded  ease-in-out duration-300" @click="demandeDevis(mission.titre)">Demander un devis <span class="flech">&#10230;</span> </button>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/> </svg>
                </div>
              </div>
            </div>
            
  </div>
  <div class="pagination flex gap-4">
    <button @click="previousPage" :disabled="currentPage === 1">Précédente</button>
    <button v-for="page in totalPages" :key="page" @click="changePage(page)" :class="{ active: currentPage === page }">{{ page }}</button>
    <button @click="nextPage" :disabled="currentPage === totalPages">Suivant</button>
  </div>
  
</div>
</template>

<script>
import Prestations from "@/connection/Prestations";
  export default{
  data(){
      return {
        pageSize: 10,
        currentPage: 1,
        missions : [],
      };
  },
  computed: {

    totalPages() {
      return Math.ceil(this.missions.length / this.pageSize); 
    },

    displayedMissions() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.missions.slice(start, end); 
    }

  },

  methods: {

    demandeDevis(titre) {
        return this.$router.push(`/demande-devis/${titre}`)
    },

    changePage(page) {
      this.currentPage = page; 
    },

    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--; 
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++; 
      }
    }

  },

  created() {


      //for create new liste prestation or missions
      // Prestations.insert(this.missions)
      // .then((result) => {
      //         console.log(result)
      // })
      // .catch((error) => {
      //         console.log(error);
      // });



      Prestations.read()
      .then((result) => {
          this.missions = result.data.prestations;
      })
      .catch((error) => {
          console.log(error);
      });

  }
};
</script>

<style scoped>

       
       svg{
          display: none;
       }
       .btn{
          display: flex;
          transition: 0.6s;
       }
      .btn .flech{
          display: none;
          width: 0px;
          margin-left: 5px;
          overflow: hidden;
         
      }
      .btn:hover .flech{
          display: block;
          width:20px;
          color:#1a73e8;
      }
      .Titre{
        color:#1a73e8;
      }
      .btn:hover{
          background-color: white;
          color: #1a73e8;
          border:1px solid #1a73e8;
      }
      @media screen and (max-width: 1480px) {
         .all{
          max-width: 1000px;
          min-width: 600px;
          left: 0;
         }
        }
        @media screen and (max-width: 1335px) {
          .all{
           max-width: 950px;
          }
          .Description{
            width:250px;
          }
          .btn{
            margin-left: -420px;
          }
         }
         @media screen and (max-width: 1235px) {
          .all{
           max-width: 850px;
          }
          img{
            width:190px;
              height: 170px;
          }
          
          .content{
            margin-top:-20px;
            margin-left: -20px;
          }
          .content .btn{
           margin-right: -60px;
          }
         }
         @media screen and (max-width: 1135px) {
          .all{
           width: 650px;
           
           margin-right: 20px;
           margin-left: 20px;
         
          }
          img{
            width:130px;
              height: 120px;
          }
          .content{
            margin-top: 0px;
           
          }
         .fotterContent{
          margin-top: -20px;
          margin-left: -40px;
         
         }
         .Titre{
          margin-top: -3px;
          font-size: 16px;
          margin-left: -40px;
         }
         }
         @media screen and (max-width:1101px) {
          .container{
            width: 1000px;
            padding: 0;
            margin: 0;
          }
          .all{
           min-width: 1000px;
           margin:auto;
           float: none;
          padding: 0;
         
         }
         .all:hover{
          background: white;
         }
         img{
            width:160px;
              height: 150px;
        
          margin-left: 40px;
         }
         svg{
          display: block;
          width:70px;
          opacity: 0.4;
       }
         .fotterContent{
          display: flex;
          margin-top: 20px;
         }
         .fotterContent button{
          display: none;
         }
         }
         @media screen and (max-width: 1040px) {
          .all{
           min-width:900px;
         
         }
         }
         @media screen and (max-width: 980px) {
          .all{
           min-width:800px;
         margin: auto;
         }
         }
         @media screen and (max-width: 900px) {
          .all{
           min-width:700px;
        
         }
         .content{
          margin-left: 40px;
         }
         }
         @media screen and (max-width: 800px) {
          .all{
           width:500px;
        
         }
         img{
          width:140px;
          height: 120px;
            }
          
         }
         @media screen and (max-width: 740px) {
          .all{
           min-width:700px;
        
         }
         .content{
          margin-left: 40px;
         }
         .fotterContent{
          margin-top: -30px;
        }
         }
         @media screen and (max-width: 700px) {
          .all{
            margin-left: -10px;
           min-width:650px;
        
         }
         .fotterContent{
          margin-top: -30px;
        }
         }
         
         
         @media screen and (max-width: 650px) {
          .container{
            width: 600px;
          }
          .all{
            width: 600px;
           min-width:400px;
          
         }
         .Titre , .fotterContent{
          margin-left: 30px;
         }
        
         }
         @media screen and (max-width: 610px) {
          .container{
            width: 530px;
          }
          .all{
            width: 530px;
           min-width:400px;
          
         }
         .Titre , .fotterContent{
          margin-left: 30px;
         }
        
         }
         @media screen and (max-width: 560px) {
          .container{
            width: 430px;
          }
          .all{
            width:450px;
           min-width:300px;
         }
         }
         @media screen and (max-width: 490px) {
          .all{
            width:400px;
           min-width:300px;
         }
         .Description{
          display: none;
         }
         .flech{
          width: 20px;
        }
         .Titre{
          font-size: 14px;
          width:200px;
         }
         .content{
          margin-left:0px;
         }
         }
         @media screen and (max-width: 442px) {
          .container{
            width: 350px;
          }
          .all{
            width:350px;
           min-width:300px;
         }
         img{
          height: 80px;
         }
         }
         @media screen and (max-width: 395px) {
          .container{
            width: 300px;
          }
          .all{
            width:300px;
           min-width:300px;
         }
         }
         @media screen and (max-width: 340px) {
          .container{
            width:250px;
          }
          .all{
            width:250px;
           min-width:200px;
         }
         svg{
          display: none;
         }
         .Titre{
          font-size: 12px;
          width:150px;
         }
         }
        
         
         

</style>






<template>
  <div class="containerFerst">

    <div class="imgg">
      <div class="text-overlay w-full text-center text-white text-3xl font-extrabold">
        <h2>Contrôle réglementaire et volontaire</h2>
      </div>
    </div>

    <div class="titre text-left p-4">Nos services <span class="text-orange-700 font-extrabold font">|</span> Contrôle réglementaire et volontaire</div>

    <div class="top-div mb-24">

      <div class="centered-text grid justify-between p-4">
        <p>Les équipements de travail et les moyens de protection mis en service ou utilisés dans les établissements doivent être équipés, installés, utilisés, réglés et maintenus de manière à préserver la sécurité et la santé des travailleurs.</p>
        <p>Les équipements et les installations techniques doivent faire l’objet de contrôles, de vérifications ou d’inspections selon la réglementation en vigueur pour assurer leurs conformités réglementaires, leurs performances et leurs utilisations en toute sécurité.</p>
      </div>

    </div>
   
      <div class="containerGlobal flex items-center justify-center h-screen" id="metiers">
        <div class="all">
          <div class="container test1">
            <div class="titre p-6 font-bold text-cyan-500 text-3xl">
              <h2>
               <span class="NosBorder">Nos</span> contrôles
              </h2>
            </div>
            <div class="paragraphe p-6 text-xl">
              <p>
                Mettre en conformité de vos équipements et installations technique au regard de la réglementation et aux normes en vigueurs.
              </p>
            </div>
          </div>
          <div v-for="photo in photos" :key="photo.id" @click="pageService(photo.url)">
            <div class="container">
              <div class="image">
                <img :src="photo.src" alt="" />
              </div>
              <div class="content"></div>
              <div class="contenu p-3">
                <div class="divTitre font-bold text-2xl text-sky-50 ml-2">
                  <p>{{ photo.title }}</p>
                </div>
              </div>
              <div class="contenu2">
                <div class="divFooter">
                  <div class="decouvrir">
                    <p class="text-white decouvrirText">DECOUVRIR</p>
                    <p class="plus text-white text-2xl">+</p>
                  </div>
                  <p class="cercle "></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   
  </div>
</template>

<script>
export default {
  data() {
    return {
      photos: [
        {
          id: 1,
          src: "https://gthpdf.fra1.digitaloceanspaces.com/high-voltage-tower-isolated-sky-min.jpg",
          title: "Contrôle réglementaire des installations électriques",
          url : "/controle-reglementaire-des-installations-electriques"
        },
        {
          id: 2,
          src: "https://gthpdf.fra1.digitaloceanspaces.com/construction-site-building-min.jpg",
          title: "Contrôle réglementaire des appareils et accessoires de levage",
          url : "/controle-reglementaire-des-appareils-et-accessoires-de-levage"
        },
        {
          id: 3,
          src: "https://gthpdf.fra1.digitaloceanspaces.com/wind-turbines.jpg",
          title: "Contrôle réglementaire des machines et engins de chantier",
          url : "/controle-reglementaire-des-machines-et-engins-de-chantier"
        },
        {
          id: 4,
          src: "https://gthpdf.fra1.digitaloceanspaces.com/modern-escalator-shopping-center-min.jpg",
          title: "Contrôle reglementaire des ascenseurs escaliers mecanique trottoirs roulantes",
          url : "/controle-reglementaire-des-ascenseurs-escaliers-mecanique-trottoirs-roulantes"
        },
        {
          id: 5,
          src: "https://gthpdf.fra1.digitaloceanspaces.com/fire-alarm-min.jpg",
          title: "Contrôle réglementaire des dispositifs de système incendie",
          url : "/controle-reglementaire-des-dispositifs-de-systeme-incendie"
        },
        {
          id: 6,
          src: "https://gthpdf.fra1.digitaloceanspaces.com/gaz.jpg",
          title: "Contrôle réglementaire des appareils à pression de gaz et vapeur",
          url: "/controle-reglementaire-des-appareils-a-pression-de-gaz-et-vapeur"
        },
        {
          id: 7,
          src: "https://gthpdf.fra1.digitaloceanspaces.com/man-safety-min.jpg",
          title: "Contrôle d'equipement de travail",
          url: "/controle-d-equipement-de-travail"
        }
      ],
    };
  },

  methods : {
    pageService(url) {
      return this.$router.push(url)
    }
  }
};
</script>


<style  scoped>
*{
  font-family: Arial, Helvetica, sans-serif;
}
.imgg {
  width: 100%;
  height: 500px;
  overflow:hidden;
  position: relative;
  background-image: url("https://gthpdf.fra1.digitaloceanspaces.com/7.a4904722.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
}
.borderCenter {
  width:10px;
  height: auto;
}
.text-overlay {
    padding: 5%;
    margin: auto;
    position: absolute;
    z-index: 9;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(1, 1, 1, 0.6);
}
.text-overlay > h2 {
  color : #56be25;
  font-size: 40px;
}
.containerFerst {
  position: relative;
  height: auto;
   margin: auto;
}

.top-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.image-cover {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.image-cover img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.centered-text {
  text-align: center;
  width: 60%;
  height: auto;
  background-color: rgb(255, 255, 255);
  color: #686868;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 200;
}

.centered-text p {
  font-family: Cairo,Verdana,Tahoma,Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #616161;
  text-align: justify;
  margin-top: 10px;
  padding: 10px;
}

h2{
  font-family: Cairo,Verdana,Tahoma,Arial;
}

.containerGlobal {
  width: 100%;
  height: auto;
  margin-top: 30px;

}
.all {
  height: auto;
  width: 1050px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.test1 {
  max-width: 350px;
  min-width: 350px;
}
.NosBorder{
  padding-top: 6px;
  border-top: 4px solid #cf1f21;
}

.paragraphe {
    text-align: justify;
    line-height: 1.2em;
    color: #1a73e8;
    font-weight: bold;
}

.container {
  overflow: hidden;
  flex: 1 0 33.33%;
  position: relative;
  cursor: pointer;
}
.image {
  width: 350px;
  height: 50vh;
}
.image img {
  width: 350px;
  height: 50vh;
  object-fit: cover;
}
.content {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  width: 350px;
  height: 50vh;
  display: flex;
  opacity: 0.3;
}

.contenu {
  position: absolute;
  top: 0;
  left: 0;

  width: 350px;
  height: 300px;
}
.contenu2 {
  position: absolute;
  bottom: 100px;
  
  right:50px;
  width: 350px;
  height: 70px;
}
.divFooter {
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: end;
}
.divFooter .cercle {
  padding: 25px;
  border: 5px solid rgb(179, 179, 179);
  border-radius: 100px;
  opacity: 0.7;
}
.divFooter:hover .cercle {
  border: 5px solid #56BE25;
}

.divFooter:hover .decouvrir .plus {
  -webkit-transform: rotate(600deg);
  -webkit-transition-duration: 1s;
  -webkit-transition-delay: now;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
 
}
.divFooter:hover .decouvrir .decouvrirText {
  padding-right: 5px;
  transition: ease 1s;
  font-weight: bold;
}

.decouvrir {
  font-size: 20px;
  display: flex;
  align-items: center;
  margin-right: -35px;
}
.divTitre {
  width: 300px;
  height: 400px;
}
.container:hover .content {
  opacity: 0.5;
  transition: 0.5s ease;
}
.container:hover .image img {
  transition: 0.5s ease;
  transform: scale(1.1);
}


@media screen and (max-width: 1100px) {
  .containerGlobal{
    margin-top: 70px;
  }
  .centered-text{
    width: 70%;
  }
  
}
@media screen and (max-width: 800px) {
  .containerGlobal{
    margin-top: 170px;
  }
}

@media screen and (max-width: 470px) {
  .containerGlobal{
    margin-top:270px ;
  }
  .centered-text{
    margin-top: 150px;
  }
  text-overlay h2{
    font-size: 18px;
  }
}
@media screen and (max-width: 380px) {
  .containerGlobal{
    margin-top:370px ;
  }
  .centered-text{
    margin-top: 200px;
  }
}
</style>


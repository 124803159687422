<template>
  <div class="parent w-64  grid gap-4 ml-4">
    <div class="socialMedia flex text-left items-center justify-between  bg-zinc-100 hover:bg-zinc-200  cursor-pointer p-4 rounded-xl ">
      <div class="content">
        <h3><a href="https://www.linkedin.com/company/gthconsult/">Suivez-nous sur Linkdeen</a></h3>
        <p class="text-sm text-slate-400">linkedin.com/gthconsult</p>
      </div>
      <div class="icon">
        <svg style="color: blue" xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16"> <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" fill="blue"></path> </svg>
      </div>
     </div>
   <div class="socialMedia flex text-left items-center justify-between  bg-zinc-100 hover:bg-zinc-200  cursor-pointer p-4 rounded-xl ">
    <div class="content">
      <h3><a href="https://discord.gg/RnN4KWA8">Suivez-nous sur Discord</a></h3>
      <p class="text-sm text-slate-400 ">discord.gg/RnN4KWA8</p>
    </div>
    <div class="icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="bi bi-discord" viewBox="0 0 16 16"> <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"/> </svg>
    </div>
   </div>
   <div class="socialMedia flex text-left items-center justify-between  bg-zinc-100 hover:bg-zinc-200  cursor-pointer p-4 rounded-xl ">
    <div class="content">
      <h3><a href="https://twitter.com/gthconsult">Suivez-nous sur Twitter</a></h3>
      <p class="text-sm text-slate-400 ">@gthconsult</p>
    </div>
    <div class="icon">
      <svg style="color: blue" xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16"> <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" fill="blue"></path> </svg>
    </div>
   </div>
  
  </div>
</template>

<script>
export default {
    name : "socialMediaCarrieres"
}
</script>

<style scoped>

svg{
  opacity: .3;
}
@media screen  and (max-width: 1140px){
  .socialMedia{
    width:200px;
  }
  svg{
    margin-left:-30px;
    width:50px;
    height: 50px;
    opacity: .2;
  }
}
@media screen  and (max-width: 1090px){
  .socialMedia{
    width:200px;
  }
 
}
@media screen  and (max-width: 1021px){
  .parent{
    display: flex;
    width: 800px;
  }
  .socialMedia{
    width:400px;
  }
 
}
@media screen  and (max-width: 921px){
  .parent{
    display: flex;
    width: 700px;
  }
  .socialMedia{
    width:300px;
  }
 
}
@media screen  and (max-width: 821px){
  .parent{
    display: grid;
    width: 600px;
  }
  .socialMedia{
    width:500px;
  }
 
}
@media screen  and (max-width: 621px){
  .parent{
    display: grid;
    width: 500px;
  }
  .socialMedia{
    width:500px;
  }
 
}
@media screen  and (max-width: 521px){
  .parent{
    display: grid;
    width: 300px;
  }
  .socialMedia{
    width:300px;
  }
 
}
@media screen  and (max-width: 421px){
  .parent{
    display: grid;
    width: 200px;
  }
  .socialMedia{
    width:200px;
  }
 
}

</style>
import axios from 'axios'
import config from "@/config"

// class Devis
class Programmes {

    //--------------------------------------- create formation --------------------------------------------
    static create(programme) {
        const { titre, auteur } = programme;
        
        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/programmes/create`,
            {
                titre : titre,
                auteur : auteur,
            },
            {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
            .then(response => {
                    resolve(response);
            })
            .catch(error => {
                    reject(error);
            });
        })
    }

    static select(programmeId) {
        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/programmes/select`,
            {
                programmeId : programmeId,
            },
            {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
            .then(response => {
                    resolve(response);
            })
            .catch(error => {
                    reject(error);
            });
        })
    }

    static read() {
        return new Promise((resolve, reject) => {
            axios.get(`${config.launch}/programmes/read`, {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    static update(programme , programmeId) {
        const { titre, auteur } = programme;
        return new Promise((resolve, reject) => {
            axios.put(`${config.launch}/programmes/update`,
            {
                programmeId : programmeId,
                titre : titre,
                auteur : auteur,
            },
            {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
            .then(response => {
                    resolve(response);
            })
            .catch(error => {
                    reject(error);
            });
        })
    }


    static delete(programmeId) {
        return new Promise((resolve, reject) => {
            axios.delete(`${config.launch}/programmes/delete/${programmeId}`,)
            .then(response => {
                    resolve(response);
            })
            .catch(error => {
                    reject(error);
            });
        })
    }


}


export default Programmes;

<template>
  <div class="parent_1">
    <div class="screen_1">
            <img src="https://gthpdf.fra1.digitaloceanspaces.com/plan-min%20(1).jpg">
            <div class="opacity"></div>
            <a href="#">LE GROUPE</a>
            <h1>PLAN DE SITE</h1>
        </div>
        <div class="containt_1">
             <img src="../assets/logogth.png">
            <div class="site_plan">
                <h1>PAGES</h1>
                    <ul class="liste">
                        <li><a href="">À propos de GTH-consult </a></li>
                        <li><a href="">Accueil</a></li>
                        <li><a href="">Actu & Media</a></li>
                        <li><a href="">Contact</a></li>
                        <li><a href="">Espace client</a></li>
                        <li><a href="">Le groupe</a></li>
                        <li><a href="">Nos moyens</a></li>
                        <li><a href="">Nos services</a></li>
                        <li><a href="">Nous rejoindre</a></li>
                        <li><a href="">Agrements</a></li>
                        <li><a href="">Mentions légales</a></li>
                        <li><a href="">Plan du site</a></li>
                        <li><a href="">Politique de confidentialité</a></li>
                        
                    </ul>
            </div>
       
        </div>
  </div>
</template>

<script>
export default {
    name : "PlanSiteView",
    data() {
        return {
            
        }
    }
}
</script>

<style scoped>
*{
    font-family: Cairo,Verdana,Tahoma,Arial;
  }
*{
        margin:0; 
      padding: 0;
      box-sizing: border-box;
   
      text-decoration: none;
      list-style: none;
    }
    .parent_1{
        height:max-content;
        
    }
    .screen_1{
        position: relative;
        height: 80vh;
    }
    .screen_1 img{
        width: 100%;
        height:100%;
    }
    .screen_1 .opacity{
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(36, 48, 100,0.4);
    }
    .screen_1 h1{
        color: #fff;
        position: absolute;
        top: 50%;
        left:35%;
        font-size: 30px;
        font-weight: bold;
    }
    .screen_1 a{
        color:#fff;
        position:absolute;
        top: 45%;
        left: 35%;
        text-transform: uppercase;
        font-size: 14px;
    }
    .screen_1 a:hover{
        border-bottom: 1px solid #fff;   
    }
    .containt_1{
        position: relative;
    }
    .containt_1 img{
        width: 100%;
        position: absolute;
        opacity: 0.1;
        /* right:50%; */
        top: 0;
      z-index: -1;
    }
    .site_plan{
        margin:20px 0px 0px 80px;
        /* position: absolute; */
    }
    .site_plan h1{
        color:rgb(207,31,33);
        margin-bottom: 20px;
        font-size: 27px;
        font-weight: bold;
    }
    .liste li{
        line-height: 40px;
        font-size: 19px;
        font-weight: bold;
    }
    .liste li a{
        color: #616161;
    }
    .liste li a:hover{
        color: rgb(36, 48, 100);
        border-bottom: 2px solid rgb(36, 48, 100);
    } 

</style>
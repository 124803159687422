<template>
    <div class='px-6 md:px-16 py-16' id='actualites'>

        <h2 class='heade'>ACTUS & MÉDIAS</h2>
        <p class='subheade'>Dernières nouvelles et <br class='hidden md:block'/> Meilleures histoires</p>

        <div v-for="item in actualites" :key="item.id" class="grid lg:grid-cols-3 place-items-center gap-y-6" data-aos="zoom-in">

            <!-- <div class=" new bg-[url('https://gthpdf.fra1.digitaloceanspaces.com/1.3cfb99a0.jpg')] h-[450px] relative z-30 opacit w-full md:w-[366px] bg-cover p-8 flex flex-col justify-between">
                <h1 class='bg-blues text-white rounded-md w-max p-1 '>15 JUL, 2021</h1>
                <div class="group">
                    <p class='text-[20px] text-white font-[600]  '>The for fully had she there leave merit enjoy forth.</p>
                    <div class="group-img pt-5 flex items-center text-white font-[300] gap-x-2">
                        <img src="https://gthpdf.fra1.digitaloceanspaces.com/1.3cfb99a0.jpg" alt="" class='rounded-full w-10' />
                        <h1 class='text-[14px]'> BY JOHN BAUS ---- IN CREATIVE</h1>
                    </div>
                </div>
            </div> -->
<!-- 
            <div class="new border h-[450px] relative  w-full md:w-[366px]  p-8 flex flex-col justify-between" data-aos="zoom-in">
                <h1 class='bg-blues text-white rounded-md w-max p-1 '>15 JUL, 2021</h1>
                <p class='font-[700] text-[#0e2258] py-6 text-[20px] '>Impossible admiration in particular conviction up.</p>
                <p class='font-[300] text-neutral-600'>Coming merits and was talent enough far. Sir joy northward sportsmen education. Discovery incommode earnestly no he commanded if. Put still
                    any about manor. Estimable me disposing
                    of be moonlight cordially curiosity.</p>
                    <div class="group-img pt-5 flex items-center text-black font-[300] gap-x-2">
                        <img src="https://gthpdf.fra1.digitaloceanspaces.com/1.3cfb99a0.jpg" alt="" class='rounded-full w-10' />
                        <h1 class='text-[14px]'> BY <span class='font-[400] text-[#0e2258]'>JOHN BAUS</span> ---- IN  <span class='font-[400] text-[#0e2258]'>CREATIVE</span></h1>
                    </div>
            </div> -->

            <!-- <div data-aos="zoom-in" class="new bg-[url('https://gthpdf.fra1.digitaloceanspaces.com/1.3cfb99a0.jpg')] h-[450px] relative opacit w-full md:w-[366px] bg-cover p-8 flex flex-col justify-between">
                <h1 class='bg-purple-950 text-white rounded-md w-max p-1 '>15 JUL, 2021</h1>
                <div class="group">
                    <p class='text-[20px] text-white font-[600] titre'>GTHCONSULT confirme sonengagement dans le système de management de qualité ISO 9001: 2015 avec Le GROUPE AFNOR.</p>
                    <div class="group-img pt-5 flex items-center text-white font-[300] gap-x-2">
                        <img src="https://gthpdf.fra1.digitaloceanspaces.com/1.3cfb99a0.jpg" alt="" class='rounded-full w-10' />
                        <h1 class='auteur text-[14px]'> par Jamal ETTARIQI - Directeur technique</h1>
                    </div>
                </div>
            </div> -->



            <div data-aos="zoom-in" class="new h-[450px] relative opacit w-full md:w-[366px] bg-cover p-8 flex flex-col justify-between">
                <h1 class='bg-purple-950 text-white rounded-md w-max p-1 '>{{ new Date(item.date).toLocaleDateString() }}</h1>
                <img :src="item.img" :alt=item.filename />
                <div class="group">
                    <p class='text-[20px] text-white font-[600] titre'>{{ item.titre }}</p>
                    <div class="group-img pt-2 flex items-center text-white font-[300] gap-x-2">
                        <h1 class='auteur text-[14px]'> par {{ item.auteur }}</h1>
                    </div>
                    <div class="group-img pt-2 flex items-center text-white font-[300] gap-x-2">
                        <h3 class='lire text-[14px]' @click="selectActualite(item.titre)"><a href="javascript:void(0)">Lire la suite</a></h3>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Actualite from "@/connection/Actualite"
export default {
    name : "ActualitesHome",

    data() {
        return {
            actualites : []
        }
    },

    methods : {
        selectActualite(titre) {
            return this.$router.push(`/actualites/${titre}`)
        }
    },

    mounted() {
        Actualite.read()
        .then((result) => {
            result.data.forEach((el) => {
                this.actualites.push({ id : el._id , titre : el.titre, auteur : el.auteur, img : el.location, date : el.date, filename : el.filename })
            });
        })
        .catch((error) => {
            console.log(error);
        });
    }

}
</script>

<style scoped>
*{
    font-family: Cairo,Verdana,Tahoma,Arial;
  }
.heade {
    color: #003478;
    text-align: center;
    font-size: xx-large;
}  

.subheade {

    padding-bottom: 4rem;
    text-align: center;
    font-size: 29px;
    font-weight: 700;
    color: rgb(30 58 138 1);
}

.new {
    cursor: pointer;
}

.titre {
    color: #0e0779;
    filter: drop-shadow(2px 2px 2px #aaa);
}
.auteur {
    color: #0e0779;
    filter: drop-shadow(2px 2px 2px #aaa);
}
.lire {
    color: #f80b0b;
    filter: drop-shadow(2px 2px 2px #aaa);
}

</style>
<template>
    <div class="elearning">

        <div class="menu" v-if="flagMenu">
            <ul>
                <li>Formateurs</li>
                <ul>
                    <li @click="handelNouveauFormateur">Nouveau Formateur</li>
                    <li @click="handelListeFormateurs">Liste des Formateurs</li>
                </ul>
            </ul>
            <ul>
                <li>Les Formations</li>
                <ul>
                    <li @click="handelNouveauFormations">Nouveau Formation</li>
                    <li @click="handelListeFormations">Liste des Formations</li>
                </ul>
            </ul>
            <ul>
                <li>Les Leçons</li>
                <ul>
                    <li @click="handelNouveauLecons">Nouveau Leçon</li>
                    <li @click="handelListeLecons">Liste des Leçons</li>
                </ul>
            </ul>
            <ul>
                <li>Les Programmes</li>
                <ul>
                    <li @click="handelNouveauProgrammes">Nouveau Programme</li>
                    <li @click="handelListeProgrammes">Liste des Programmes</li>
                </ul>
            </ul>
            <ul>
                <li>Les Medias</li>
                <ul>
                    <li @click="handelNouveauMedias">Nouveau Media</li>
                    <li @click="handelListeMedias">Liste des Medias</li>
                </ul>
            </ul>
            <ul>
                <li>Mes Formations</li>
                <ul>
                    <li @click="handelNouveauMesFormation">Nouveau Mes Formation</li>
                    <li @click="handelListeMesFormation">Liste Mes Formations</li>
                </ul>
            </ul>
        </div>

        <div class="display">

            <ListeFormateurs v-if="flagListeFormateurs" />
            <ListeFormations v-if="flagListeFormations" />
            <ListeLecons v-if="flagListeLecons" />
            <ListeProgrammes v-if="flagListeProgrammes" />
            <ListeMedias v-if="flagListeMedias" />
            <ListeMesFormation v-if="flagListeMesFormations" />

            <NouveauFormateur v-if="flagNouveauFormateur" />
            <NouveauFormation v-if="flagNouveauFormation" />
            <NouveauLecon v-if="flagNouveauLecon" />
            <NouveauProgramme v-if="flagNouveauProgramme" />
            <NouveauMedia v-if="flagNouveauMedia" />
            <NouveauMesFormation v-if="flagNouveauMesFormation" />

            <EditFormateur v-if="flagEditFormateur" />
            <EditFormation v-if="flagEditFormation" />
            <EditLecon v-if="flagEditLecon" />
            <EditProgramme v-if="flagEditProgramme" />
            <EditMedia v-if="flagEditMedia" />
            <EditMesFormation v-if="flagEditMesFormation" />


        </div>

    </div>
</template>
  
<script>

import ListeFormateurs from "@/components/componentsEleraning/ListeFormateurs";
import ListeFormations from "@/components/componentsEleraning/ListeFormations";
import ListeLecons from "@/components/componentsEleraning/ListeLecons.vue";
import ListeProgrammes from "@/components/componentsEleraning/ListeProgrammes";
import ListeMedias from "@/components/componentsEleraning/ListeMedias";
import ListeMesFormation from "@/components/componentsEleraning/ListeMesFormation.vue";
import NouveauFormateur from "@/components/componentsEleraning/NouveauFormateur";
import NouveauLecon from "@/components/componentsEleraning/NouveauLecon.vue";
import NouveauFormation from "@/components/componentsEleraning/NouveauFormation";
import NouveauProgramme from "@/components/componentsEleraning/NouveauProgramme";
import NouveauMedia from "@/components/componentsEleraning/NouveauMedia";
import NouveauMesFormation from "@/components/componentsEleraning/NouveauMesFormation.vue";
import EditFormateur from "@/components/componentsEleraning/EditFormateur";
import EditFormation from "@/components/componentsEleraning/EditFormation";
import EditLecon from "@/components/componentsEleraning/EditLecon.vue";
import EditProgramme from "@/components/componentsEleraning/EditProgramme";
import EditMedia from "@/components/componentsEleraning/EditMedia";
import EditMesFormation from "@/components/componentsEleraning/EditMesFormation.vue";


export default {
    name: "ElearningView",
    data() {
        return {
            flagMenu : true,
            flagListeFormateurs: false,
            flagNouveauFormateur: false,
            flagEditFormateur: false,
            flagListeFormations: false,
            flagNouveauFormation: false,
            flagEditFormation: false,
            flagNouveauProgramme: false,
            flagListeProgrammes: false,
            flagEditProgramme: false,
            flagNouveauMedia: false,
            flagListeMedias: false,
            flagEditMedia: false,
            flagNouveauLecon: false,
            flagListeLecons: false,
            flagEditLecon: false,
            flagListeMesFormations: false,
            flagNouveauMesFormation: false,
            flagEditMesFormation: false,
        }
    },

    components: {
    ListeFormateurs,
    ListeFormations,
    ListeLecons,
    ListeMesFormation,
    NouveauFormateur,
    NouveauFormation,
    NouveauLecon,
    EditLecon,
    NouveauProgramme,
    ListeProgrammes,
    NouveauMedia,
    ListeMedias,
    EditFormateur,
    EditFormation,
    EditProgramme,
    EditMedia,
    NouveauMesFormation,
    EditMesFormation
},

    methods: {

        handelNouveauMesFormation() {
            this.flagListeMesFormations = false;
            this.flagMenu = false;
            this.flagNouveauMesFormation = true;
            this.flagEditMesFormation = false;
            this.flagEditMesFormation = false;
            this.flagNouveauLecon = false;
            this.flagListeLecons = false;
            this.flagEditLecon = false;
            this.flagEditFormateur = false;
            this.flagEditFormation = false;
            this.flagEditProgramme = false;
            this.flagEditMedia = false;
            this.flagListeFormateurs = false,
            this.flagNouveauFormateur = false,
            this.flagListeFormations = false,
            this.flagNouveauFormation = false,
            this.flagNouveauProgramme = false,
            this.flagListeProgrammes = false,
            this.flagNouveauMedia = false,
            this.flagListeMedias = false
        },


        handelListeMesFormation() {
            this.flagListeMesFormations = true
            this.flagNouveauMesFormation = false;
            this.flagEditMesFormation = false;
            this.flagEditMesFormation = false;
            this.flagNouveauLecon = false;
            this.flagListeLecons = false;
            this.flagEditLecon = false;
            this.flagEditFormateur = false;
            this.flagEditFormation = false;
            this.flagEditProgramme = false;
            this.flagEditMedia = false;
            this.flagListeFormateurs = false,
            this.flagNouveauFormateur = false,
            this.flagListeFormations = false,
            this.flagNouveauFormation = false,
            this.flagNouveauProgramme = false,
            this.flagListeProgrammes = false,
            this.flagNouveauMedia = false,
            this.flagListeMedias = false
        },

        handelListeLecons() {
            this.flagListeMesFormations = false;
            this.flagNouveauMesFormation = false;
            this.flagEditMesFormation = false;
            this.flagEditMesFormation = false;
            this.flagNouveauLecon = false;
            this.flagListeLecons = true;
            this.flagEditLecon = false;
            this.flagEditFormateur = false;
            this.flagEditFormation = false;
            this.flagEditProgramme = false;
            this.flagEditMedia = false;
            this.flagListeFormateurs = false,
                this.flagNouveauFormateur = false,
                this.flagListeFormations = false,
                this.flagNouveauFormation = false,
                this.flagNouveauProgramme = false,
                this.flagListeProgrammes = false,
                this.flagNouveauMedia = false,
                this.flagListeMedias = false
        },

        handelNouveauLecons() {
            this.flagListeMesFormations = false;
            this.flagNouveauMesFormation = false;
            this.flagEditMesFormation = false;
            this.flagEditMesFormation = false;
            this.flagNouveauLecon = true;
            this.flagListeLecons = false;
            this.flagEditLecon = false;
            this.flagEditFormateur = false;
            this.flagEditFormation = false;
            this.flagEditProgramme = false;
            this.flagEditMedia = false;
            this.flagListeFormateurs = false,
                this.flagNouveauFormateur = false,
                this.flagListeFormations = false,
                this.flagNouveauFormation = false,
                this.flagNouveauProgramme = false,
                this.flagListeProgrammes = false,
                this.flagNouveauMedia = false,
                this.flagListeMedias = false
        },

        handelNouveauFormateur() {
            this.flagListeMesFormations = false;
            this.flagNouveauMesFormation = false;
            this.flagEditMesFormation = false;
            this.flagEditMesFormation = false;

            this.flagNouveauLecon = false;
            this.flagListeLecons = false;
            this.flagEditLecon = false;

            this.flagEditFormateur = false;
            this.flagEditFormation = false;
            this.flagEditProgramme = false;
            this.flagEditMedia = false;

            this.flagListeFormateurs = false,
                this.flagNouveauFormateur = true,
                this.flagListeFormations = false,
                this.flagNouveauFormation = false,
                this.flagNouveauProgramme = false,
                this.flagListeProgrammes = false,
                this.flagNouveauMedia = false,
                this.flagListeMedias = false
        },

        handelListeFormateurs() {
            this.flagListeMesFormations = false;
            this.flagNouveauMesFormation = false;
            this.flagEditMesFormation = false;
            this.flagEditMesFormation = false;

            this.flagNouveauLecon = false;
            this.flagListeLecons = false;
            this.flagEditLecon = false;

            this.flagEditFormateur = false;
            this.flagEditFormation = false;
            this.flagEditProgramme = false;
            this.flagEditMedia = false;

            this.flagListeFormateurs = true,
                this.flagNouveauFormateur = false,
                this.flagListeFormations = false,
                this.flagNouveauFormation = false,
                this.flagNouveauProgramme = false,
                this.flagListeProgrammes = false,
                this.flagNouveauMedia = false,
                this.flagListeMedias = false
        },
        handelNouveauFormations() {
            this.flagListeMesFormations = false;
            this.flagNouveauMesFormation = false;
            this.flagEditMesFormation = false;
            this.flagEditMesFormation = false;

            this.flagNouveauLecon = false;
            this.flagListeLecons = false;
            this.flagEditLecon = false;

            this.flagEditFormateur = false;
            this.flagEditFormation = false;
            this.flagEditProgramme = false;
            this.flagEditMedia = false;

            this.flagListeFormateurs = false,
                this.flagNouveauFormateur = false,
                this.flagListeFormations = false,
                this.flagNouveauFormation = true,
                this.flagNouveauProgramme = false,
                this.flagListeProgrammes = false,
                this.flagNouveauMedia = false,
                this.flagListeMedias = false
        },
        handelListeFormations() {
            this.flagListeMesFormations = false;
            this.flagNouveauMesFormation = false;
            this.flagEditMesFormation = false;
            this.flagEditMesFormation = false;
            this.flagNouveauLecon = false;
            this.flagListeLecons = false;
            this.flagEditLecon = false;

            this.flagEditFormateur = false;
            this.flagEditFormation = false;
            this.flagEditProgramme = false;
            this.flagEditMedia = false;

            this.flagListeFormateurs = false,
                this.flagNouveauFormateur = false,
                this.flagListeFormations = true,
                this.flagNouveauFormation = false,
                this.flagNouveauProgramme = false,
                this.flagListeProgrammes = false,
                this.flagNouveauMedia = false,
                this.flagListeMedias = false
        },
        handelNouveauProgrammes() {
            this.flagListeMesFormations = false;
            this.flagNouveauMesFormation = false;
            this.flagEditMesFormation = false;
            this.flagEditMesFormation = false;
            this.flagNouveauLecon = false;
            this.flagListeLecons = false;
            this.flagEditLecon = false;

            this.flagEditFormateur = false;
            this.flagEditFormation = false;
            this.flagEditProgramme = false;
            this.flagEditMedia = false;

            this.flagListeFormateurs = false,
                this.flagNouveauFormateur = false,
                this.flagListeFormations = false,
                this.flagNouveauFormation = false,
                this.flagNouveauProgramme = true,
                this.flagListeProgrammes = false,
                this.flagNouveauMedia = false,
                this.flagListeMedias = false
        },
        handelListeProgrammes() {

            this.flagListeMesFormations = false;
            this.flagNouveauMesFormation = false;
            this.flagEditMesFormation = false;
            this.flagEditMesFormation = false;
            this.flagNouveauLecon = false;
            this.flagListeLecons = false;
            this.flagEditLecon = false;

            this.flagEditFormateur = false;
            this.flagEditFormation = false;
            this.flagEditProgramme = false;
            this.flagEditMedia = false;

            this.flagListeFormateurs = false,
                this.flagNouveauFormateur = false,
                this.flagListeFormations = false,
                this.flagNouveauFormation = false,
                this.flagNouveauProgramme = false,
                this.flagListeProgrammes = true,
                this.flagNouveauMedia = false,
                this.flagListeMedias = false
        },
        handelNouveauMedias() {

            this.flagListeMesFormations = false;
            this.flagNouveauMesFormation = false;
            this.flagEditMesFormation = false;
            this.flagEditMesFormation = false;
            this.flagNouveauLecon = false;
            this.flagListeLecons = false;
            this.flagEditLecon = false;

            this.flagEditFormateur = false;
            this.flagEditFormation = false;
            this.flagEditProgramme = false;
            this.flagEditMedia = false;

            this.flagListeFormateurs = false,
                this.flagNouveauFormateur = false,
                this.flagListeFormations = false,
                this.flagNouveauFormation = false,
                this.flagNouveauProgramme = false,
                this.flagListeProgrammes = false,
                this.flagNouveauMedia = true,
                this.flagListeMedias = false
        },
        handelListeMedias() {

            this.flagListeMesFormations = false;
            this.flagNouveauMesFormation = false;
            this.flagEditMesFormation = false;
            this.flagEditMesFormation = false;
            this.flagNouveauLecon = false;
            this.flagListeLecons = false;
            this.flagEditLecon = false;

            this.flagEditFormateur = false;
            this.flagEditFormation = false;
            this.flagEditProgramme = false;
            this.flagEditMedia = false;

            this.flagListeFormateurs = false,
                this.flagNouveauFormateur = false,
                this.flagListeFormations = false,
                this.flagNouveauFormation = false,
                this.flagNouveauProgramme = false,
                this.flagListeProgrammes = false,
                this.flagNouveauMedia = false,
                this.flagListeMedias = true
        }
    }
}
</script>
  
<style scoped>
.elearning {
    display: flex;
    width: 100%;
}

.menu {
    width: 15%;
    height: auto;
    margin: 0;
    padding: 0;
    padding: 5px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    background-color: rgb(0 0 0 / 82%);
}


.menu ul {
    padding: 0px;
    padding-left: 10px;
    margin: 0px;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
}

.menu ul li {
    cursor: pointer;
    padding: 3px;
    color: #ddd;
}

.menu ul li:hover {
    color: white;
    background-color: black;
}

.display {
    display: flex;
    width: -webkit-fill-available;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
</style>
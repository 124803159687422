<template>

  <div class="parent">

    <div class="imgg">
      <div class="text-overlay w-full text-center text-white text-3xl font-extrabold">
        <h2>Prélèvements Environnementaux</h2>
      </div>
    </div>

    <div class="w-full bg-white-100 text-left p-4 ">Nos services <span class="text-orange-700 font-extrabold font">|</span> Prélèvements Environnementaux</div>

    <div class="w-full bg-zinc-100 p-10" id="obligation-reglementaire">
      <div class=" w-4/6 mx-auto mt-10 text-center text-zinc-500  ">
        <h2 class="titre text-xl font-extrabold text-black mb-10">METTRE EN CONFORMITÉ DE VOS PRÉLÈVEMENTS ENVIRONNEMENTAUX AU REGARD DE LA RÉGLEMENTATION ET AUX NORMES EN VIGUEURS</h2>
        <p class="resume">Pour répondre à vos besoins, GTHCONSULT met à votre disposition un laboratoire agréé et accrédité sur les analyses environnementales, et des personnels qualifiés intervenants sur le terrain avec des matériels adaptés.
          </p>
      </div>
    </div>

    <div class="bottom-div" id="missions">
      <div class="left-content">
        <ul>
            <li><a href="#" @click="scrollToContent('obligation-reglementaire')">Obligation réglementaire</a></li>
            <li><a href="#" @click="scrollToContent('missions')">Les missions</a></li>
        </ul>
      </div>
      <div class="right-content">

        <div  class="content-section mb-16">
         <div>
              <h5 class="font-bold text-slate-800 mt-6 mb-4">Obligation réglementaire : </h5>
              <div class="w-5/6  text-left text-zinc-500">
                <div class=" text-zinc-400 font-bold mb-2 ">• Décret n° 2-12-431 du 21 moharrem 1435 (25 novembre 2013) fixant les conditions d’utilisation des substances ou préparations susceptibles de porter atteinte à la santé des salariés ou de compromettre leur sécurité. </div> 	
                <div class=" text-zinc-400 font-bold mb-2 ">• Arrêté du ministre à l’emploi et des affaires sociales N°4576-14 du 1 er rabii I 1436 (24 décembre 2014) fixant les valeurs limites d’exposition professionnelle à certains produits chimiques dangereux.</div> 	
                <div class=" text-zinc-400 font-bold mb-2 ">• Arrêté du ministre du travail et de l’insertion professionnelle n°1280-18 du 26 joumada II 1439 (15 mars 2018) fixant les conditions et les modalités de qualification des organismes pour effectuer les mesures de concentration des agents chimiques pouvant présenter un risque pour la santé et la sécurité des salariés.</div> 	
              </div>
         </div>



         <div>
          <h5 class="font-bold text-slate-800 mt-6 mb-4">Retrouvez ci-dessous l'ensemble des missions :</h5>
          <div class="w-5/6  text-left text-zinc-500">
            <table class="table-auto border  p-4">
             
              <tbody class="border  p-2 ">
                <tr v-for="(item, index) in tableDataFifth" :key="index" class="border  p-2 hover:bg-zinc-100 ">
                  <td class="border  p-2 ">{{ item.disignation }}</td>
                  <td class="border  p-2 ">{{ item.code }}</td>
                </tr>
               
              </tbody>
            </table>	 
            </div>
         </div>
        </div>

        
      </div>
    </div>
  </div>
</template>

<script>
import VueScrollTo from 'vue-scrollto';

export default {
  
  name: "NosValeurs",
   data() {
    return {
     
      tableDataFifth: [
        { disignation: "Analyse des rejets liquides", code: "ENVIR-ARL" },
        { disignation: "Analyse des rejets atmosphériques", code: "ENVIR-ARAT" },
        { disignation: "Mesures de la nuisance sonore", code: "ENVIR-MNS" },
        { disignation: "Analyse de la qualité de l’air environnant", code: "ENVIR-AQA" },
      ]

    };
  },
  methods: {
    scrollToContent(link) {
      VueScrollTo.scrollTo(`#${link}`, 500, { easing: 'ease-in-out' });
    }
  }
};
</script>

<style scoped>

h5 {
    font-size: 20px;
    color: #2fd820;
}

*{
    font-family: Cairo,Verdana,Tahoma,Arial;
 }

 .titre {
    text-align: center;
    font-size: xx-large;
    font-weight: 700;
    font-size: 1.77777778rem;
    line-height: 120%;
    color: #00153C;
}
.resume {
    font-family: Cairo,Verdana,Tahoma,Arial;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #616161;
    text-align: justify;
}

.badget {
    margin-top: 20px;
    font-family: Cairo,Verdana,Tahoma,Arial;
    font-style: normal;
    font-weight: 200;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0em;
    color: #616161;
}

.imgg {
  width: 100%;
  height: 700px;
  overflow: hidden;
  position: relative;
  background-image: url("https://gthpdf.fra1.digitaloceanspaces.com/6.733de935.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.text-overlay {
  padding: 5%;
  margin: auto;
  position: absolute;
  z-index: 999;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(1, 1, 1, 0.6);
  font-family: Cairo,Verdana,Tahoma,Arial;

}

.text-overlay h1 {
    width:fit-content;
    font-family: Cairo,Verdana,Tahoma,Arial;

}

.text-overlay > h2 {
  color: #56be25;
  font-size: 40px;
  font-family: Cairo,Verdana,Tahoma,Arial;

}

.bottom-div {
  display: flex;
  flex-wrap: wrap;
}



.left-content {
  width: 25%;
  position: sticky;
  display: grid;
  align-items: center;
  top: 0;
  height: calc(100vh - 30px);
  padding: 10px;
}

.left-content ul{
  background: white;
  padding: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}.left-content ul li a{
 font-size: 12px;
 font-family: Cairo,Verdana,Tahoma,Arial;

}
.left-content ul li {
  padding-left: 12px;
  padding: 5px;
 }
 .left-content ul li:hover {
  background: rgb(235, 234, 234);
 }
 .left-content ul li:hover a{
  color: rgb(84, 72, 253);
  font-family: Cairo,Verdana,Tahoma,Arial;

 }

.right-content {
  width: 75%;
}

.content-section {
  margin-top: 20px;
}


@media screen and (max-width:1090px) {
  .bottom-div{
    display: grid;
  }
  .left-content{
    width: 80%;
    height: auto;
    margin-top: 50px;
    margin: auto;
    position: relative;
  }
  .right-content{
    width: 80%;
    margin: auto;
  }
  
}
</style>


<template>
  <header data-aos="menu-area" data-aos-anchor-placement="bottom-top">
        <div class="top">
            <a href="/">
                <img class="logo" src="./assets/logogth.png"/>
            </a>
            <ul>
                <li><a href="/#contact"><font-awesome-icon icon="fa-solid fa-phone" />Contactez-nous</a></li>
                <li><a href="/catalogue"><font-awesome-icon icon="fa-solid fa-handshake"/>Catalogues</a></li>
                <li><a href="/espace-client"><font-awesome-icon icon="fa-solid fa-user" />Espace Client</a></li>
            </ul>
        </div>
        <div class="bottom">
            <ul>
                <li>
                    <a href="/">LE GROUPE</a>
                    <div class="dropdown_menu">
                        <ul>
                            <li><a href="/a-propos">À propos</a></li>
                            <li><a href="/nos-valeurs">Nos valeurs</a></li>
                            <li><a href="/engagement">Nos Engagements</a></li>
                        </ul>
                    </div>
                </li>
                <li>
                    <a href="/#services">NOS SERVICES</a>
                    <div class="dropdown_menu">
                        <ul>
                            <li>
                                <a href="/controle-reglementaire-et-volontaire">Contrôle réglementaire et volontaire</a>
                                <div class="dropdown_menu-1">
                                    <ul>
                                        <li><a href="/controle-reglementaire-des-installations-electriques">Contrôle réglementaire des installations électriques</a></li>
                                        <li><a href="/controle-reglementaire-des-appareils-et-accessoires-de-levage">Contrôle réglementaire des appareils et accessoires de levage</a></li>
                                        <li><a href="/controle-reglementaire-des-machines-et-engins-de-chantier">Contrôle réglementaire des machines et engins de chantier</a></li>
                                        <li><a href="/controle-reglementaire-des-ascenseurs-escaliers-mecanique-trottoirs-roulantes">Contrôle réglementaire des ascenseurs – escaliers mécanique – trottoirs roulantes</a></li>
                                        <li><a href="/controle-reglementaire-des-dispositifs-de-systeme-incendie">Contrôle réglementaire des dispositifs de système incendie</a></li>
                                        <li><a href="/controle-reglementaire-des-appareils-a-pression-de-gaz-et-vapeur">Contrôle réglementaire des appareils à pression de gaz et vapeur</a></li>
                                        <li><a href="/controle-d-equipement-de-travail">Contrôle d’équipement de travail</a></li>
                                    </ul>
                                </div>
                            </li>
                            <li><a href="/evaluation-des-risques-au-travail">Évaluation des risques au travail</a></li>
                            <li><a href="/prelevements-environnementaux">Environnementaux</a></li>
                            <li><a href="/formations-techniques">Formations technique</a></li>
                            <li><a href="/audits-et-assistance-techniques">Audits et assistance techniques</a></li>
                        </ul>
                    </div>
                </li>
                <li><a href="/nos-moyens">NOS MOYENS</a></li>
                <li><a href="/#agrements">AGRÉMENTS</a></li>
                <li>
                    <a>NOUS REJOINDRE</a>
                    <div class="dropdown_menu">
                        <ul>
                            <li><a href="/4-raisons-de-nous-rejoindre">4 raisons de nous rejoindre</a></li>
                            <li><a href="/offre-emploi">Nos offre d’emploi</a></li>
                            <li><a href="/candidature-spontanee">Candidature spontanée</a></li>
                        </ul>
                    </div>
                </li>
                <li>
                    <a href="/#actualites">ACTUS & MÉDIAS</a>
                    <div class="dropdown_menu">
                        <ul>
                            <li><a href="/actualites">Actualités</a></li>
                            <li><a href="/news-réglementation">News Réglementation</a></li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
</header>
</template>
<script>

export default {
    name : "HeaderHome",
    data() {
        return {

        }
    },
    methods: {

    }


}
</script>

<style scoped>
.logo {
    width: 160px;
    -webkit-filter: drop-shadow(5px 5px 5px #222);
    filter: drop-shadow(2px 2px 2px #aaa);
}

header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    transition-delay: 0.6s;
    padding: 25px 50px;
}
.top {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.top ul {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.top ul li {
    position: relative;
}

.top ul li:nth-child(1) a {
    position: relative;
    margin: 0px 15px;
    text-decoration: none;
    color: white;
    letter-spacing: 2px;
    font-weight: bold;
    transition: 0.6s;
    border: 2px solid white;
    padding: 10px 13px 10px 8px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-family: Cairo,Verdana,Tahoma,Arial;
    font-style: normal;
}
.top ul li:nth-child(1) a:hover {
    background-color: white;
    color: #003478;
}
.top ul li:nth-child(2) a {

    position: relative;
    margin: 0px 15px;
    text-decoration: none;
    color: white;
    letter-spacing: 2px;
    font-weight: bold;
    transition: 0.6s;
    background-color:#1a73e8;
    border: 2px solid #1a73e8;
    padding: 10px 13px 10px 8px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
}

.top ul li:nth-child(2) a:hover {
    background-color: #003478;
    border: 2px solid #003478;

}

.top ul li:nth-child(3) a {
    position: relative;
    margin: 0px 15px;
    text-decoration: none;
    color: white;
    letter-spacing: 2px;
    font-weight: bold;
    transition: 0.6s;
    background-color: #56BE25;
    border: 2px solid #56BE25;
    padding: 10px 13px 10px 8px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
}

.top ul li:nth-child(3) a:hover {
    background-color: red;
    border: 2px solid red;

}
.top > ul > li > a > svg {
    margin-right: 15px;
}

.bottom {
    padding: 0;
    margin : 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.bottom ul {
    padding: 0;
    margin: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottom ul li {
    padding: 0;
    margin: 0;
    position: relative;
    line-height: 165%;
}

.bottom ul li a {
    padding: 0;
    margin: 0px 14px;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    font-size: 20px;
    font-family: Cairo,Verdana,Tahoma,Arial;
    font-style: normal;
}

.dropdown_menu {
    padding: 0;
    margin: 0;
    display: none;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: white;
}

.bottom ul li:hover .dropdown_menu {
    padding: 0;
    margin : 0;
    position: absolute;
    display: block;
    left: 0;
    top : 100%;
}

.dropdown_menu ul {
    padding: 0;
    margin: 0;
    display: block;
}

.dropdown_menu ul li  {
    margin: 5px;
    padding : 0;
    width: 500px;
}

.dropdown_menu ul li:hover  {
    background-color: #eee8e8;
}

.dropdown_menu ul li:hover a  {
    color: #56BE25;
}

.dropdown_menu ul li a  {
    color: #003478;
    font-size: 18px;
    font-weight: 400;
    font-family: Cairo,Verdana,Tahoma,Arial;
    font-style: normal;
}

.dropdown_menu-1 {
    display: none;
}

.dropdown_menu ul li:hover .dropdown_menu-1 {
    display: block;
    position: absolute;
    top: 0;
    left: 500px;
    background-color: rgb(86, 190, 37, 0.9);
}

.dropdown_menu ul li .dropdown_menu-1 > ul li {
    width: 700px;
}
.dropdown_menu ul li .dropdown_menu-1 > ul li:hover {
    background-color: #56BE25;
}
.dropdown_menu ul li .dropdown_menu-1 > ul li:hover a {
    color: #003478;
}



.dropdown_menu ul li .dropdown_menu-1 > ul li a {
    color : white;
    font-size: 16px;
    max-width: fit-content;
}

.aos-animate {
    background-color: rgb(0 0 0 / 68%);
    z-index: 999;
}

[data-aos] {
    pointer-events: unset;
}


@media screen and (max-width: 800px) { 
    .logo {
        width: 40px;
        -webkit-filter: drop-shadow(5px 5px 5px #222);
        filter: drop-shadow(2px 2px 2px #aaa);
    }

    .top ul li:nth-child(1) a {
        position: relative;
        margin: 0px;
        text-decoration: none;
        color: white;
        transition: 0.6s;
        font-size: 4px;
        font-family: Cairo,Verdana,Tahoma,Arial;
        font-style: normal;
        background-color: transparent;
        border: 0px;
  }

    .top ul li:nth-child(2) a {
        position: relative;
        margin: 0px;
        text-decoration: none;
        color: white;
        transition: 0.6s;
        font-size: 4px;
        font-family: Cairo,Verdana,Tahoma,Arial;
        font-style: normal;
        background-color: transparent;
        border: 0px;
  }
    .top ul li:nth-child(3) a {
        position: relative;
        margin: 0px;
        text-decoration: none;
        color: white;
        transition: 0.6s;
        font-size: 4px;
        font-family: Cairo,Verdana,Tahoma,Arial;
        font-style: normal;
        background-color: transparent;
        border: 0px;
  }

  .top ul li:nth-child(1) a svg {
    display: none;
  }

  .top ul li:nth-child(2) a svg {
    display: none;
  }

  .top ul li:nth-child(3) a svg {
    display: none;
  }


  .bottom ul li a {
    padding: 0;
    margin: 0px;
    color: #fff;
    cursor: pointer;
    font-size: 4px;
    font-family: Cairo,Verdana,Tahoma,Arial;
    font-style: normal;
    margin-left: 2px;
    margin-right: 2px;
}


}




</style>
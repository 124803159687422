import axios from 'axios'
import config from "@/config"

// class Messages
class Subscribers {

    //--------------------------------------- envoyer email for subscribe  --------------------------------------------
    static envoyer(email) {

        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/subscribers/envoyer`,
            {
                email : email
            },
            {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }
}

export default Subscribers;
<template>
  <div class="parent">
            <div class="catalouge" @click="doGoPageCatalouge()">
                <font-awesome-icon icon="fa-solid fa-handshake"/>
                <span><a href="/catalogue">Catalouge</a></span>
            </div>
            <div class="demandez" @click="doGoPageDemandez()">
                <font-awesome-icon icon="fa-solid fa-headset" />
                <span><a href="/demande">Demandez-nous</a></span>
            </div>
  </div>
</template>

<script>
export default {
 name : "WidgetHome",
 data() {
    return {

    }
 },

 methods: {
    doGoPageDemandez() {
        return this.$router.push("/demande")
    },

    doGoPageCatalouge() {
        return this.$router.push("/catalogue")
    }
 }

}
</script>

<style scoped>
.parent {
    position: fixed;
    height: fit-content;
    background-color: #329604da;;
    right: 0;
    top: 35%;
    width: 10%;
    border-radius: 10px 0px 0px 10px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.catalouge {
    
    margin: 10px auto ;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.catalouge svg {
    font-size: 35px;
    color:  whitesmoke;
    cursor: pointer;
    transition: 0.6s;

}
.catalouge span {
    color:  white;
}
.catalouge span a {
    font-family: Cairo,Verdana,Tahoma,Arial;
}

.catalouge svg:hover {
    font-size: 40px;
    color: white;
}

.demandez {
    margin: 10px auto ;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}



.demandez svg {
    font-size: 35px;
    color: whitesmoke;
    cursor: pointer;
    transition: 0.6s;
}

.demandez span {
    color:  white;
}

.demandez span a {
    font-family: Cairo,Verdana,Tahoma,Arial;
}


.demandez svg:hover {
    font-size: 40px;
    color: white;
}

@media screen and (max-width: 768px) {
    .parent {
        display: none;
    }
}

</style>
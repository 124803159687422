<template>
    <div class="parent" id="ourHome">
      <div class="topContent w-8/12 m-auto justify-center">
        <div class="grid gap-4 mt-30">
         <p class="text text-zinc-600 text-justify"><span class="gthconsult">GTHCONSULT</span> est un bureau de contrôle réglementaire, dont l’objectif est d’accompagner les chefs d'établissement et les exploitants pour répondre à leurs obligations réglementaires ou volontaires en matière des contrôles réglementaires des installations techniques, gestion des risques professionnels, protection des personnes, des biens et de l'environnement.</p>
         <h2 class="titre"><span class="NosBorder">LES ATOUTS</span></h2>

        </div>
      </div>
      <div class="bottomContent">
        <img src="https://gthpdf.fra1.digitaloceanspaces.com/ourWorld.svg" alt="" class="world">
        <div class="analystic flex justify-center gap-36 ">
                        <div class="eachone grid">
                                <font-awesome-icon icon="fa-solid fa-building" />
                                <h3 class="text-sky-600  font-extrabold text-5xl">500+</h3>
                                <p>Clients</p>
                        </div>
                        <div class="eachone grid">
                                    <font-awesome-icon icon="fa-solid fa-briefcase" />
                                    <h3 class="text-sky-600 font-extrabold text-5xl" >6+</h3>
                                    <p>Ans d'experience</p>
                            </div>
                            <div class="eachone grid">
                                <font-awesome-icon icon="fa-solid fa-person-digging" />
                                <h3 class="text-sky-600 font-extrabold text-5xl">4000+</h3>
                                <p>Missions confiées</p>
                        </div>
                        <div class="eachone grid">
                            <font-awesome-icon icon="fa-solid fa-medal" />
                            <h3 class="text-sky-600 font-extrabold text-5xl">98%</h3>
                            <p>Nous a recommandé</p>
                    </div>
        </div>
        <div class="people flex justify-center gap-16  mt-32 h-32">
            <img src="https://gthpdf.fra1.digitaloceanspaces.com/maroc.png" alt="" class="rounded-full bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-1">
            <img src="https://gthpdf.fra1.digitaloceanspaces.com/france.png" alt="" class="top1 rounded-full h-14 w-14 bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-1">
            <img src="https://gthpdf.fra1.digitaloceanspaces.com/senigal.png" alt="" class="rounded-full h-16 bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-1">
        </div>
      </div>
      
    </div>
  </template>
  
  <script>
  export default {
      name : "OurHome",
      data() {
          return {
  
          }
      }
  
  }
  </script>
  
  <style scoped>
  *{
    font-family: Cairo,Verdana,Tahoma,Arial;
  }
      .parent{
        overflow:hidden ;
          height: 100vh;
          width: 100%;
        
      }

      .titre {
        text-align: center;
        font-size: xx-large;
        margin-top: 30px;
        margin-bottom: 30px;
        font-weight: 700;
        font-size: 1.77777778rem;
        line-height: 120%;
        color: #00153C;
        letter-spacing: .02em;
      }

      .NosBorder{
        padding-top: 6px;
        border-top: 4px solid #cf1f21;
      }

      .gthconsult {
        font-weight: bold;
      }

      .text {
        margin-top: 30px;
        font-family: Cairo,Verdana,Tahoma,Arial;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: 0em;
        color: #616161;
      }

      .analystic{
        margin-top:-70vh;
      }

      .eachone {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }
      .eachone svg {
        font-size: 50px;
        color: #0284c7;
      }

      .top1{
        margin-top: -70px;
      }

      @media screen  and (max-width: 1211px){
        .analystic{
            margin-top:-60vh;
          }
      }
      @media screen  and (max-width: 1091px){
        .analystic{
            margin-top:-55vh;
            flex-wrap: wrap;
            gap:30px;
          }
          .analystic h3{
            font-size: 50px;
          }
      }
      @media screen  and (max-width: 971px){
        .analystic{
            margin-top:-45vh;
            flex-wrap: wrap;
            gap:30px;
          }
          .analystic h3{
            font-size: 30px;
          }
      }
      @media screen  and (max-width: 881px){
        .analystic{
            margin-top:-42vh;
            flex-wrap: wrap;
            gap:30px;
          }
          .analystic h3{
            font-size: 26px;
          }
         
      }

      @media screen  and (max-width: 770px){
        .analystic{
            margin-top:-39vh;
            flex-wrap: wrap;
            gap:30px;
          }
          .analystic h3{
            font-size: 22px;
          }
          .analystic p{
            font-size: 18px;
          }

          .text {
            margin-top: 50px;
            font-weight: 100;
            font-size: 15px;
            line-height: 120%;
            text-align: left;

          }

          .eachone {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
          }
          .eachone svg {
            font-size: 15px;
            color: #0284c7;
          }
         
      }

      @media screen  and (max-width: 680px){
        .analystic{
            margin-top:-35vh;
            flex-wrap: wrap;
            gap:30px;
          }
          .analystic h3{
            font-size: 18px;
          }
          .analystic p{
            font-size: 14px;
          }
          .people img{
            width:40px;
            height:40px;
          }
          .people{
            margin-top: 90px;
          }
         
      }
      @media screen  and (max-width: 600px){
        .analystic{
            margin-top:-30vh;
            flex-wrap: wrap;
            gap:30px;
          }
          .analystic h3{
            font-size: 16px;
          }
          .analystic p{
            font-size: 12px;
          }

         
      }
      @media screen  and (max-width: 520px){
        .analystic{
            margin-top:-27vh;
          }

         
      }
      @media screen  and (max-width: 470px){
        .analystic{
            margin-top:-23vh;
          }
      }
      @media screen  and (max-width: 390px){
        .analystic{
            margin-top:-20vh;
          }
          .world{
            margin-top: 40px;
          }
          .people img{
            display: none;
          }
      }
  </style>
  
<template>
    <div class="gestionInspecteur">
  
      <h3 v-if="flagEditMedia == false">LISTES DES MEDIAS</h3>
  
      <div class="rechercher-table" v-if="flagEditMedia == false">
  
        <div class="rechercher">
          <input type="text" v-model="rechercher" placeholder="Recherche un media">
        </div>
  
        <table id="inspecteurs">
          <tr>
            <th>Titre</th>
            <th>Auteur</th>
            <th>Type</th>
            <th>Actions</th>
          </tr>
          <tr v-for="(item, i) in filterMedia" :key="i">
            <td>{{ item.titre }}</td>
            <td>{{ item.auteur }}</td>
            <td>{{ item.filename.split('.')[1] }}</td>
            <td>
              <a @click="deleteMedia(i, item.filename)" title="Supprimer">Supprimer</a>
              <a @click="editMedia(i)" title="Modifier">Modifier</a>
              <a @click="apercuMedia(item.filename)" title="Télécharger">Aperçu</a>
            </td>
          </tr>
        </table>
  
      </div>
  
      <ul class="pagination" v-if="flagEditMedia == false">
        <button @click="selectPageLeft()"><i class="fa-solid fa-backward"></i></button>
        <li v-for="n in numberPage" :key="n" @click="selectPage(n)">
          <p v-show="numberPageSelect[n - 1] == false">{{ n }}</p>
          <p v-show="numberPageSelect[n - 1] == true" style="background-color: #0300db; color: white;">{{ n }}</p>
        </li>
        <button @click="selectPageRight()"><i class="fa-solid fa-forward"></i></button>
      </ul>
  
  
      <!--  Start Edit SousTraitance   -->
      <EditMedia v-if="flagEditMedia == true" :mediaId="mediaId" />
      <!--  End Edit SousTraitance   -->
  
      <!--  Start Confirmation Model   -->
      <ConfirmationModel v-if="flagConfirmationModel == true" :text="textConfirmation" @closeConfirmation="closeConfirmation()" @validerConfirmation="validerConfirmation()" />
      <!--  End Confirmation Model   -->
  
      <!--  Start Confirmation Model   -->
      <ErrorModel v-if="flagErrorModel == true" :text="text" :titre="titre" />
      <!--  End Confirmation Model   -->
  
      <!--  Start Info Delete Model   -->
      <LandingModel v-if="flagLandingModel == true" />
      <!--  End Info Delete Model   -->
  
      <!--  Start Confirmation Model   -->
      <NotAccessPageModel v-if="flagNotAccessPageModel == true" />
      <!--  End Confirmation Model   -->
  
    </div>
  </template>
  
  <script>
  // import Programmes from "@/connection/Programmes";
  import Medias from "@/connection/Medias";
  import EditMedia from "./EditMedia.vue";
  import ConfirmationModel from "@/components/models/ConfirmationModel.vue";
  import ErrorModel from "@/components/models/ErrorModel.vue";
  import LandingModel from "@/components/models/LandingModel.vue";
  import NotAccessPageModel from "@/components/models/NotAccessPageModel.vue";
  
  export default {
    name: "gestionAdmin",
    components: {
      EditMedia,
      ConfirmationModel,
      ErrorModel,
      LandingModel,
      NotAccessPageModel
    },
    data() {
      return {
        numberPageSelect: [],
        mediaDelete: null,
        mediaFileDelete : null,
        textDeleteModel: null,
        pageIndex: 0,
        itemsPerPage: 10,
        numberPage: null,
        succes: false,
        echec: false,
        msg: null,
        rechercher: null,
        premedias: [],
        medias: [],
        flagEditMedia: false,
        flagConfirmationModel: false,
        flagErrorModel: false,
        flagLandingModel: false,
        flagNotAccessPageModel: false,
        flagModelDelete: false,
        mediaId: null,
        text: null,
        titre: null
      };
    },
  
    computed: {
  
      filterMedia() {
            return this.medias.filter((item) => {
    
            if (!this.rechercher) {
                if (item != undefined) {
                return item;
                }
    
            } else {
                if (item != undefined) {
                return !item.titre.toString().indexOf(this.rechercher.toLowerCase().toString()) ||
                       !item.auteur.toString().indexOf(this.rechercher.toLowerCase().toString());
                }
            }
    
            });
      }
      
    },
  
    methods: {

      apercuMedia(filename) {

        Medias.displayMedia(filename)
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
        });

      },
  
      selectPageLeft() {
  
        for (let i = 0; i < this.numberPage; i++) {
          this.numberPageSelect[i] = false;
        }
  
        this.numberPageSelect[0] = true;
  
        this.medias = [];
        for (let i = 0 * this.itemsPerPage; i < (0 * this.itemsPerPage) + this.itemsPerPage; i++) {
          this.medias.push(this.premedias[i]);
        }
  
      },
  
      selectPageRight() {
  
        for (let i = 0; i < this.numberPage; i++) {
          this.numberPageSelect[i] = false;
        }
  
        const indice = this.numberPage - 1;
        this.numberPageSelect[indice] = true;
        this.medias = [];
        for (let i = indice * this.itemsPerPage; i < (indice * this.itemsPerPage) + this.itemsPerPage; i++) {
          this.medias.push(this.premedias[i]);
        }
      },
  
      selectPage(value) {
  
        const select = value - 1;
        // change current select for change color
        for (let i = 0; i < this.numberPage; i++) {
          this.numberPageSelect[i] = false;
        }
  
        this.numberPageSelect[select] = true;
        this.medias = [];
        for (let i = select * this.itemsPerPage; i < (select * this.itemsPerPage) + this.itemsPerPage; i++) {
          this.medias.push(this.premedias[i]);
        }

      },
  
      closeConfirmation() {
        this.flagConfirmationModel = false;
      },
  
      validerConfirmation() {
  
        this.flagConfirmationModel = false;
        this.flagLandingModel = true;
        // delete in db backend
        Medias.delete(this.medias[this.mediaDelete]._id, this.mediaFileDelete)
          .then((response) => {
            if(response) {
              this.medias.splice(this.mediaDelete, 1)
              this.flagLandingModel = false;
            }
          })
          .catch((error) => {
            this.msg = error.message;
            console.error(`HTTP error: ${error.name} => ${error.message}`);
            throw "fail request at: GET /refreshtime";
          });
      },
  
  
    // delete one admin
    deleteMedia(i, filename) {
        this.flagConfirmationModel = true;
        this.textConfirmation = "Êtes-vous sûr de supprimer ?";
        this.mediaDelete = i;
        this.mediaFileDelete = filename;
    },
  
    // edit one admin
    editMedia(i) {
        this.flagEditMedia = true;
        this.mediaId = this.medias[i]._id;
    }
  
  
    },
  
    //clients
    created() {
  
      Medias.read()
  
        .then((result) => {

          result.data.medias.forEach(element => {
            this.premedias.push(element);
          });
  
          this.numberPage = Math.ceil(result.data.medias.length / this.itemsPerPage);
  
          for (let i = 0; i < this.numberPage; i++) {
            this.numberPageSelect.push(false);
          }
  
          this.numberPageSelect[0] = true;
  
          for (let i = this.pageIndex * this.itemsPerPage; i < (this.pageIndex * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.medias.push(this.premedias[i]);
          }
  
        })
        .catch((error) => {
          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
        });
  
    }
  
  
  };
  </script>
  
  <style scoped>
  .succes {
    background-color: green;
    color: white;
    padding: 10px;
    height: fit-content;
    width: 100%;
  }
  
  .echec {
    background-color: red;
    color: white;
    padding: 10px;
    height: fit-content;
    width: 100%;
  }
  
  .gestionInspecteur {
    width: 100%;
    height: 100%;
    padding-top: 0px;
    padding-left: 0px;
    margin: 0px;
    padding: 2px;
    position: relative;
  }
  
  .gestionInspecteur h3 {
    width: 100%;
    margin: auto;
    color: white;
    background: linear-gradient(346deg, rgba(207, 31, 33, 1) 0%, rgba(24, 86, 161, 1) 100%);
    text-align: center;
    text-align: center;
    padding: 15px;
    margin-bottom: 20px;
  }
  
  pecteur .rechercher-table {
    width: 100%;
  }
  
  .gestionInspecteur .rechercher-table .rechercher {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .gestionInspecteur .rechercher-table .rechercher input {
    width: 50%;
    height: 40px;
    outline: 0;
    border: 1px solid #243064;
    font-size: 16px;
    background-color: white;
    padding: 5px;
    color: #243064;
  }
  
  .gestionInspecteur .rechercher-table table {
    width: 100%;
  }
  
  .gestionInspecteur .rechercher-table table tr {
    width: 100%;
  }
  
  .gestionInspecteur .rechercher-table table tr th {
    background-color: #243064;
    color: white;
    border-right: 1px solid white;
    padding: 10px 4px;
  }
  
  table {
    border-collapse: collapse;
  }
  
  .gestionInspecteur .rechercher-table table tr td {
    color: black;
    padding: 5px;
  }
  
  .gestionInspecteur .rechercher-table table tr:nth-child(even) {
    background-color: #f8f8f8;
  }
  
  .gestionInspecteur .rechercher-table table tr td a {
    cursor: pointer;
  
    margin-right: 10px;
  }
  
  .fa-trash {
    color: red;
  }
  
  .fa-trash:hover {
    color: rgb(230, 88, 88);
  }
  
  .fa-pen-to-square {
    color: rgb(255, 187, 0);
  }
  
  .fa-circle-check {
    color: green;
  }
  
  .gestionInspecteur .rechercher-table ul {
    width: 100%;
    height: fit-content;
    text-align: center;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .gestionInspecteur .rechercher-table ul li {
    color: black;
    margin-left: 5px;
    cursor: pointer;
    font-size: 18px;
  }
  
  .gestionInspecteur .rechercher-table ul li:hover {
    color: red;
    margin-left: 5px;
    transition: 0.3s;
  }
  
  .gestionInspecteur .rechercher-table .deleteAll {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  
  .gestionInspecteur .rechercher-table .deleteAll input {
    width: fit-content;
    padding: 10px;
    height: 40px;
    background-color: red;
    color: white;
    border: 0;
    cursor: pointer;
  }
  
  #inspecteurs>tr>td:nth-child(10)>a:nth-child(1)>svg {
    color: red;
    font-size: 20px;
  }
  
  #inspecteurs>tr>td:nth-child(10)>a:nth-child(2)>svg {
    color: blue;
    font-size: 20px;
  }
  
  #inspecteurs>tr>td:nth-child(10)>a:nth-child(3)>svg {
    color: green;
    font-size: 20px;
  }
  
  #app>div>div>div.menu-content>div.content>div>h3 {
    background-color: #ff0000d4;
    padding: 15px;
  }
  
  #app>div>div>div.menu-content>div.content {
    background-color: white;
  }
  
  #inspecteurs>tr>td {
    color: #000000;
    border-bottom: 1px solid #acacac;
    text-transform: capitalize;
  }
  
  #app>div>div>div.menu-content>div.content>div {
    background-color: white;
  }
  
  #inspecteurs>tr>td:nth-child(8)>a:nth-child(1)>svg {
    color: red;
  }
  
  #inspecteurs>tr>td:nth-child(8)>a:nth-child(3)>svg {
    color: green;
  }
  
  #inspecteurs>tr>td:nth-child(8)>a:nth-child(4)>svg {
    color: red;
  }
  
  #inspecteurs>tr>td:nth-child(8)>a:nth-child(5)>svg {
    color: orange;
  }
  
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
    width: fit-content;
    flex-wrap: wrap;
    padding: 5px 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  
  .pagination p {
    border: 1px solid rgb(151, 151, 151);
    margin: 0 4px;
    padding: 5px 10px;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .pagination p:hover,
  button:hover {
    background-color: #243064;
    color: white;
  }
  
  
  
  
  .pagination button {
    border: 0;
    padding: 5px;
    border: 1px solid rgb(170, 170, 170);
    margin-left: 15px;
    color: gray;
    cursor: pointer;
  }
  
  .pagination button:nth-child(1) {
    margin-right: 15px;
    margin-left: 0;
  
  }
  </style>
<template>
 <div className='parent text-white px-8 py-16'>
        <div className="flex justify-center items-center max-w-2xl mx-auto  flex-col space-y-4 ">
        <p className='text-center text-[30px] font-bold '>Vous avez besoin d’une inspection réglementaire et volontaire en exploitation pour votre projet ?</p>
            <p>Contactez-nous pour un premier rendez-vous ou transmettez-nous votre projet.</p>
            <button @click="demande()" className='outline outline-1 font-semibold outline-white p-4 rounded-lg ease-linear duration-200 hover:bg-white hover:text-black'>Demander plus d'information</button>
        </div>

    </div>
</template>

<script>
export default {
    name : "DemandeHome",
    data() {
        return {

        }
    },
    methods : {
        demande() {
            return this.$router.push("/demande")
        }
    }

}
</script>

<style scoped>
.parent{
    margin-top:  30px;
    background: #0057E3 url("https://risk-control.fr/wp-content/uploads/2021/01/Graphisme.svg") no-repeat center right 80px;
    background-size: auto 100%;
}

</style>
<template>
  <div class="parent">

    <div class="imgg">
      <div class="text-overlay w-full text-center text-white text-3xl font-extrabold">
        <h2>Demande un conseil</h2>
      </div>
    </div>

    <div class="content  text-zinc-500 mt-44">
      <div class=" first flex justify-center">
        <div class="w-4/5 relative">
          <div class="circle-background"></div>
          <h3 class="text-2xl mb-6 font-bold">Vous avez besoin d’un renseignement ?</h3>
          <p>Pour toute demande ou information, laissez-nous vos coordonnées, un membre de notre équipe vous contacte dans les plus brefs délais.</p>
        </div>
      </div>
      <div class="test">
        <div class="shadow-lg p-10  w-5/6">
          <p class="mb-4">Les champs marqués d'une astérisque (*) sont des champs obligatoires.</p>
          <hr class="border-gray-300 w-4/5 mb-4">
          <div class="mb-4">
            <label for="nom" class="block font-bold mb-2">Nom <span class="text-red-500">*</span></label>
            <input v-model="nom" type="text" id="nom" required class="border border-gray-300 rounded p-2 w-4/5" placeholder="Votre nom">
          </div>

          <div class="mb-4">
            <label for="prenom" class="block font-bold mb-2">Prénom <span class="text-red-500">*</span></label>
            <input v-model="prenom" type="text" id="prenom" required class="border border-gray-300 rounded p-2 w-4/5" placeholder="Votre prénom">
          </div>

          <div class="mb-4 text-left">
            <label class="block font-bold mb-2">Vous êtes <span class="text-red-500">*</span></label>
            <div class="flex items-center">
              <input type="radio" v-model="etat" id="particulier" class="w-4" name="type" value="particulier" required>
              <label for="particulier" class="ml-4">Un particulier</label>
            </div>
            <div class="flex items-center">
              <input type="radio" v-model="etat" id="professionnel" class="w-4" name="type" value="professionnel" required>
              <label for="professionnel" class="ml-4">Un professionnel</label>
            </div>
          </div>

          <div class="mb-4">
            <div class="">
              <label for="numero" class="block w-1/3 font-bold mb-2">Votre numéro Téléphone</label>
              <div class="w-4/5 flex">
                <input v-model="telephone" type="text" id="numero" class="border border-gray-300 rounded-r p-2 w-full" placeholder="Votre numéro telephone">
              </div>
            </div>
          </div>


          <div class="mb-4 r">
            <label class="block mr-2 font-bold mb-2">Le meilleur moment pour vous appeler</label> <br>
            <div class="flex items-center">
              <input v-model="moment" type="radio" class="w-4" id="matin" name="moment" value="matin">
              <label for="matin" class="ml-4">Le matin</label>
            </div>
            <div class="flex items-center ">
              <input v-model="moment" type="radio" class="w-4" id="apres-midi" name="moment" value="apres-midi">
              <label for="apres-midi" class="ml-4">L'après-midi</label>
            </div>
          </div>

          <div class="mb-4">
            <label for="email" class="block font-bold mb-2">Adresse de messagerie <span class="text-red-500">*</span></label>
            <input v-model="email" type="email" id="email" required class="border border-gray-300 rounded p-2 w-4/5" placeholder="Votre adresse email">
          </div>

          <div class="mb-4">
            <label for="objet" class="block font-bold mb-2">Objet <span class="text-red-500">*</span></label>
            <select v-model="objet" id="objet" required class="border border-gray-300 rounded p-2 w-4/5">
              <option  selected value="Nouveau-projet">Nouveau projet</option>
              <option value="Services-facturation">Services facturation</option>
              <option value="Autre">Autre</option>
            </select>
          </div>

          <div class="mb-4">
            <label class="block font-bold mb-2">Motif du contact:</label>
            <div class="flex items-center">
              <input type="radio" v-model="motif" class="w-4" id="demande-devis" name="motif" value="demande-devis">
              <label class="ml-4" for="demande-devis">Demande de devis</label>
            </div>
            <div class="flex items-center">
              <input type="radio" v-model="motif" class="w-4" id="renseignement" name="motif" value="renseignement">
              <label class="ml-4" for="renseignement">Renseignement</label>
            </div>
          </div>

          <div class="mb-4">
            <label for="numero-affaire" class="block font-bold mb-2">Votre numéro d'affaire ou adresse du projet <span class="text-red-500">*</span></label>
            <input v-model="numeroAaffaire" type="text" id="numero-affaire" required class="border border-gray-300 rounded p-2 w-4/5" placeholder="Numéro d'affaire ou adresse du projet">
          </div>

          <div class="mb-4">
            <label for="ville-region" class="block font-bold mb-2">Ville et région</label>
            <input v-model="ville" type="text" id="ville-region" class="border border-gray-300 rounded p-2 w-4/5" placeholder="Votre ville et région">
          </div>

          <div class="mb-4">
            <label for="message" class="block font-bold mb-2">Votre message <span class="text-red-500">*</span></label>
            <textarea v-model="message" id="message" required class="border border-gray-300 rounded p-2 w-4/5" placeholder="Votre message"></textarea>
          </div>

          <div class="mb-4 flex items-start">
            <input type="checkbox" :checked="checkedPoltique" class="mt-2 w-4 mr-4" id="confidentialite" required>
            <label for="confidentialite" class="inline"> <span class="text-black"> Important:</span> en soumettant ce formulaire, j'accepte que les informations saisies soient utilisées pour permettre de me recontacter. <span class="text-black"> <a href="/politique-de-confidentialite">Politique de confidentialité </a></span><span class="text-red-500">*</span></label>
          </div>

          <button @click.prevent="envoyer" class="bg-blue-500 text-white px-4 py-2 rounded">Envoyer La Demande</button>
        </div>
      </div>
    </div>

    <loading v-model:active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"/>

  </div>
</template>

<script>
import Demandes from "@/connection/Demandes"
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
export default {
  data() {
    return {
      checkedPoltique : false,
      isLoading : false,
      nom : null,
      prenom : null,
      telephone : null,
      email : null, 
      etat : null,
      moment : null,
      objet : null, 
      motif : null,
      numeroAaffaire : null,
      ville : null,
      message : null
    };
  },

  components : {
    Loading
  },
  methods : {


    envoyer() {
      this.isLoading = true;
      // console.log(this.nom, this.telephone, this.email, this.etat, this.moment, this.objet, this.motif, this.numeroAaffaire, this.ville, this.message)
      Demandes.envoyer(this.nom, this.prenom, this.telephone, this.email, this.etat, this.moment, this.objet, this.motif, this.numeroAaffaire, this.ville, this.message)
      .then((result) => {
          if(result) {
                this.nom  = null;
                this.prenom  = null;
                this.telephone  = null;
                this.email  = null; 
                this.etat  = null;
                this.moment  = null;
                this.objet  = null; 
                this.motif  = null;
                this.numeroAaffaire  = null;
                this.ville  = null;
                this.message  = null;
                this.isLoading = false;
                this.checkedPoltique = false;
          }
      })
      .catch((error) => {
          console.log(error);
      });
    }

  }
};
</script>

<style scoped>
  .imgg {
    width: 100%;
    height: 300px;
    overflow:hidden;
    position: relative;
    background-image: url("https://gthpdf.fra1.digitaloceanspaces.com/mentions-legales-min.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .text-overlay {
      padding: 5%;
      margin: auto;
      position: absolute;
      z-index: 999;
      text-align: center;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      background-color: rgb(1, 1, 1, 0.6);
  }
  .text-overlay > h2 {
    color : #56be25;
    font-size: 40px;
  }

  .test{
    background:white;
   
  }
  .content{
    width: 100%;
    display: grid;
    grid-template-columns: 30% 70%;
   
  }
  .circle-background {
    position: absolute;
    opacity: 0.1;
    top: 30%;
    left: -20%;
    transform: translate(-50%, -50%);
    width: 300%;
    height: 100%;
    border-radius: 100%;
   
    border: 350px dotted rgb(184, 183, 183);
    z-index: -99;
  }



  @media screen and (max-width: 800px) {
    
    .content{
      display: grid;
       grid-template-columns: 100%;

    }
    .first{
      width: 100%;
      margin-top: 0;
    }

    .test{
      width: 90%;
      margin: auto;
    }
    form{
      width:100%;
      margin: auto;
    }
    .circle-background {
     
      left: -40%;
      border: 300px dotted rgb(184, 183, 183);
     
    }
  }
</style>
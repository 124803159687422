<template>
    <div class="parent">
  
      <div class="imgg">
  
        <div class="text-overlay w-full text-center text-white text-3xl font-extrabold">
          <h2>ACTUALITÉS</h2>
        </div>
  
      </div>
      
      <div class="content">
  
          <div class="cont">

              <div class="paragraph">
                <h1>{{ titre }}</h1>
                <div class="line"></div>
                <p>{{ new Date(date).toLocaleDateString() }}</p>
              </div>

          <div class="description">

          <p class="text4">
            {{ introduction }}
          </p>
          <p class="text4" v-for="(item, index) in developments" :key="index">
            {{ item }}
          </p>
          <p class="text4">
            {{ conclusion }}
          </p>

      </div>
  
      </div>

       
      </div>
  
    </div>
  </template>
  
  <script>
  import Actualite from "@/connection/Actualite"
  
  export default {
      name : "OffreEmploisDetailsView",
      data() {
          return {
            date : null,
            titre : null,
            developments : [],
            introduction : null,
            conclusion : null

          }
      },
  
      methods: {
        
      },

      mounted() {
  
        Actualite.select(this.$route.params.titre)
        .then((result) => {
            this.date = result.data[0].date;
            this.titre = result.data[0].titre;
            this.developments = result.data[0].developments;
            this.introduction = result.data[0].introduction;
            this.conclusion = result.data[0].conclusion;
        })
        .catch((error) => {
            console.log(error.message)
        });
  
      }
  }
  </script>
  
  <style scoped>
  *{
    margin: 0px;
    padding: 0px;
  }
      .imgg {
          width: 100%;
          height: 300px;
          overflow:hidden;
          position: relative;
          background-image: url("https://gthpdf.fra1.digitaloceanspaces.com/mentions-legales-min.jpg"); 
          background-size: 100%;
          background-repeat: no-repeat;
      }
  
      .text-overlay {
          padding: 5%;
          margin: auto;
          position: absolute;
          z-index: 999;
          text-align: center;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          background-color: rgb(1, 1, 1, 0.6);
      }
      .text-overlay > h2 {
          color : #56be25;
          font-size: 40px;
      }
      .cont{
        width: 80%;
        margin-left: 10%;
        margin-top: 30px;
        /* border: 1px solid black; */
      }
      
      .paragraph {
        text-align: center;
        width: 60%;
        margin-left: 20%;
        margin-bottom: 120px;
      }
     .paragraph h1{
      color: rgb(36, 48, 100);
      font-size: 25px;
      font-weight: bold;
     } 
     .line{
      height: 1px;
      width: 90%;
      border:1px solid rgb(0,0,0,0.3);
      margin-left:5% ;
      margin-top: 20px;
     }
     .content h2{
      color: rgb(207,31,33);
      font-size: 20px;
      font-weight: bold;
      margin: 20px 0px;
     }
     li{
      margin-left: 20px;
     }
     .formulaire{
      margin-top: 40px;
      width: 90%;
      margin-left: 5%;
     }
     .formulaire .titr{
      
       border:none; 
      background-color: rgba(36, 48, 100,0.5);
      height: 70px;
     }
     .titr h2{
      margin: 0px 10px;
      padding: 20px 0px;
     }
     .form{
      padding-top: 10px;
      background-color: whitesmoke;
      padding-bottom: 50px;
     }
     .form input{
      /* border: 2px solid rgba(207,31,33,0.4); */
      border-radius: 5px;
      height: 40px;
      margin-top: 10px;
     }
     .label{
      width: 100px;
      margin-top: 10px;
     }
     .input,.inp1{
      width:600px;
     }
     .input{
      padding-left: 20px;
      
     }
     .input input{
      width: 79%;
     }
     .form .rows{
      display: flex;
      flex-direction: row;
     justify-content: center;
  
     }
     .fullName, .dateTime{
      display: flex;
      flex-direction: row;
    } 
    .dateTime input{
      width: 230px;
    }
    .boutton{
      color: #FFF;
      background-color: rgb(207,31,33);
      width: 130px;
      padding: 5px;
      border-radius: 10px;
      margin-left: 45%;
      margin-top: 50px;
      
    }
    @media (max-width:600px){
      .form .rows{
      display: flex;
      flex-direction: column;
     justify-content: center;
  
     }
     .input,.inp1{
      width:300px;
     }
     .dateTime{
      display: flex;
      flex-direction: column;
     }
     .boutton{
      margin-left: 25%;
     }
    }
    @media (max-width:900px){
      .form .rows{
      display: flex;
      flex-direction: column;
     justify-content: center;
  
     }
     .input,.inp1{
      width:500px;
     }
    
     .boutton{
      margin-left: 25%;
     }
    }
  
  </style>
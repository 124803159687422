<template>
  <div class="parent_2">
    <div class="screen_2">
            <img src="https://gthpdf.fra1.digitaloceanspaces.com/cookies.jpg">
            <div class="opacity"></div>
            <a href="#">LE GROUPE</a>
            <h1>POLITIQUE DE CONFIDENTIALITÉ</h1>
        </div>
        <div class="containt_2">
            <img src="../assets/logogth.png">
           <div class="site_cookie">
               <h3>1. Introduction</h3>
                  <p>
                    Notre site web, https://www.gthconsult.com (ci-après : « le site web ») utilise des cookies et autres technologies liées (par simplification, toutes ces technologies sont désignées par le terme « cookies »). Des cookies sont également placés par des tierces parties que nous avons engagées. Dans le document ci-dessous, nous vous informons de l’utilisation des cookies sur notre site web.
                </p>
                  <br>
                 
                   <br><br>
               <h3>2. Que sont les cookies ?</h3>
               <p>Un cookie est un petit fichier simple envoyé avec les pages de ce site web et stocké par votre navigateur sur le disque dur de votre ordinateur ou d’un autre appareil. Les informations qui y sont stockées peuvent être renvoyées à nos serveurs ou aux serveurs des tierces parties concernées lors d’une visite ultérieure.
               </p> 
                      <br>
               <h3>3. Que sont les scripts ?</h3>
               <p>
                Un script est un élément de code utilisé pour que notre site web fonctionne correctement et de manière interactive. Ce code est exécuté sur notre serveur ou sur votre appareil.
               </p><br>
               <h3>4. Qu’est-ce qu’une balise invisible ?</h3>
               <p>
                Une balise invisible (ou balise web) est un petit morceau de texte ou d’image invisible sur un site web, utilisé pour suivre le trafic sur un site web. Pour ce faire, diverses données vous concernant sont stockées à l’aide de balises invisibles.
               </p><br>
               <h3>5. Cookies</h3>
              
                   <h5>5.1 Cookies techniques ou fonctionnels</h5>
                   <p>Certains cookies assurent le fonctionnement correct de certaines parties du site web et la prise en compte de vos préférences en tant qu’utilisateur. En plaçant des cookies fonctionnels, nous vous facilitons la visite de notre site web. Ainsi, vous n’avez pas besoin de saisir à plusieurs reprises les mêmes informations lors de la visite de notre site web et, par exemple, les éléments restent dans votre panier jusqu’à votre paiement. Nous pouvons placer ces cookies sans votre consentement.</p>
                    <h5>5.2 Cookies statistiques</h5>
                    <p>Nous utilisons des cookies statistiques afin d’optimiser l’expérience des internautes sur notre site web. Avec ces cookies statistiques, nous obtenons des observations sur l’utilisation de notre site web. Nous demandons votre permission pour placer des cookies statistiques.</p>
                    <h5>5.3 Cookies de marketing</h5>
                    <p>Les cookies de marketing sont des cookies ou toute autre forme de stockage local, utilisés pour créer des profils d’utilisateurs afin d’afficher de la publicité ou de suivre l’utilisateur sur ce site web ou sur plusieurs sites web dans des finalités marketing similaires.</p>
                <br> 
               <h3> 6. Consentement</h3>
               <p>Lorsque vous visitez notre site web pour la première fois, nous vous montrerons une fenêtre contextuelle avec une explication sur les cookies. Dès que vous cliquez sur « Je suis d'accord » vous nous autorisez à utiliser les catégories de cookies et d’extensions que vous avez sélectionnés dans la fenêtre contextuelle, comme décrit dans la présente politique de cookies. Vous pouvez désactiver l’utilisation des cookies via votre navigateur, mais veuillez noter que notre site web pourrait ne plus fonctionner correctement
               </p><br>
               <h3> 7. Coordonnées</h3>
               <p>Pour des questions et/ou des commentaires sur notre politique de cookies et cette déclaration, veuillez nous contacter en utilisant les coordonnées suivantes :<br>
                GTHCONSULT BUREAU DE CONTRÔLE AGRÉÉ PAR L’ÉTAT<br>

                GTHCONSULT, SIÈGE SOCIAL: BOULEVARD MOHAMMED VI, LOT AL MASSIRA, LOT N°187, APPT 5-6 3EME ETAGE, Mohammedia<br>

                Maroc<br>

                Site web : https://www.gthconsult.com<br>

                E-mail : service.supports@gthconsult.ma<br>
               </p><br> <!---->
                          </div>
      
       </div>
  </div>
</template>

<script>
export default {
    name : "PolitiqueView",
    data() {
        return {
            
        }
    }

}
</script>


<style scoped>
*{
    font-family: Cairo,Verdana,Tahoma,Arial;
  }
  *{
        margin:0; 
      padding: 0;
      box-sizing: border-box;
      font-family: Arial, Helvetica, sans-serif;
      text-decoration: none;
      list-style: none;
    }
    .parent_2{
        height:max-content;
    }
    .screen_2{
        height: 80vh;
        position: relative;
    }
    .screen_2 img{
        width: 100%;
        height:100%;
    }
    .screen_2 .opacity{
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(36, 48, 100,0.4);
    }
    .screen_2 h1{
        color: #fff;
        position: absolute;
        top: 50%;
        left:35%;
        font-size: 30px;
    }
    .screen_2 a{
        color:#fff;
        position:absolute;
        top: 45%;
        left: 35%;
        text-transform: uppercase;
    }
    .screen_2 a:hover{
        border-bottom: 1px solid #fff;   
    }
    .containt_2{
        height:max-content ;
        position: relative;
       width: 90%;
    }
    .containt_2 img{
        width: 100%;
        position: absolute;
        opacity: 0.1;
        top:200px;
        height: 900px;
    }
    .site_cookie{
        margin:20px 0px 0px 80px;
        /* position: absolute; */
    }
    .site_cookie h3 {
        color:rgb(207,31,33);
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
    }
    
    .site_cookie p{
        line-height: 20px;
        font-size: 16px;
        color: #616161;
        
    }
    .site_cookie h5{
      color: black;
      font-weight: bold;
    }
</style>
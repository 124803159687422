<template>
  <div>
    <div class="slider" @mouseover="stopAutoSlide" @mouseleave="startAutoSlide">
      <div class="slider-container">
        <div class="slider-slide" v-for="(photo, index) in photos" :key="index" :style="getSlideStyle(index)">
          <div class="contenuSlider mr-44 text-left text-white">
            <h1 class="font-bold text-3xl">{{ photo.title }}</h1>
            <br />
            <p>{{ photo.description }}</p>
            <br />
            <button class="enSavoirPlus" @click="redirectPage(photo.url)">En savoir plus<font-awesome-icon icon="fa-solid fa-arrow-right" /></button>
          </div>
          <div class="blur"></div>
          <img :src="photo.src" alt="" />
        </div>
      </div>
    </div>

    <div class="pagination">
      <span
        v-for="(photo, index) in photos"
        :key="index"
        :class="{ active: currentIndex === index }"
        @click="setCurrentSlide(index)"
      >
      </span>
    </div>

    <div class="controls">
      <button @click="prevSlide"><font-awesome-icon class="font-right" icon="fa-solid fa-chevron-left" /></button>
      <button @click="nextSlide"><font-awesome-icon class="font-right" icon="fa-solid fa-chevron-right" /></button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      photos: [
        {
          id: 1,
          src: "https://gthpdf.fra1.digitaloceanspaces.com/casablanca.jpg",
          title: "GTHCONSULT, BUREAU DE CONTRÔLE RÉGLEMENTAIRE AGRÉÉ PAR L’ÉTAT ASSURE UNE PARFAITE MAITRISE DE RISQUE",
          description: "",
          url : "/#ourHome"
        },
        {
          id: 2,
          src: "https://gthpdf.fra1.digitaloceanspaces.com/elec.png",
          title: "CONTRÔLE RÉGLEMENTAIRE DES INSTALLATIONS TECHNIQUES",
          description:"Mettre en conformité de vos équipements et installations technique au regard de la réglementation et aux normes en vigueurs",
          url : "/controle-reglementaire-et-volontaire"
        },
        {
          id: 3,
          src: "https://gthpdf.fra1.digitaloceanspaces.com/PHOTO-RISQUE_AU_TRAVAIL.jpg",
          title: "RISQUE AU TRAVAIL",
          description:"Prevenir les risques professionnels : sante & securite preserver la sante et la securite au travail",
          url : "/evaluation-des-risques-au-travail"

        },
        {
          id: 4,
          src: "https://gthpdf.fra1.digitaloceanspaces.com/env.jpg",
          title: "PRELEVEMENTS ENVIRONNEMENTAUX",
          description:"Pour répondre à vos besoins, GTHCONSULT met à votre disposition un laboratoire agréé et accrédité sur les analyses environnementales, et des personnels qualifiés intervenants sur le terrain avec des matériels adaptés",
          url : "/prelevements-environnementaux"
        },
        {
          id: 5,
          src: "https://gthpdf.fra1.digitaloceanspaces.com/formation.jpg",
          title: "FORMATIONS TECHNIQUE",
          description:"",
          url : "/formations-techniques"
        },
      ],
      currentIndex: 0,
      slideInterval: null, 
      Duration: 6000, 
    };
  },
  methods: {
    
    redirectPage(url) {

      if(url == "/#ourHome") {
        window.location.href = "/#ourHome";
      } else {
        this.$router.push({ path: url })
      }

    },

    setCurrentSlide(index) {
      this.currentIndex = index;
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.photos.length;
    },
    prevSlide() {
      this.currentIndex =
        (this.currentIndex - 1 + this.photos.length) % this.photos.length;
    },
    getSlideStyle(index) {
      if (index === this.currentIndex) {
        return {
          display: "block",
        };
      } else {
        return {
          display: "none",
        };
      }
    },
    //auto slide 4 seconds
    startAutoSlide() {
      this.slideInterval = setInterval(() => {
        this.nextSlide();
      }, this.Duration);
    },
    stopAutoSlide() {
      clearInterval(this.slideInterval);
    },
  },
  created() {
    this.startAutoSlide(); 
  },
};
</script>

<style scoped>

*{
  font-family: Cairo,Verdana,Tahoma,Arial;
}
.slider {
  height: 100vh;
  width: 100%;
  margin: 0 auto;
}
.blur {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  width: 100%;
  height: 100vh;
  opacity: 0.5;
}

.slider-container {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.slider-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.7s;
}
.slider-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.contenuSlider {
  position: absolute;
  z-index: 5;
  margin-top: 200px;
  margin-left: 250px;
  max-width: 700px;
  min-width: 100px;
}
.contenuSlider  h1{
  font-size: 36px;
  line-height: 1.3em;
  text-shadow: 5px 1px 2px rgba(1, 1, 1, 0.72);
}
.contenuSlider  p{
  font-size: 24px;
  text-shadow: 5px 1px 2px rgba(1, 1, 1, 0.72);
  line-height: 1.5em;
  text-align: justify;
}
.contenuSlider  button svg{
  margin-left: 5px;
}
.controls {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  position: absolute;
  top: 0;
  margin-top: 350px;
  width: 100%;
  height: 30%;
  z-index: 2;
}
.controls button .font-left, .controls button .font-right {
  width: 60px;
  height: 60px;
  color: rgb(86, 190, 37, 0.5);
  transition  : 0.3s all ease;
}

.controls button:hover .font-left, .controls button:hover .font-right {
  width: 70px;
  height: 70px;
  color: rgb(86, 190, 37, 0.9);
}

.pagination {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 10%;
}

.pagination span {
  display: inline-block;
  margin-top: 100vh;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}

.pagination span.active {
  background-color: rgb(86, 190, 37, 0.9);
}
.enSavoirPlus {
    color: white;
    letter-spacing: 2px;
    font-weight: 400px;
    transition: 0.6s;
    border: 2px solid white;
    padding: 10px 13px 10px 8px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
}
.enSavoirPlus:hover {
  background-color: white;
  color: #003478;
}
@media screen and (max-width: 1168px) {
  .enSavoirPlus {
    width:160px;
  }
  .contenuSlider{
    margin-left: 100px;
    margin-top: 100px;
    margin-right: 50px;
  }
  .controls button{
    width:100px;
  }
}
@media screen and (max-width: 768px) {
  .contenuSlider {
    margin-left: 100px;
    margin-top: 100px;
    margin-right: 50px;
  }

  .contenuSlider  h1{
    font-size: 16px;
  }
  .contenuSlider  p{
    font-size: 10px;
  }

  .enSavoirPlus {
    color: white;
    font-weight: 100px;
    border: 1px solid white;
    padding: 4px;
    border-radius: 4px;
    font-size: 6px;
  }

  .controls button .font-left, .controls button .font-right {
    width: 20px;
    height: 20px;
    color: rgb(86, 190, 37, 0.5);
    transition  : 0.3s all ease;
  }

  .controls button:hover .font-left, .controls button:hover .font-right {
    width: 30px;
    height: 30px;
    color: rgb(86, 190, 37, 0.9);
  }

}


</style>

<template>
  <div class="parent_3">
        <div class="screen_3">
            <img src="https://gthpdf.fra1.digitaloceanspaces.com/Nos_Engagements-min.jpg">
            <div class="opacity"></div>
            <a href="#">LE GROUPE</a>
            <h1>NOS ENGAGEMENTS</h1>
        </div>
        <div class="presentation">
          <p class="resume">Notre politique QSE (Qualité Sécurité Environnement) traduit l’engagement de la direction et ses collaborateurs, GTHCONSULT à mettre en œuvre un système de management responsable en terme de la qualité, de sécurité et de respect de l’environnement. Notre but est de maintenir l’amélioration continue de cette politique QSE pour en garantir l’efficacité.</p>
          <font-awesome-icon icon="fa-solid fa-circle-down" />
        </div>
        <div class="containt_3">
            <img src="../assets/logogth.png" class="logo">
            <div class="engagements">
                  <h1 class="titre">Découvrez nos engagements Qualité Sécurité Environnement</h1> 
                  <div class="engagement">
                    <h2 class="titre">NOS ENGAGEMENTS</h2>
                    <ul class="rows">
                      <li class="columns">
                        <img src="https://gthpdf.fra1.digitaloceanspaces.com/teamwork-min.jpg">
                      </li>
                      <li class="columns">
                          <p>ORGANISME GTHCONSULT, soucieux d’assurer la sécurité et de protéger la santé de 
                          ses salariés a mis en œuvre, depuis sa création, des mesures visant à assurer l’intégrité physique de ses collaborateurs et la protection de l’environnement
                          quelles que soient les missions proposées.
                          </p>
                      </li> 
                    </ul>
                  </div>
                  <div class="security">
                      <h2 class="titre">SANTÉ ET SÉCURITÉ</h2>
                      <ul class="rows">
                        <li class="columns">
                          <ul class="number">
                            <li> N 01- La maîtrise et la prévention des risques identifiés;</li>
                            <li>N 02- Le maintien d’un environnement de travail sain pour tous nos collaborateurs, au travers de la prévention des risques psychosociaux;</li>
                            <li>N 03- L’amélioration de la qualité de vie au travail via le réaménagement des espaces de travail;</li>
                            <li>N 04- La formation et l’habilitation des collaborateurs nécessaires à l’exercice de leurs missions ainsi qu’aux exigences clients;</li>
                            <li>N 05- La sensibilisation de l’ensemble du personnel aux aspects santé et sécurité;</li>
                            <li>N 06- La mise à disposition des collaborateurs de matériels et équipements de protection individuelle nécessaires à leurs activités;</li>
                            <li>N 07- L’analyse des accidents et incidents, avec mise en place de mesures de prévention et de protection destinées à éviter que de tels événements se reproduisent.</li>
                          </ul>
                        </li>
                        <li class="columns">
                          <img src="https://gthpdf.fra1.digitaloceanspaces.com/Sante_securite-min.jpg">
                        </li>
                      </ul>
                  </div>
                  <div class="environnement">
                      <h2 class="titre">ENVIRONNEMENT </h2>
                      <ul class="rows">
                        <li class="columns">
                          <img src="https://gthpdf.fra1.digitaloceanspaces.com/environnement.jpg">
                        </li>
                        <li class="columns">
                          <ul class="number">
                            <li>N 01- Le suivi, la maitrise et la réduction de nos consommations en énergie;</li>
                            <li>N 02- La mise en place d’une gestion éco-responsable de nos bureaux et équipements techniques;</li>
                            <li>N 03- La réduction de la pollution et de l’effet de serre, via la sélection des véhicules;</li>
                            <li>N 04- l’optimisation des déplacements des collaborateurs, la politique de gestion du personnel privilégiant le rapprochement domicile/travail;</li>
                            <li>N 05- La généralisation d’outils de mise en ligne de nos rapports et avis;</li>
                            <li>N 06- La sensibilisation de l’ensemble du personnel aux aspects environnementaux et énergétiques.</li>
                          </ul>
                        </li>
                      </ul>
                  </div>
                  <div class="responsability">
                    <h2 class="titre">NOS RESPONSABILITÉS</h2>
                    <ul class="rows">
                      <li class="columns">
                          <p>Les obligations en matière de sécurité de l’employeur et des salariés sont
                              définies de façon précise par le Code du travail.
                          </p>
                      </li> 
                      <li class="columns">
                          <img src="https://gthpdf.fra1.digitaloceanspaces.com/Nos_responsabilite.jpg">
                        </li>
                    </ul>
                  </div>
            </div>
      
       </div>
  </div>
</template>

<script>
export default {
    name : "EngagementView",
    data() {
        return {
            
        }
    }
}
</script>

<style scoped>

  *{
      margin:0; 
      padding: 0;
      box-sizing: border-box;
        font-family: Cairo,Verdana,Tahoma,Arial;
     
      text-decoration: none;
      list-style: none;
    }
  
  .titre {
    text-align: center;
    font-size: xx-large;
    font-weight: 700;
    font-size: 1.77777778rem;
    line-height: 120%;
    color: #00153C;
    letter-spacing: .02em;
  }

  .resume {
      font-family: Cairo,Verdana,Tahoma,Arial;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      letter-spacing: 0em;
      color: white;
      text-align: justify;
  }

  .badget {
      margin-top: 20px;
      font-family: Cairo,Verdana,Tahoma,Arial;
      font-style: normal;
      font-weight: 200;
      font-size: 20px;
      line-height: 150%;
      letter-spacing: 0em;
      color: #616161;
  }


    .imgg {
    width: 100%;
    height: 300px;
    overflow:hidden;
    position: relative;
    background-image: url("https://gthpdf.fra1.digitaloceanspaces.com/Nos_Engagements-min.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .text-overlay {
      padding: 5%;
      margin: auto;
      position: absolute;
      z-index: 999;
      text-align: center;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      background-color: rgb(1, 1, 1, 0.6);
  }
  .text-overlay > h2 {
    color : #56be25;
    font-size: 40px;
  }

  
    .parent_3{
        height:max-content;
    }
    .screen_3{
        height: 80vh;
        position: relative;
    }
    .screen_3 img{
        width: 100%;
        height:100%;
    }
    .screen_3 .opacity{
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(36, 48, 100,0.4);
    }
    .screen_3 h1{
        color: #fff;
        position: absolute;
        top: 50%;
        left:35%;
        font-size: 30px;
    }
    .screen_3 a{
        color:#fff;
        position:absolute;
        top: 45%;
        left: 35%;
        text-transform: uppercase;
    }
    .screen_3 a:hover{
        border-bottom: 1px solid #fff;   
    }
    .containt_3{
        height:max-content ;
        position: relative;
       width: 90%;
       margin-left: 4%;
    }
    .containt_3 .logo{
        width: 100%;
        position: absolute;
        opacity: 0.1;
        top:200px;
        height: 900px;
        z-index: -20;
    }
    .containt_3 div{
        width:100%;
        height:25%;
        margin-top:50px ;
    }
    .containt_3 .rows{
      display: flex;
      flex-direction:row ;
      justify-content: space-around;
      align-items: center;
    }
    .containt_3 .rows .columns{
      width:50%;
        height:25%;
        margin-left: 20px;
    }
    .containt_3 img{
      height: 350px;
    }
    .containt_3 h1{
      color:rgb(36, 48, 100);
      font-size:30px;
      font-weight: bold;
      margin-left: 20px;
      text-transform: uppercase;
    }
    .containt_3 h2{
      color:rgb(207,31,33);
      font-size: 30px;
      margin-left: 20px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .containt_3 .number li{
      margin-top: 20px;
      font-family: Cairo,Verdana,Tahoma,Arial;
      font-style: normal;
      font-weight: 200;
      font-size: 20px;
      color: rgb(36, 48, 100);
      font-size: 16px;
    }
    .containt_3 p{
      color: #616161;
      font-size: 17px;
    }
    .presentation{
      width: 100%;
      height: max-content;
      background-color: rgb(36, 48, 100);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 20px;

    }
    .presentation p{
      padding: 50px;
    }
    .presentation svg{
      font-size: 40px;
      color : white;
    }

    @media (max-width:700px){
      .containt_3 .rows{
      display: flex;
      flex-direction:column;
      
    }
    .containt_3 .rows .columns{
      width:100%;
        height:25%;
        margin-left: 20px;
        margin-bottom: 20px;
    }
    .containt_3 h1{
      font-size: 25px;
    }
  }
</style>
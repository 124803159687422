<template>
    <div class="parent">

        <div class="header">
            <img class="logo" src="../assets/logogth.png"/>
            <div class="titre">QUESTIONNAIRE DE SATISFACTION CLIENT</div>
            <ul class="reference">
                <li>Référence : F.PS.01.04</li>
                <li>Édition : 02</li>
                <li>Date : 24/04/2024</li>
                <li>Page : 1/1</li>
            </ul>
        </div>

        <div class="barr"></div>

        <div class="itresse">
            <h3>Votre avis nous intéresse!</h3>
            <p>Chez <span class="gthconsult">GTHCONSULT</span>, nos clients sont au cœur de nos préoccupations. Nous vous remercions sincèrement de</p>
            <p>prendre quelques instants pour compléter ce questionnaire. Vos réponses seront prises en compte pour façonner</p>
            <p>en permanence des solutions adaptées à vos besoins. <span class="gthconsult">GTHCONSULT</span> vous remercie pour votre contribution.</p>
        </div>

        <div class="barr"></div>

        <ul class="client">
            <p>Veuillez remplir les informations suivantes</p>
            <li>Société<input type="text" :value="raisonSocial" disabled></li>
            <li>Secteur d’activité
                <select v-model="secteurActivite">
                    <option v-for="item in secteurs" :value="item" :key="item">{{ item }}</option>
                </select>
                <input v-if="secteurActivite=='Autre'" type="text" v-model="secteurActivite">
            </li>
            <li>Nom de l’évaluateur (obligatoire)<input type="text" v-model="nomEvaluateur"></li>
            <li>Prénom de l’évaluateur (obligatoire)<input type="text" v-model="prenomEvaluateur"></li>
            <li>Fonction (facultatif)<input type="text" v-model="fonctionEvaluateur"></li>
            <li>N° de Téléphone (facultatif)<input type="text" v-model="telephoneEvaluateur"></li>
            <li>Email de l’évaluateur (facultatif)<input type="email" v-model="emailEvaluateur" disabled></li>
            <li>Lieu (facultatif)<input type="text" v-model="lieuEnvoyer"></li>
            <li>Date (facultatif)<input type="date" v-model="dateEnvoyer"></li>
            <li>Par quel moyen avez-vous trouvé GTHCONSULT ?
                <select v-model="source">
                    <option v-for="item in sources" :value="item" :key="item">{{ item }}</option>
                </select>
            </li>
            <li>Quels types de prestations avez-vous bénéficié de la part de GTHCONSULT ?</li>
            <li>
                <table id="customers">
                    <tr v-for="(item, index) in prestations" :key="item.id">
                        <td><input type="checkbox" v-model="prestations[index].value"></td>
                        <td>{{ item.text }}</td>
                    </tr>
                </table>
            </li>
        </ul>

        <div class="barr-question">I - Évaluation du degré d'attachement de nos clients</div>
        <p class="question-recommander">Recommanderiez-vous GTHCONSULT à d'autres entreprises ayant des besoins similaires à nos services ?</p>
        <p class="question-degre">Veuillez choisir votre réponse ci-dessous</p>

        <div class="echelle">
            <li :class="isDefaultButtonColorRecommandation" @click="changeColorRecommandation">Recommandation</li>
            <li :class="isDefaultButtonColorNeutre" @click="changeColorNeutre">Neutre</li>
            <li :class="isDefaultButtonColorPasRecommandation" @click="changeColorPasRecommandation">Pas de recommandation</li>
        </div>

        <div class="barr-question">II - Évaluation du niveau de satisfaction de nos clients</div>
        <p class="question-degre">Veuillez choisir un seul choix</p>
        <table id="customers">
            <tr>
                <th>Les questions</th>
                <th>Non satisfait</th>
                <th>Moyennement satisfait</th>
                <th>Satisfait</th>
                <th>Très satisfait</th>
            </tr>
            <tr v-for="item in questions" :key="item.id">
                <td>{{ item.question }}</td>
                <td><input type="checkbox" v-model="item.mauvais"></td>
                <td><input type="checkbox" v-model="item.mediocre"></td>
                <td><input type="checkbox" v-model="item.satisfaction"></td>
                <td><input type="checkbox" v-model="item.bien"></td>
            </tr>
            <div class="commentaires">
              <p>* Pour accéder à notre plateforme E-GTH CLIENT, n'hésitez pas à nous contacter dès maintenant. <a href="https://gthconsult.com/#contact" target="_blank">Cliquez ici</a></p>
            </div>
        </table>

        <div class="barr-question">Commentaires</div>

        <div class="commentaires">
            <p>Votre avis nous intéresse, n'hésitez pas à nous faire part de toutes remarques ou suggestions pour améliorer Nos prestations?</p>
            <textarea v-model="commentaire" id="" cols="30" rows="10"></textarea>
            <button @click="envoyer">Envoyer</button>
        </div>

        <p class="ref">Référence : {{ this.id }}</p>
        <p class="date">Date : {{ new Date(this.date).toLocaleDateString() }}</p>

        <ConfirmationModel v-if="flagConfirmation == true" :text="textConfirmation" @closeConfirmation="closeConfirmation()" @validerConfirmation="validerConfirmation()" />
        <NotAccessPageModel v-if="flagNotAccess" :text="textNotAccess" />


    </div>
    
</template>

<script>
import ConfirmationModel from  "@/components/models/ConfirmationModel.vue"
import NotAccessPageModel from  "@/components/models/NotAccessPageModel.vue"
import Satisfaction from "@/connection/Satisfaction"
import Urls from "@/connection/Urls"
export default {
    name : "EnqueteSatisfaction",
    data() {
        return {
            echelleSelect : null,
            isDefaultButtonColorRecommandation : "is-default-button-color",
            isDefaultButtonColorNeutre : "is-default-button-color",
            isDefaultButtonColorPasRecommandation : "is-default-button-color",
            echelles : [
                { value : "Recommandation" },
                { value : "Neutre" },
                { value : "Pas de recommandation" }
            ],
            secteurs : ["Agroalimentaire", "Textile et habillement", "Chimie et parachimie", "Automobile", "Électronique,câblage, etc", "BTP (Bâtiment et travaux publics)", "Tourisme", "Services", "Agriculture", "Autre"],
            sources : ["Recommandation", "Site internet", "Mailing", "Avis en ligne", "Recherche", "Bouche-à-oreille", "Autre"],
            prestations : [
                { text : "Prestations de contrôle règlementaire" , value : false },
                { text : "Prestations d’assistance technique", value : false },
                { text : "Prestations des formations", value : false },
                { text : "Autre", value : false }
            ],
            textNotAccess : null,
            flagNotAccess : false,
            flagConfirmation : false,
            textConfirmation : null,
            id : null,
            pathUrl : null,
            secteurActivite : null,
            source : null,
            prestation : null,
            raisonSocial :null,
            adresse :null,
            ville : null,
            codePostal : null,
            pays : null,
            email : null,
            telephone : null,
            ice : null,
            date : null,
            nomEvaluateur : null,
            prenomEvaluateur : null,
            fonctionEvaluateur : null,
            telephoneEvaluateur : null,
            emailEvaluateur : null,
            lieuEnvoyer : null,
            dateEnvoyer : null,
            commentaire : null,
            questions : [
                { id : 1, question : "Notre réactivité envers vos demandes ?", mauvais : false , mediocre : false , satisfaction : false, bien :false },
                { id : 2, question : "Respect des dates et des horaires d’intervention ?", mauvais : false , mediocre : false , satisfaction : false, bien :false },
                { id : 3, question : "Qualité de nos prestations ?", mauvais : false , mediocre : false , satisfaction : false, bien :false },
                { id : 4, question : "Pertinence de nos observations ?", mauvais : false , mediocre : false , satisfaction : false, bien :false },
                { id : 5, question : "Qualité de nos rapports ?", mauvais : false , mediocre : false , satisfaction : false, bien :false },
                { id : 6, question : "Qualité de nos inspecteurs/formateurs", mauvais : false , mediocre : false , satisfaction : false, bien :false },
                { id : 7, question : "Qualité de notre plateforme E-GTH CLIENT ? *", mauvais : false , mediocre : false , satisfaction : false, bien :false }
            ],
        }
    },

    components : {
        ConfirmationModel,
        NotAccessPageModel
    },

    methods : {

        changeColorRecommandation() {

            if(this.isDefaultButtonColorRecommandation === 'is-default-button-color'){
                        this.isDefaultButtonColorRecommandation = 'is-green-color';
                        this.echelleSelect = "Recommandation";
            } else {
                this.isDefaultButtonColorRecommandation = 'is-default-button-color';
            }


        },

        changeColorNeutre() {

            if(this.isDefaultButtonColorNeutre == 'is-default-button-color'){
                this.isDefaultButtonColorNeutre = 'is-grey-color';
                this.echelleSelect = "Neutre";
            } else {
                this.isDefaultButtonColorNeutre = 'is-default-button-color';
            }
        },

        changeColorPasRecommandation() {
            if(this.isDefaultButtonColorPasRecommandation == 'is-default-button-color'){
                this.isDefaultButtonColorPasRecommandation = 'is-red-color';
                this.echelleSelect = "Pas de recommandation";
            } else {
                this.isDefaultButtonColorPasRecommandation = 'is-default-button-color';
            }
        },

        envoyer() {

            this.textConfirmation = "Êtes-vous sûr des informations saisies ?",
            this.flagConfirmation = true;
        },

        closeConfirmation() {
            this.flagConfirmation = false;
            return window.open('','_self').close();
        },

        validerConfirmation() {
           this.flagConfirmation = false;
           Satisfaction.envoyer(this.id, this.raisonSocial, this.secteurActivite, this.nomEvaluateur, this.prenomEvaluateur, this.fonctionEvaluateur, this.telephoneEvaluateur, this.emailEvaluateur, this.lieuEnvoyer, this.dateEnvoyer, this.questions, this.commentaire, this.echelleSelect, this.source, this.prestations)
            .then(() => {
                this.flagConfirmation = false;
                return window.open('','_self').close();
            })
            .catch((error) => {
                console.log(error);
            });
        }

    },

    mounted() {

        const {
            pathUrl
        } = this.$route.params;

        
        Urls.read(pathUrl)
        .then((result) => {


            if(result.data.url == null) {
                this.flagNotAccess = true;
                this.textNotAccess = "Nous sommes désolés, l'enquête est actuellement indisponible. Merci de votre compréhension";
            } else {
                this.id = result.data.url._id;
                this.pathUrl = result.data.url.pathUrl;
                this.raisonSocial = result.data.url.raisonSocial;
                this.adresse = result.data.url.adresse;
                this.ville  = result.data.url.ville;
                this.codePostal = result.data.url.codePostal;
                this.pays = result.data.url.pays;
                this.email = result.data.url.email;
                this.emailEvaluateur = result.data.url.email;
                this.telephone = result.data.url.telephone;
                this.ice = result.data.url.ice;
                this.date = result.data.url.date;
            }


        })
        .catch((error) => {
            console.log(error.message)
        });

    }

}
</script>

<style scoped>
    .parent {
        height: auto;
        width: 100%;
    }

    .header {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-content: center;
    }

    .header img {
        height: 100px;
    }

    .header .titre {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: x-large;
        font-weight: bolder;
        color: #243064;
    }

    .header .reference {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .itresse {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .itresse h3 {
        font-size: x-large;
        font-weight: bolder;
        color: red;
    }
    .itresse p {
        padding: 5px;
    }
    .question-recommander {
        margin-top: 20px;
        padding: 5px;
        color: blue;
        text-align: center;
        width: 100%;
        font-size: 22px;
    }
    .question-degre {
        padding: 5px;
        color: red;
        text-align: center;
        width: 100%;
    }

    .note {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    .note p {
        color: blue;
        font-size: 20px;
    }

    .gthconsult {
        color: #243064;
        font-weight: bold;
    }

    .barr {
        margin-top: 20px;
        height: 5px;
        width: 100%;
        background-color: #243064;
    }
    .barr-question {
        margin-top: 20px;
        height: auto;
        font-size: x-large;
        font-weight: bolder;
        padding: 5px;
        color: #efefef;
        width: 100%;
        background-color: #243064;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .client {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .client p {
        color: red;
    }

    .client li {
        width: 500px;
        color: #243064;
        margin: 5px;
    }
    .client li input {
        width: 100%;
        height: 50px;
        background-color: #efefef;
        padding: 4px;
        color: black;
    }

    .client li select {
        width: 100%;
        height: 50px;
        background-color: #efefef;
        padding: 4px;
        color: black;
    }

    #customers {
        margin-top: 20px;
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    #customers td, #customers th {

        border: 1px solid #ddd;
        padding: 8px;

    }

    #customers tr:nth-child(even){
        background-color: #f2f2f2;
    }

    #customers tr:hover {
        background-color: #ddd;
    }

    #customers th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #f13809;
        color: white;
    }

    .commentaires {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .commentaires p {
        color: red;
    }
    .commentaires p a {
        color: blue;
        font-size: larger;
    }
    .commentaires textarea{
        margin-top: 10px;
        width: 80%;
        height: 400px;
        background-color: #efefef;
        padding: 4px;
        color: black;
    }
    .commentaires button{
        margin-top: 10px;
        padding: 10px;
        border-radius: 5px;
        background-color: rgba(255, 0, 0, 0.644);
        color: white;
        border: 0px;
    }
    .commentaires button:hover{
        background-color: red;
    }

    .is-default-button-color {
        background-color: rgb(36, 117, 238);
        padding: 20px;
        margin-left: 5px;
        margin-right: 5px;
        border-radius: 20px;
        color: white;
        font-size: larger;
        cursor: pointer;
    }
    .is-default-button-color:hover {
        background-color: black;
        padding: 20px;
        margin-left: 5px;
        margin-right: 5px;
        border-radius: 20px;
        color: white;
        font-size: larger;
        cursor: pointer;
    }

    .is-green-color {
        background-color:green;
        padding: 20px;
        margin-left: 5px;
        margin-right: 5px;
        border-radius: 20px;
        color: white;
        font-size: larger;
        cursor: pointer;
    }
    .is-red-color {
        background-color: red;
        padding: 20px;
        margin-left: 5px;
        margin-right: 5px;
        border-radius: 20px;
        color: white;
        font-size: larger;
        cursor: pointer;
    }
    .is-grey-color {
        background-color: grey;
        padding: 20px;
        margin-left: 5px;
        margin-right: 5px;
        border-radius: 20px;
        color: white;
        font-size: larger;
        cursor: pointer;
    }

    .echelle {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        width: 100%;
        justify-content: center;
    }

    .echelle .notSelect {
        background-color: #b6b6b6;
        margin: 4px;
    }
    .echelle .select {
        background-color: #2502c5;
        margin: 4px;
        color: white;
    }

    .echelle ul li {
        padding: 16px;
        font-size: 20px;
        cursor: pointer;
    }

    .ref , .date {
        margin-left: 10px;
    }


</style>
<template>
    <div class="gestionInspecteur">

        <h3 v-if="flagEditLecon == false">FICHES DES LEÇONS</h3>

        <div class="rechercher-table" v-if="flagEditLecon == false">

            <div class="rechercher">
                <input type="text" v-model="rechercher" placeholder="Recherche un leçon">
            </div>

            <table id="inspecteurs">
                <tr>
                    <th>Titre</th>
                    <th>Auteur</th>
                    <th>Actions</th>
                </tr>
                <tr v-for="(item, i) in filterlecon" :key="i">
                    <td>{{ item.titre }}</td>
                    <td>{{ item.auteur }}</td>
                    <td>
                        <a @click="deleteLecon(i)" title="Supprimer">Supprimer</a>
                        <a @click="editLecon(i)" title="Modifier">Modifier</a>
                    </td>
                </tr>
            </table>

        </div>

        <ul class="pagination" v-if="flagEditLecon == false">
            <button @click="selectPageLeft()"><i class="fa-solid fa-backward"></i></button>
            <li v-for="n in numberPage" :key="n" @click="selectPage(n)">
                <p v-show="numberPageSelect[n - 1] == false">{{ n }}</p>
                <p v-show="numberPageSelect[n - 1] == true" style="background-color: #0300db; color: white;">{{ n }}</p>
            </li>
            <button @click="selectPageRight()"><i class="fa-solid fa-forward"></i></button>
        </ul>


        <!--  Start Edit SousTraitance   -->
        <EditLecon v-if="flagEditLecon == true" :leconId="leconId" />
        <!--  End Edit SousTraitance   -->

        <!--  Start Confirmation Model   -->
        <ConfirmationModel v-if="flagConfirmationModel == true" :text="textConfirmation"
            @closeConfirmation="closeConfirmation()" @validerConfirmation="validerConfirmation()" />
        <!--  End Confirmation Model   -->

        <!--  Start Confirmation Model   -->
        <ErrorModel v-if="flagErrorModel == true" :text="text" :titre="titre" />
        <!--  End Confirmation Model   -->

        <!--  Start Info Delete Model   -->
        <LandingModel v-if="flagLandingModel == true" />
        <!--  End Info Delete Model   -->

        <!--  Start Confirmation Model   -->
        <NotAccessPageModel v-if="flagNotAccessPageModel == true" />
        <!--  End Confirmation Model   -->

    </div>
</template>
  
<script>
import Lecons from "@/connection/Lecons";
import EditLecon from "./EditLecon.vue";
import ConfirmationModel from "@/components/models/ConfirmationModel.vue";
import ErrorModel from "@/components/models/ErrorModel.vue";
import LandingModel from "@/components/models/LandingModel.vue";
import NotAccessPageModel from "@/components/models/NotAccessPageModel.vue";

export default {
    name: "gestionAdmin",
    components: {
        EditLecon,
        ConfirmationModel,
        ErrorModel,
        LandingModel,
        NotAccessPageModel
    },
    data() {
        return {
            numberPageSelect: [],
            leconDelete: null,
            textDeleteModel: null,
            pageIndex: 0,
            itemsPerPage: 10,
            numberPage: null,
            succes: false,
            echec: false,
            msg: null,
            rechercher: null,
            prelecons: [],
            lecons: [],
            flagEditLecon: false,
            flagConfirmationModel: false,
            flagErrorModel: false,
            flagLandingModel: false,
            flagNotAccessPageModel: false,
            flagModelDelete: false,
            leconId: null,
            text: null,
            titre: null
        };
    },

    computed: {

        filterlecon() {
            return this.lecons.filter((item) => {

                if (!this.rechercher) {
                    if (item != undefined) {
                        return item;
                    }

                } else {
                    if (item != undefined) {
                        return !item.titre.toString().indexOf(this.rechercher.toLowerCase().toString()) ||
                            !item.auteur.toString().indexOf(this.rechercher.toLowerCase().toString());
                    }
                }

            })
        }

    },

    methods: {

        selectPageLeft() {

            for (let i = 0; i < this.numberPage; i++) {
                this.numberPageSelect[i] = false;
            }

            this.numberPageSelect[0] = true;

            this.lecons = [];
            for (let i = 0 * this.itemsPerPage; i < (0 * this.itemsPerPage) + this.itemsPerPage; i++) {
                this.lecons.push(this.prelecons[i]);
            }

        },

        selectPageRight() {

            for (let i = 0; i < this.numberPage; i++) {
                this.numberPageSelect[i] = false;
            }

            const indice = this.numberPage - 1;
            this.numberPageSelect[indice] = true;
            this.lecons = [];
            for (let i = indice * this.itemsPerPage; i < (indice * this.itemsPerPage) + this.itemsPerPage; i++) {
                this.lecons.push(this.prelecons[i]);
            }
        },

        selectPage(value) {

            const select = value - 1;
            // change current select for change color
            for (let i = 0; i < this.numberPage; i++) {
                this.numberPageSelect[i] = false;
            }

            this.numberPageSelect[select] = true;
            this.lecons = [];
            for (let i = select * this.itemsPerPage; i < (select * this.itemsPerPage) + this.itemsPerPage; i++) {
                this.lecons.push(this.prelecons[i]);
            }
        },



        closeConfirmation() {
            this.flagConfirmationModel = false;
        },

        validerConfirmation() {

            this.flagConfirmationModel = false;
            this.flagLandingModel = true;
            // delete in db backend
            Lecons.delete(this.lecons[this.leconDelete]._id)
                .then((response) => {
                    if (response) {
                        this.lecons.splice(this.leconDelete, 1)
                        this.flagLandingModel = false;
                    }
                })
                .catch((error) => {
                    this.msg = error.message;
                    console.error(`HTTP error: ${error.name} => ${error.message}`);
                    throw "fail request at: GET /refreshtime";
                });
        },


        // delete one admin
        deleteLecon(i) {
            this.flagConfirmationModel = true;
            this.textConfirmation = "Êtes-vous sûr de supprimer ?";
            this.leconDelete = i;
        },

        // edit one admin
        editLecon(i) {
            this.flagEditLecon = true;
            this.leconId = this.lecons[i]._id;
        }


    },

    //clients
    created() {

        Lecons.read()
            .then((result) => {

                if (result.data.lecons.length != 0) {

                    result.data.lecons.forEach(element => {
                        this.prelecons.push(element);
                    });

                    this.numberPage = Math.ceil(result.data.lecons.length / this.itemsPerPage);

                    for (let i = 0; i < this.numberPage; i++) {
                        this.numberPageSelect.push(false);
                    }

                    this.numberPageSelect[0] = true;

                    for (let i = this.pageIndex * this.itemsPerPage; i < (this.pageIndex * this.itemsPerPage) + this.itemsPerPage; i++) {
                        this.lecons.push(this.prelecons[i]);
                    }
                }



            })
            .catch((error) => {
                this.msg = error.message;
                console.error(`HTTP error: ${error.name} => ${error.message}`);
                throw "fail request at: GET /refreshtime";
            });

    }


};
</script>
  
<style scoped>
.succes {
    background-color: green;
    color: white;
    padding: 10px;
    height: fit-content;
    width: 100%;
}

.echec {
    background-color: red;
    color: white;
    padding: 10px;
    height: fit-content;
    width: 100%;
}

.gestionInspecteur {
    width: 100%;
    height: 100%;
    padding-top: 0px;
    padding-left: 0px;
    margin: 0px;
    padding: 2px;
    position: relative;
}

.gestionInspecteur h3 {
    width: 100%;
    margin: auto;
    color: white;
    background: linear-gradient(346deg, rgba(207, 31, 33, 1) 0%, rgba(24, 86, 161, 1) 100%);
    text-align: center;
    text-align: center;
    padding: 15px;
    margin-bottom: 20px;
}

pecteur .rechercher-table {
    width: 100%;
}

.gestionInspecteur .rechercher-table .rechercher {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
}

.gestionInspecteur .rechercher-table .rechercher input {
    width: 50%;
    height: 40px;
    outline: 0;
    border: 1px solid #243064;
    font-size: 16px;
    background-color: white;
    padding: 5px;
    color: #243064;
}

.gestionInspecteur .rechercher-table table {
    width: 100%;
}

.gestionInspecteur .rechercher-table table tr {
    width: 100%;
}

.gestionInspecteur .rechercher-table table tr th {
    background-color: #243064;
    color: white;
    border-right: 1px solid white;
    padding: 10px 4px;
}

table {
    border-collapse: collapse;
}

.gestionInspecteur .rechercher-table table tr td {
    color: black;
    padding: 5px;
}

.gestionInspecteur .rechercher-table table tr:nth-child(even) {
    background-color: #f8f8f8;
}

.gestionInspecteur .rechercher-table table tr td a {
    cursor: pointer;

    margin-right: 10px;
}

.fa-trash {
    color: red;
}

.fa-trash:hover {
    color: rgb(230, 88, 88);
}

.fa-pen-to-square {
    color: rgb(255, 187, 0);
}

.fa-circle-check {
    color: green;
}

.gestionInspecteur .rechercher-table ul {
    width: 100%;
    height: fit-content;
    text-align: center;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.gestionInspecteur .rechercher-table ul li {
    color: black;
    margin-left: 5px;
    cursor: pointer;
    font-size: 18px;
}

.gestionInspecteur .rechercher-table ul li:hover {
    color: red;
    margin-left: 5px;
    transition: 0.3s;
}

.gestionInspecteur .rechercher-table .deleteAll {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.gestionInspecteur .rechercher-table .deleteAll input {
    width: fit-content;
    padding: 10px;
    height: 40px;
    background-color: red;
    color: white;
    border: 0;
    cursor: pointer;
}

#inspecteurs>tr>td:nth-child(10)>a:nth-child(1)>svg {
    color: red;
    font-size: 20px;
}

#inspecteurs>tr>td:nth-child(10)>a:nth-child(2)>svg {
    color: blue;
    font-size: 20px;
}

#inspecteurs>tr>td:nth-child(10)>a:nth-child(3)>svg {
    color: green;
    font-size: 20px;
}

#app>div>div>div.menu-content>div.content>div>h3 {
    background-color: #ff0000d4;
    padding: 15px;
}

#app>div>div>div.menu-content>div.content {
    background-color: white;
}

#inspecteurs>tr>td {
    color: #000000;
    border-bottom: 1px solid #acacac;
    text-transform: capitalize;
}

#app>div>div>div.menu-content>div.content>div {
    background-color: white;
}

#inspecteurs>tr>td:nth-child(8)>a:nth-child(1)>svg {
    color: red;
}

#inspecteurs>tr>td:nth-child(8)>a:nth-child(3)>svg {
    color: green;
}

#inspecteurs>tr>td:nth-child(8)>a:nth-child(4)>svg {
    color: red;
}

#inspecteurs>tr>td:nth-child(8)>a:nth-child(5)>svg {
    color: orange;
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
    width: fit-content;
    flex-wrap: wrap;
    padding: 5px 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.pagination p {
    border: 1px solid rgb(151, 151, 151);
    margin: 0 4px;
    padding: 5px 10px;
    border-radius: 20px;
    cursor: pointer;
}

.pagination p:hover,
button:hover {
    background-color: #243064;
    color: white;
}




.pagination button {
    border: 0;
    padding: 5px;
    border: 1px solid rgb(170, 170, 170);
    margin-left: 15px;
    color: gray;
    cursor: pointer;
}

.pagination button:nth-child(1) {
    margin-right: 15px;
    margin-left: 0;

}
</style>
<template>
  <div class="NouveauFormateur">
    <div class="box">
      <h1>Nouveau Formateur</h1>
        <label for="Email ou Numéro de téléphone">Nom</label>
        <input type="text" maxlength="128" name="text" v-model="formateur.nom" />
        <label for="Prénom">Prénom</label>
        <input type="text" maxlength="128" name="text" v-model="formateur.prenom" />
        <label for="Email ou Numéro de téléphone">Email ou Numéro de Téléphone</label>
        <input type="text" maxlength="128" name="email" v-model="formateur.email" />
        <label for="Mot de passe">Mot de passe</label>
        <input type="text" name="password" v-model="formateur.password" disabled />
        <button @click="enregistrer">Enregistrer</button>
    </div>

    <!--  Start Confirmation Model   -->
      <ErrorModel v-if="flagErrorModel == true" :text="text" :titre="titre" />
    <!--  End Confirmation Model   -->

    <!--  Start Info Delete Model   -->
      <LandingModel v-if="flagLandingModel == true" />
    <!--  End Info Delete Model   -->
  </div>
</template>
  
<script>
import Formateurs from "@/connection/Formateurs";
import ErrorModel from "@/components/models/ErrorModel.vue";
import LandingModel from "@/components/models/LandingModel.vue";

export default {
  name: "NouveauFormateur",
  data() {
    return {
      flagLandingModel : false,
      flagErrorModel : false,
      formateur: {
        nom: null,
        prenom: null,
        email: null,
        password: ""
      },
      text : null,
      titre : null
    }
  },
  components : {
    ErrorModel,
    LandingModel
  },
  methods : {
    generatePassword() {
      var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      var string_length = 8;
      for (var i=0; i<string_length; i++) {
          var rnum = Math.floor(Math.random() * chars.length);
          this.formateur.password += chars.substring(rnum,rnum+1);
      }
    },

    enregistrer() {
      Formateurs.create(this.formateur)
      .then((result) => {
          if(result.data.success) {
            this.formateur.nom = null;
            this.formateur.prenom = null;
            this.formateur.email = null;
            this.formateur.password = "";
            this.generatePassword();
          }
      })
      .catch((error) => {
        this.titre = "Error";
        this.text = error.message
        console.log(error);
      });
    }
  },
  mounted() {
    this.generatePassword();
  } 
}
</script>
  
<style scoped>
.NouveauFormateur {
  display: flex;
  flex-direction: column;
}

.NouveauFormateur .box {
  margin: 20px;
  border-radius: 8px;
  border: 2px #ddd solid;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.NouveauFormateur .box h1 {
  margin-bottom: 10px !important;
  font-size: x-large;
  font-weight: bold;
  font-family: "Amazon Ember", Arial, sans-serif;
}


.NouveauFormateur .box label {
  margin-bottom: 5px !important;
}

.NouveauFormateur .box input[type=text],
.NouveauFormateur .box input[type=password] {
  width: 300px;
  height: 40px;
  margin-bottom: 5px !important;
  background-color: #dddddd54;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid;
  outline: 0px;
}

.NouveauFormateur .box input[type=text]:hover,
.NouveauFormateur .box input[type=password]:hover {
  background-color: white;
  outline: 0px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  outline: 0px;
}


div.NouveauFormateur > div > button {
  margin-bottom: 5px !important;
  margin-top: 20px !important;
  background-color: #0057e3;
  color: white;
  font-size: small;
  font-weight: bold;
  font-family: "Amazon Ember", Arial, sans-serif;
  cursor: pointer;
  width: 300px;
  height: 40px;
  border-radius: 5px;
}
</style>
<template>
    <div class='flex gap-5 flex-col md:flex-row items-center lg:justify-between px-6 md:px-0 py-32' id="agrements">
        <!--         <img src='https://gthpdf.fra1.digitaloceanspaces.com/logokingdoom.9ac1d735.png' width=900 height=800 data-aos="zoom-in" alt='img' class='relative lg:right-20 flex-1 lg:w-[300px] h-auto  lg:object-cover '/>
 -->
        <div class="flex items-center  flex-1 gap-x-1 text-white">

            <div class="space-y-1 overflow-hidden">

                <div class="image-div hover:cursor-pointer relative">
                    <img class="w-64 h-72  duration-300 ease-linear overflow-hidden border object-cover" src="https://gthpdf.fra1.digitaloceanspaces.com/elec.png" alt="">
                        <p class="absolute top-0 left-0 z-40 p-3 font-bold leading-7">AGRÉMENT POUR CONTRÔLE DES INSTALLATIONS ÉLECTRIQUES</p>
                        <!-- <p class="absolute bottom-0 text-blue-400 left-0 z-40 p-3 flex items-center gap-x-3 font-bold ">Lire <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg></p> -->
                </div>

                <div class="image-div hover:cursor-pointer relative text-white">
                    <img class="w-64 h-72  duration-300 ease-linear overflow-hidden border object-cover" src="https://gthpdf.fra1.digitaloceanspaces.com/ACCESSOIRES-DE%20LEVAGE.png" alt="">
                        <p class="absolute top-0 left-0 z-40 p-3 font-bold leading-7">AGRÉMENT POUR CONTRÔLE DES APPAREILS ET ACCESSOIRES DE LEVAGE</p>
                        <!-- <p class="absolute bottom-0 text-blue-400 left-0 z-40 p-3 flex items-center gap-x-3 font-bold ">Lire <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg></p> -->
                </div>
            </div>

            <div class="space-y-1 overflow-hidden">

                <div class="image-div hover:cursor-pointer relative">
                    <img class="w-64 h-72  duration-300 ease-linear overflow-hidden border object-cover" src="https://gthpdf.fra1.digitaloceanspaces.com/assanceur.jpg" alt="">
                    <p class="absolute top-0 left-0 z-40 p-3 font-bold leading-7">AGRÉMENT POUR CONTRÔLE DES ASCENSEURS ET MONTE-CHARGES</p>
                    <!-- <p class="absolute bottom-0 text-blue-400 left-0 z-40 p-3 flex items-center gap-x-3 font-bold ">Lire <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg></p> -->
                </div>

                <div class=" hover:cursor-pointer relative">
                    <img class="w-64 h-72  duration-300 ease-linear overflow-hidden border object-contain"
                        src="https://gthpdf.fra1.digitaloceanspaces.com/logokingdoom.9ac1d735.png"
                        alt="">
                </div>

                <div class="image-div hover:cursor-pointer relative">
                    <img class="w-64 h-72  duration-300 ease-linear overflow-hidden border object-cover" src="https://gthpdf.fra1.digitaloceanspaces.com/appareils-pression.jpg" alt="">
                    <p class="absolute top-0 left-0 z-40 p-3 font-bold leading-7">AGRÉMENT POUR CONTRÔLE DES APPAREILS À PRESSION</p>
                    <!-- <p class="absolute bottom-0 text-blue-400 left-0 z-40 p-3 flex items-center gap-x-3 font-bold ">Lire <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg></p> -->
                </div>


            </div>
            <div class="space-y-1 overflow-hidden">
                <div class="image-div hover:cursor-pointer relative">
                    <img class="w-64 h-72  duration-300 ease-linear overflow-hidden border object-cover"
                    src="https://gthpdf.fra1.digitaloceanspaces.com/trax.png" alt="">
                    <p class="absolute top-0 left-0 z-40 p-3 font-bold leading-7">AGRÉMENT POUR CONTRÔLE DES APPAREILS À PRESSION</p>
                    <!-- <p class="absolute bottom-0 text-blue-400 left-0 z-40 p-3 flex items-center gap-x-3 font-bold ">Lire <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg></p> -->
                </div>

                <div class="image-div hover:cursor-pointer relative">
                    <img class="w-64 h-72  duration-300 ease-linear overflow-hidden border object-cover"
                    src="https://gthpdf.fra1.digitaloceanspaces.com/afnor-certification-iso-9001.png" alt="">
                    <p class="absolute top-0 left-0 z-40 p-3 font-bold leading-7">CERTIFICATION ISO 9001 – 2015 ENCOURS</p>
                    <!-- <p class="absolute bottom-0 text-blue-400 left-0 z-40 p-3 flex items-center gap-x-3 font-bold ">Lire <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg></p> -->
                </div>
            </div>
        </div>
        <div class="flex-1 text-center md:text-left" data-aos="flip-right">
            <p class=' text-[30px] pt-5 lg:text-[40px] font-[600] text-blue-900 '><span class="NosBorder">NOS
                    RECONNAISSANCES</span> EXTERNES AGRÉMENTS MINISTÉRIELS </p>
            <p class='text font-[200] pt-12 max-w-[600px] '>GTHCONSULT possède de nombreuses reconnaissances externes
                (agréments ministériels, certifications et qualifications), qui garantissent notre système qualité et
                technique et prouvent notre compétence, et notre savoir-faire.</p>
            <ul class='pt-8 space-y-3'>
                <li class='flex items-center gap-2 font-[600] text-blue-900 '><span
                        class='bg-gray-200 centred rounded-full text-purple-800 p-2'>
                        <AiOutlineFileSearch class=' text-3xl ' />
                    </span>Vérification des installation électrique - Ministère de l'Emploi</li>
                <li class='flex items-center gap-2 font-[600] text-blue-900 '><span
                        class='bg-gray-200 centred rounded-full text-purple-800 p-2'>
                        <AiOutlineUserSwitch className='text-3xl ' />
                    </span>Vérification des appareils et accessoires de levage - Ministère de l'Emploi</li>
                <li class='flex items-center gap-2 font-[600] text-blue-900 '><span
                        class='bg-gray-200 centred rounded-full text-purple-800 p-2'>
                        <AiOutlineUserSwitch className='text-3xl ' />
                    </span>Vérification des ascenseurs et monte-chargess - Ministère de l'Equipement et Transport</li>
                <li class='flex items-center gap-2 font-[600] text-blue-900 '><span
                        class='bg-gray-200 centred rounded-full text-purple-800 p-2'>
                        <AiOutlineUserSwitch className='text-3xl ' />
                    </span>Vérification générale périodique des appareils ou machine - Ministère de l'Emploi</li>
                <li class='flex items-center gap-2 font-[600] text-blue-900 '><span
                        class='bg-gray-200 centred rounded-full text-purple-800 p-2'>
                        <AiOutlineUserSwitch className='text-3xl ' />
                    </span>Vérification des appareil à pression - Ministère de l'energie et des mines</li>

            </ul>
        </div>

    </div>
</template>

<script>
export default {
    name: "AgrementsHome",
    data() {
        return {

        }
    }
}
</script>

<style scoped>
.NosBorder {
    padding-top: 6px;
    border-top: 4px solid #cf1f21;
}

.text {
    margin-top: 30px;
    font-family: Cairo, Verdana, Tahoma, Arial;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0em;
    color: #616161;
    text-align: justify;
}

.image-div:hover::before{
    content: "";
    position: absolute;
    top:0;
    bottom:0;
    left: 0;
    right: 0;
    background-color: rgb(0 0 0 / 80%);
    z-index: 1;
}
.image-div::before{
    content: "";
    position: absolute;
    top:0;
    transition: ease 0.3s;
    bottom:0;
    left: 0;
    right: 0;
    background-color: rgb(0 0 0 / 50%);
    z-index: 1;


}

.image-div:hover img{
    transform: scale(1.1);

}

.image-div{
    overflow: hidden;
}
.image-div svg{
    fill: blue;
    font-weight: 600;
}
.image-div:hover svg{
margin-left: 10px;
transition: ease 0.3s;
}

.image-div p{
    -webkit-text-stroke: 0.3px rgb(29, 29, 29);
}

@media screen and (max-width: 800px) { 
    p {
        font-size: 10px;
    }
}



</style>
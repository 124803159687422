<template>
  <div class="parent">
    <div class="imgg">
      <div class="text-overlay w-full text-center text-white text-3xl font-extrabold">
        <h2>Nos engagements</h2>
      </div>
    </div>  
  </div>
</template>

<script>
export default {
    name : "NewsReglementation",
    data() {
        return {
            
        }
    }
}
</script>

<style scoped>
*{
  font-family: Cairo,Verdana,Tahoma,Arial;
}
  .imgg {
    width: 100%;
    height: 300px;
    overflow:hidden;
    position: relative;
    background-image: url("https://gthpdf.fra1.digitaloceanspaces.com/mentions-legales-min.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .text-overlay {
      padding: 5%;
      margin: auto;
      position: absolute;
      z-index: 999;
      text-align: center;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      background-color: rgb(1, 1, 1, 0.6);
  }
  .text-overlay > h2 {
    color : #56be25;
    font-size: 40px;
  }

</style>
import axios from 'axios'
import config from "@/config"

// class Devis
class Formation {

    //--------------------------------------- create formation --------------------------------------------
    static create(formation) {
        const { titre, auteur } = formation;
        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/formations/create`,
            {
                titre : titre,
                auteur : auteur
            },
            {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
            .then(response => {
                    resolve(response);
            })
            .catch(error => {
                    reject(error);
            });
        })
    }

    static select(formationId) {
        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/formations/select`,
            {
                formationId : formationId,
            },
            {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
            .then(response => {
                    resolve(response);
            })
            .catch(error => {
                    reject(error);
            });
        })
    }

    static read() {
        return new Promise((resolve, reject) => {
            axios.get(`${config.launch}/formations/read`, {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    static update(formation , formationId) {
        const { titre, auteur } = formation;
        return new Promise((resolve, reject) => {
            axios.put(`${config.launch}/formations/update`,
            {
                formationId : formationId,
                titre : titre,
                auteur : auteur
            },
            {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
            .then(response => {
                    resolve(response);
            })
            .catch(error => {
                    reject(error);
            });
        })
    }


    static delete(formationId) {
        return new Promise((resolve, reject) => {
            axios.delete(`${config.launch}/formations/delete/${formationId}`,)
            .then(response => {
                    resolve(response);
            })
            .catch(error => {
                    reject(error);
            });
        })
    }


}


export default Formation;

<template>
  <div class="carrieres">
    <div class="imgg">
      <div class="text-overlay w-full text-center text-white text-3xl font-extrabold">
        <h2>Offre d'emploi de GTHCONSULT</h2>
      </div>
    </div>
    <div class="top">
        <titreCarrieres />
    </div>

    <div class="bottom">

      <div class="filter">
        <!-- <filterCarrieres /> -->
      </div>

      <div class="posts">
        <postsCarrieres :offres="offres" />
      </div>

      <div class="social-media">
        <socialMediaCarrieres />
      </div>
      
    </div>
  </div>
</template>

<script>
import titreCarrieres from "@/components/componentsCarrieres/titreCarrieres.vue";
// import filterCarrieres from "@/components/componentsCarrieres/filterCarrieres.vue";
import socialMediaCarrieres from "@/components/componentsCarrieres/socialMediaCarrieres.vue";
import postsCarrieres from "@/components/componentsCarrieres/postsCarrieres.vue";

export default {
  name : "OffreEmploiView",
  components : {
    titreCarrieres,
    // filterCarrieres,
    socialMediaCarrieres,
    postsCarrieres
  },
  data() {
    return {
    }
  },
  methods : {

  },
  mounted() {

  }


}
</script>

<style scoped>

*{
  font-family: Cairo,Verdana,Tahoma,Arial;
}
.carrieres {
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: auto;
 
}

.imgg {
  width: 100%;
  height: 500px;
  overflow:hidden;
  position: relative;
  background-image: url("https://gthpdf.fra1.digitaloceanspaces.com/offre-emploi.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.text-overlay {
    padding: 5%;
    margin: auto;
    position: absolute;
    z-index: 999;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: rgb(1, 1, 1, 0.6);
}
.text-overlay > h2 {
  color : #56be25;
  font-size: 40px;
}

.carrieres .top{
  width: 100%;
  height: 170px;
  background-color: #f1f5f9;
}
.carrieres .bottom {
  margin-top:50px;
  display: grid;
  grid-template-columns: 25% 55% 20%;
  width: 100%;
}
@media screen  and (max-width: 1380px){
  .carrieres .bottom {
    grid-template-columns: 25% 50% 25%;
  }
}
@media screen  and (max-width: 1350px){
  .carrieres .bottom {
    grid-template-columns: 27% 48% 25%;
  }
}
@media screen  and (max-width: 1230px){
  .carrieres .bottom {
    grid-template-columns:30% 45% 25%;
  }
}
@media screen  and (max-width: 1190px){
  .carrieres .bottom {
    grid-template-columns:33% 42% 20%;
  }
}
@media screen  and (max-width: 1090px){
  .carrieres .bottom {
    grid-template-columns:33% 40% 20%;
  }
}
@media screen  and (max-width: 1020px){
  .carrieres .bottom {
   display: grid;
   grid-template-columns: none;
   justify-content: center;
  }
}

</style>
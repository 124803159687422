<template>
    <div class="NouveauFormation">
        <div class="box">
            <h1>Modifier Formation</h1>
            <label for="Email ou Numéro de téléphone">Titre de Formation</label>
            <input type="text" maxlength="400" name="text" v-model="formation.titre" />
            <label for="Prénom">Auteur</label>
            <input type="text" maxlength="400" name="text" v-model="formation.auteur" />
            <button @click="update">Enregistrer</button>
        </div>
  
        <!--  Start Confirmation Model   -->
        <ErrorModel v-if="flagErrorModel == true" :text="text" :titre="titre" />
        <!--  End Confirmation Model   -->
  
        <!--  Start Info Delete Model   -->
        <LandingModel v-if="flagLandingModel == true" />
        <!--  End Info Delete Model   -->
  
    </div>
  </template>
    
  <script>
  
  import Formations from "@/connection/Formations";
  import ErrorModel from "@/components/models/ErrorModel.vue";
  import LandingModel from "@/components/models/LandingModel.vue";
  
  export default {
    name: "NouveauFormation",
    data() {
        return {
            formation: {
                titre: null,
                auteur: null
            },
            text : null,
            titre : null
        }
    },
    props : {
      formationId : String
    },
    components : {
        ErrorModel,
        LandingModel
    },
    methods: {
      update() {
            this.flagLandingModel = true;
            Formations.update(this.formation, this.formationId)
                .then((result) => {
                  if(result.data.success) {
                    const router = this.$router
                    router.go({path: '/elearning'});
                  }
                })
                .catch((error) => {
                    this.titre = "Error";
                    this.text = error.message
                    console.log(error);
                });
        }
    },
  
    created() {
        Formations.select(this.formationId)
        .then((result) => {
          this.formation.titre = result.data.formation.titre;
          this.formation.auteur = result.data.formation.auteur;
        })
        .catch((error) => {
            console.log(error.message);
        });
    }
  }
  </script>
    
  <style scoped>
  .NouveauFormation {
    display: flex;
    flex-direction: column;
  }
  
  .NouveauFormation .box {
    width: auto;
    margin: 20px;
    border-radius: 8px;
    border: 2px #ddd solid;
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .NouveauFormation .box h1 {
    margin-bottom: 10px !important;
    font-size: x-large;
    font-weight: bold;
    font-family: "Amazon Ember", Arial, sans-serif;
  }
  
  
  .NouveauFormation .box label {
    margin-bottom: 5px !important;
  }
  
  .NouveauFormation .box input[type=text],
  .NouveauFormation .box input[type=password] {
    width: 1000px;
    height: 40px;
    margin-bottom: 5px !important;
    background-color: #dddddd54;
    border-radius: 5px;
    padding: 5px;
    border: 1px solid;
    outline: 0px;
  }
  
  .NouveauFormation .box input[type=text]:hover,
  .NouveauFormation .box input[type=password]:hover {
    background-color: white;
    outline: 0px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    outline: 0px;
  }
  
  
  div.NouveauFormation>div>button {
    margin-bottom: 5px !important;
    margin-top: 20px !important;
    background-color: #0057e3;
    color: white;
    font-size: small;
    font-weight: bold;
    font-family: "Amazon Ember", Arial, sans-serif;
    cursor: pointer;
    width: 300px;
    height: 40px;
    border-radius: 5px;
  }
  </style>
import axios from 'axios'
import config from "@/config"

// class Messages
class Satisfaction {

    //--------------------------------------- envoyer statisfaction client --------------------------------------------
    static envoyer(id, raisonSocial, secteurActivite, nomEvaluateur, prenomEvaluateur, fonctionEvaluateur, telephoneEvaluateur, emailEvaluateur, lieuEnvoyer, dateEnvoyer, questions, commentaire, echelleSelect, source, prestations) {
        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/statisfactions/envoyer`,
            {
                id : id,
                raisonSocial : raisonSocial,
                secteurActivite : secteurActivite,
                nomEvaluateur : nomEvaluateur,
                prenomEvaluateur : prenomEvaluateur,
                fonctionEvaluateur : fonctionEvaluateur,
                telephoneEvaluateur : telephoneEvaluateur,
                emailEvaluateur : emailEvaluateur,
                lieuEnvoyer : lieuEnvoyer,
                dateEnvoyer : dateEnvoyer,
                questions : questions,
                commentaire : commentaire,
                echelleSelect : echelleSelect,
                source : source,
                prestations : prestations
            },
            {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }
}

export default Satisfaction;
<template>
    <transition name="modal-fade">
      <div class="modal-backdrop">
        <div class="modal">
  
          <header class="modal-header">
            Écrivez ci-dessous votre observation s'il vous plait
          </header>
  
            <section class="modal-body">
                <textarea v-model="message" id="" cols="40" rows="10"></textarea>
            </section>
    
            <footer class="modal-footer">
                <button @click="envoyer">Envoyer</button>
                <button @click="quitter">Quitter</button>
            </footer>
  
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    name: 'ConfirmationModel',
    data() {
        return {
            message : null
        }
    },
    props: {
      text : String
    },
  
    methods: {
  
        quitter() {
        this.$emit('quitter');
      },
  
      envoyer() {
        this.$emit('envoyer', this.message);
      },
  
    }
  };
  </script>
  
  <style scoped>
  
    .modal-backdrop {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
  
    }
  
    .modal {
      background: #FFFFFF;
      min-width: 500px;
      max-width: 600px;
      max-height: 400px;
      overflow: auto;
    }
  
    .modal-header,
    .modal-footer {
      padding: 15px;
      display: flex;
    }
  
    .modal-header {
      position: relative;
      border-bottom: 1px solid #eeeeee;
      color: black;
      text-align: center;
      font-size: 14px;
      justify-content: center;
      text-transform: capitalize;
    }
  

  
    .modal-body {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 5px;
      font-size: 18px;
  
    }
  
    .modal-footer {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .modal-footer > button:nth-child(1) {
      border: 0px;
      font-size: large;
      width: 100px;
      background-color: green;
      color: white;
      padding: 10px;
      cursor: pointer;
      margin-right: 5px;
      border-radius: 5px

    }

    .modal-footer > button:nth-child(2) {
      border: 0px;
      font-size: large;
      width: 100px;
      background-color: red;
      color: white;
      padding: 10px;
      cursor: pointer;
      margin-right: 5px;
      border-radius: 5px
    }

  
  </style>
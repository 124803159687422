<template>
 
  <div class="parent">
    <!-- <div class="imgg">

      <div class="text-overlay w-full text-center text-white text-3xl font-extrabold">
        <h2>GTHCONSULT Actualités</h2>
      </div>
    </div>

    <div class="child_1" :style="{ display: 1 === activeIndex ? 'block' : 'none' }">
      <div class="child">
        <img src="" class="image">
        <div class="opacity"></div>
        <h1 class="title_1">Espagne : caractérisation des déchets pour un meilleur recyclage</h1>
    </div>
  
    <div class="article">
      <p class="date_1">25/10/2023</p>
      <p class="post"> Certification et le CCIC, l’instance de certification et d’inspection chinoise, ont signé un accord de coopération pour la certification nationale “le CCC”, Compulsory Product Certification.

         est en mesure d’aider les fabricants et importateurs à accéder au marché Chinois et lancer efficacement les produits dans ce marché dynamique. 
        Le CCIC pourra aussi compter sur l’expertise d’ pour répondre aux besoins de certification de leurs produits destinés au marché européen. 
        
         Certification renforce sa présence à l’international grâce à ce nouveau partenariat de la certification de produits destinés à la Chine. 
        Signé fin 2022, il permet au groupe  d’accompagner les clients de bout en bout dans la certification des produits électriques et électroniques destinés à l’exportation vers le marché chinois.</p>
    </div>
     <div class="article_1">
      <div class="line"></div>
      <h1 class="title_article">Favoriser les transitions</h1>
      <p>Face aux dérèglements écologiques et énergétiques et aux mutations sociales et sociétales grandissantes auxquels les entreprises, collectivités et citoyens sont confrontés, il est nécessaire de s’adapter pour maîtriser les nouveaux risques qui en découlent en favorisant notamment,
         à tous les niveaux, le développement d’une culture prévention au sein des organisations.
          Qualité de l’air intérieur, exposition aux risques chimiques, exposition aux champs électromagnétiques, 
          bruits et vibrations en ambiance de travail, risque légionelles, radioprotection, etc. : sous l’impulsion de la prise de conscience collective, d’une politique européenne plus volontariste et de l’évolution des valeurs sociétales,
          les entreprises doivent désormais mener leur propre révolution pour dessiner ou inventer des environnements de travail sûrs et sains pour leur collaborateur. Il appartient aujourd’hui, à chaque acteur économique, de faire sa part pour construire un monde plus durable.
          <br><br>
          Depuis plus de 150 ans, 
           accompagne les grandes mutations et révolutions technologiques en développant des expertises pour garantir la sécurité des hommes, 
          des biens, de l’environnement et du numérique.
          <br><br>
          Convaincus que l’amélioration de la sécurité et de la santé au travail est le socle de tout progrès tant sur les dimensions sociales,
          économiques, environnementales et sociétales de nos activités, nous nous engageons à prévenir, 
          contrôler et maîtriser les risques quels qu’en soit leur nature.
          <br><br>
          C’est l’essence de notre action, de nos choix stratégiques et de notre développement.
          C’est le sens des missions délivrées par les 13000 collaborateurs d’ à nos 500 000 clients.</p>
    </div>
  </div>
 
  <div class="child_2" :style="{ display: 2 === activeIndex ? 'block' : 'none' }">
    <div class="child">
    <img src="" class="image">
    <div class="opacity"></div>
    <h1 class="title_1">France : caractérisation des déchets pour un meilleur recyclage</h1>
  </div>
  
    <div class="article">
      <p class="date_1">25/10/2023</p>
      <p class="post"> Certification et le CCIC, l’instance de certification et d’inspection chinoise, ont signé un accord de coopération pour la certification nationale “le CCC”, Compulsory Product Certification.

         est en mesure d’aider les fabricants et importateurs à accéder au marché Chinois et lancer efficacement les produits dans ce marché dynamique. 
        Le CCIC pourra aussi compter sur l’expertise d’ pour répondre aux besoins de certification de leurs produits destinés au marché européen. 
        
         Certification renforce sa présence à l’international grâce à ce nouveau partenariat de la certification de produits destinés à la Chine. 
        Signé fin 2022, il permet au groupe  d’accompagner les clients de bout en bout dans la certification des produits électriques et électroniques destinés à l’exportation vers le marché chinois.</p>
    </div>
    <div class="article_1">
      <div class="line"></div>
      <h1 class="title_article">Favoriser les transitions</h1>
      <p>Face aux dérèglements écologiques et énergétiques et aux mutations sociales et sociétales grandissantes auxquels les entreprises, collectivités et citoyens sont confrontés, il est nécessaire de s’adapter pour maîtriser les nouveaux risques qui en découlent en favorisant notamment,
         à tous les niveaux, le développement d’une culture prévention au sein des organisations.
          Qualité de l’air intérieur, exposition aux risques chimiques, exposition aux champs électromagnétiques, 
          bruits et vibrations en ambiance de travail, risque légionelles, radioprotection, etc. : sous l’impulsion de la prise de conscience collective, d’une politique européenne plus volontariste et de l’évolution des valeurs sociétales,
          les entreprises doivent désormais mener leur propre révolution pour dessiner ou inventer des environnements de travail sûrs et sains pour leur collaborateur. Il appartient aujourd’hui, à chaque acteur économique, de faire sa part pour construire un monde plus durable.
          <br><br>
          Depuis plus de 150 ans, 
           accompagne les grandes mutations et révolutions technologiques en développant des expertises pour garantir la sécurité des hommes, 
          des biens, de l’environnement et du numérique.
          <br><br>
          Convaincus que l’amélioration de la sécurité et de la santé au travail est le socle de tout progrès tant sur les dimensions sociales,
          économiques, environnementales et sociétales de nos activités, nous nous engageons à prévenir, 
          contrôler et maîtriser les risques quels qu’en soit leur nature.
          <br><br>
          C’est l’essence de notre action, de nos choix stratégiques et de notre développement.
          C’est le sens des missions délivrées par les 13000 collaborateurs d’ à nos 500 000 clients.</p>
    </div>
  </div>    
   
  <div class="child_3" :style="{ display: 3 === activeIndex ? 'block' : 'none' }">
    <div class="child">
    <img src="" class="image">
    <div class="opacity"></div>
    <h1 class="title_1">Allmagne : caractérisation des déchets pour un meilleur recyclage</h1>
  </div>
  
    <div class="article">
      <p class="date_1">25/10/2023</p>
      <p class="post"> Certification et le CCIC, l’instance de certification et d’inspection chinoise, ont signé un accord de coopération pour la certification nationale “le CCC”, Compulsory Product Certification.

         est en mesure d’aider les fabricants et importateurs à accéder au marché Chinois et lancer efficacement les produits dans ce marché dynamique. 
        Le CCIC pourra aussi compter sur l’expertise d’ pour répondre aux besoins de certification de leurs produits destinés au marché européen. 
        
         Certification renforce sa présence à l’international grâce à ce nouveau partenariat de la certification de produits destinés à la Chine. 
        Signé fin 2022, il permet au groupe  d’accompagner les clients de bout en bout dans la certification des produits électriques et électroniques destinés à l’exportation vers le marché chinois.</p>
    </div>
    <div class="article_1">
      <div class="line"></div>
      <h1 class="title_article">Favoriser les transitions</h1>
      <p>Face aux dérèglements écologiques et énergétiques et aux mutations sociales et sociétales grandissantes auxquels les entreprises, collectivités et citoyens sont confrontés, il est nécessaire de s’adapter pour maîtriser les nouveaux risques qui en découlent en favorisant notamment,
         à tous les niveaux, le développement d’une culture prévention au sein des organisations.
          Qualité de l’air intérieur, exposition aux risques chimiques, exposition aux champs électromagnétiques, 
          bruits et vibrations en ambiance de travail, risque légionelles, radioprotection, etc. : sous l’impulsion de la prise de conscience collective, d’une politique européenne plus volontariste et de l’évolution des valeurs sociétales,
          les entreprises doivent désormais mener leur propre révolution pour dessiner ou inventer des environnements de travail sûrs et sains pour leur collaborateur. Il appartient aujourd’hui, à chaque acteur économique, de faire sa part pour construire un monde plus durable.
          <br><br>
          Depuis plus de 150 ans, 
           accompagne les grandes mutations et révolutions technologiques en développant des expertises pour garantir la sécurité des hommes, 
          des biens, de l’environnement et du numérique.
          <br><br>
          Convaincus que l’amélioration de la sécurité et de la santé au travail est le socle de tout progrès tant sur les dimensions sociales,
          économiques, environnementales et sociétales de nos activités, nous nous engageons à prévenir, 
          contrôler et maîtriser les risques quels qu’en soit leur nature.
          <br><br>
          C’est l’essence de notre action, de nos choix stratégiques et de notre développement.
          C’est le sens des missions délivrées par les 13000 collaborateurs d’ à nos 500 000 clients.</p>
    </div>
  </div> 
  
          
       
       
  
        <div class="actualites">
        <div class="title_actual">
            <div class="line"></div>
            <h1 class="title_article">Actualités</h1>
          </div>
            <div class="actual">
              <div class="actual_1">
                 
                 
                  <div ><a href="#" @click="toggleDiv(1)"><img src="pic5.jpg" class="img"/></a></div>
                  <div class="date">15 JUL ,2023</div>
                  <div class="description"><a href="" >The for fully had she there leave merit enjoy forth</a></div>
                  <div class="comment"><p>BY JOHN BAUS</p></div>
                
              </div>
              <div class="actual_1" id="division">
                  <div class="date">15 JUL ,2023</div>
                  <div class="description_1"><a href="#" @click="toggleDiv(2)">The for fully had she there leave merit enjoy forth</a></div>
                  <p class="text">Coming merits and was talent enough far. Sir joy northward sportsmen education. Discovery incommode earnestly no he commanded if. Put still any about manor. Estimable me disposing of be moonlight cordially curiosity.</p>
                  <div class="comment_1"><p>BY JOHN BAUS</p></div>
              </div>
              <div class="actual_1">
                <a href="">
                  <div ><a href="#" @click="toggleDiv(3)"><img src="pic5.jpg" class="img"/></a></div>
                  <div class="date">15 JUL ,2023</div>
                  <div class="description"><a href="" >The for fully had she there leave merit enjoy forth</a></div></a>
                  <div class="comment"><p>BY JOHN BAUS</p></div>
              </div>
          </div>
          </div> -->
   
  </div>
</template>

<script>
export default {
  name : "ActualitesView",
  data() {
    return {
     
      activeIndex: 1 
    };
  },
  methods: {
    toggleDiv(index) {
      if (this.activeIndex === index) {
        this.activeIndex = null; 
      } else {
        this.activeIndex = index; 
      }
    }
  }
};
</script>

<style scoped>


*{
 margin: 0;
 padding: 0%;
 box-sizing: border-box;
 transition: all .2s linear;
 font-family: Cairo,Verdana,Tahoma,Arial;
 text-decoration: none;
}

.imgg {
  width: 100%;
  height: 500px;
  overflow:hidden;
  position: relative;
  background-image: url("https://gthpdf.fra1.digitaloceanspaces.com/news-min.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.text-overlay {
    padding: 5%;
    margin: auto;
    position: absolute;
    z-index: 999;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: rgb(1, 1, 1, 0.6);
}
.text-overlay > h2 {
  color : #56be25;
  font-size: 40px;
}


.parent{
 width: 100%;
 height: max-content;
}
.image{
  height: 300px;
  width: 100%;
}
.child{
position: relative;
}
.opacity{
position: absolute;
top: 0px;
background-color: rgba(36, 48, 100, 0.3);
height: 300px;
width:100%;
}
.title_1{
position: absolute;
top: 25%;
left:10% ;
color: #fff;
font-size: 50px;
text-align: left;
}
.article{
width: 60%;
height: max-content;
margin-left: 20%;
box-shadow: 5px 3px 5px 3px rgba(180, 176, 177, 0.3);
}
.date_1{
  margin-top: 10%;
}
.article p{
font-size: 18px;
padding:3% ;
text-align: left;
}
.article_1{
width: 80%;
margin-top: 70px;
margin-left: 10%;
margin-bottom: 50px;
text-align: left;
}
.article_1 p{
line-height : 2;
margin-left: 50px;
}
.title_article{
font-size: 30px;
margin-bottom: 70px;
text-align: left;

}
.line{
width: 100px;
height: 5px;
border-bottom: 2px solid rgb(207, 31, 33) ;

}
.title_actual{
  margin-left: 10%;
}
.news{
 text-align: center;
 color:rgb(36, 48, 100) ;
}


.actual{
display: flex;
flex-direction:row;
width: 90%;
margin: auto;
margin-top: 50px;  
position: relative;

} 
.actual_1{
width: 30%;
text-align: left;
height:405px;
border: 2px solid rgba(207, 31, 33, 0.3);
margin-left: 30px;
background-color: rgb(190, 240, 223);
overflow: hidden;
}
.img{
height:400px;
width: 100%;
transform-origin: center center;
object-fit: cover;
transition: transform 0.5s;
transition-property: all;
}
#division{
height:405px
}
.date{
font-size: 12px;
font-weight: bold; 
position: absolute;
top: 30px;
margin-left: 30px;
color: #fff;
background-color:rgb(207, 31, 33) ;
width: 90px;
height: 20px;
border-radius: 5px;
text-align: center;
padding-top:3px;
}
.description{
position: absolute;
top: 280px;
width:25%;
margin-left: 20px;
}
.description a{
color: #fff;
font-size: 20px;
font-weight: bold;
}
.comment{
position: absolute;
top: 350px;
margin-left: 20px;
}
.comment p{
color: #fff;
font-size: 14px;
}
.comment_1{
color: black;
font-size: 14px;
margin-top: 10px;
margin-left: 20px;
padding-bottom: 20px;

}
.description_1{
margin-top: 100px;
width: 90%;
margin-left: 20px;
}
.description_1 a{
color: black;
font-size: 20px;
font-weight: bold;
}
.text{
margin-top: 40px;
width:90%;
margin-left: 20px;
}
.img:hover{
transform:rotate(5deg) scale(1.2);
}
.description_1 a:hover{
color:rgba(207, 31, 33, 0.8);

}





@media screen and (min-width:0px) and (max-width:600px){
.title_1{
position: absolute;
top: 10%;
left:10% ;
color: #fff;
font-size: 40px;
}
.actual{
display: flex;
flex-direction:column;
width: 90%;
margin: auto;
margin-top: 50px;  

} 
.actual_1{
width: 90%;
height: fit-content;
border: 2px solid rgba(207, 31, 33, 0.3);
margin-left: 20px;
margin-top: 20px;
overflow: hidden;
position: relative;
}
.comment_1{

margin-top: 20px;
}
.description{
width:90%;
}
}

@media  (max-width:800px) and (min-width:600px){
.title_1{
position: absolute;
top:15%;
left:10% ;
color: #fff;
font-size: 50px;
} 
.parent{
 position: relative;
}
.actual{
display: flex;
flex-direction:column;
width: 90%;
margin: auto;
margin-top: 50px;  
}
#division{
 position: absolute;
 top: 25%;
 left: 55%;
}
.actual_1{
width: 45%;
height: fit-content;
border: 2px solid rgba(207, 31, 33, 0.3);
margin-left: 10px;
margin-top: 50px;
overflow: hidden;
position: relative;
}
.description{
width:70%;
position: absolute;
top: 250px;
}

}
@media(max-width:1100px) and (min-width:800px) {
 .title{
 width: 50%;
 margin: auto;
 height: fit-content;
}
.parent{
 position: relative;
}
.actual{
display: flex;
flex-direction:column;
width: 90%;
margin: auto;
margin-top: 50px;  
}
#division{
 position: absolute;
 top: 15%;
 left: 55%;
}
.actual_1{
width: 45%;
height: fit-content;
border: 2px solid rgba(207, 31, 33, 0.3);
margin-left: 10px;
margin-top: 50px;
overflow: hidden;
position: relative;
}
.description{
width:70%;
position: absolute;
top: 250px;
}
}

</style>

<template>
    <div class="parent" v-if="falgCookies">
            <div class="logocookies">
                <img src="https://gthpdf.fra1.digitaloceanspaces.com/logo-footer.e772d696.png" title='logo footer gthconsult' alt="logo" />
            </div>
            <div class="letter">
                utilise des cookies pour fournir les fonctionnalités nécessaires du site Web, améliorer votre expérience et analyser notre trafic. En utilisant notre site Web, vous acceptez notre politique de confidentialité et notre politique en matière de cookies - <a href="politique-de-confidentialite">Politique de cookies</a>.
            </div>
            <div class="condition">
                <input type="button" value="Les accepter tous" @click="accepterCookies()"/>
                <input type="button" value="Fermer" @click="notAccepterCookies()"/>
            </div>
        </div>
</template>

<script>
import { useCookies } from "vue3-cookies";

export default {
    name : "CookiesHome",
    data() {
        return {
            falgCookies : true,
        }
    },

    setup() {
        const { cookies } = useCookies();
        return { cookies };
    },

    methods : {

        accepterCookies(){
            this.cookies.set("accepteCookies", true, '90d');
            this.falgCookies = false;
        },

        notAccepterCookies(){
            this.cookies.set("accepteCookies", false, '90d');
            this.falgCookies = false;

        }
    }
}
</script>

<style scoped>

.parent {
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #329604da;;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100px;
    width: 100%;
    z-index: 1000;
    color: white;
}
.parent > .logocookies{
    height: 100px;
    padding: 10px;
    width: 15%;
}
.parent > .lettre{
    color: #ddd;
    font-size: 16px;
    height: 100px;
    padding: 25px;
    text-align: justify;
    width: 70%;
}
.parent > .lettre > a {
    color: white;
    font-weight: bolder;
    font-size: 20;
}

.parent > .condition{
    padding: 5px;
    width: 15%;
}
.parent > .condition input:nth-child(1){
    background-color: #1a73e8;
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 3px solid white;
}

.parent > .condition input:nth-child(1):hover{
    background-color: rgba(0, 0, 124, 0.973);

}
.parent > .condition input:nth-child(2){
    background-color: rgba(224, 64, 64, 0.678);
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 3px solid white;
}
.parent > .condition input:nth-child(2):hover{
    background-color: red;
}

</style>
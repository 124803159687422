<template>
  <div class="parent">
        <div class="screen">
            <img src="https://gthpdf.fra1.digitaloceanspaces.com/plan-min%20(1).jpg">
            <div class="opacity"></div>
            <a href="">ACCUEIL</a>
            <h1>MENTION LEGALE</h1>
        </div>
        <div class="containt">
             <img src="../assets/logogth.png">
            <div class="site">
                <h3>Condition d'utilisation</h3>
                   <p>
                    Les informations contenues sur le site www.gthconsult.com (le « Site ») sont à usage général et n'ont aucune valeur contractuelle. L'accès et l'utilisation de ce Site sont soumis aux « conditions d'utilisation » détaillées ci-dessous, ainsi qu'aux lois et/ou réglementations applicables. En vous connectant à ce Site, vous acceptez inconditionnellement et sans réserve les présentes conditions d'utilisation dans leur intégralité. 
                    GTHCONSULT se réserve le droit de corriger, à tout moment et sans préavis, les informations diffusées sur ce Site.
                   </p>
                   <br>
                   <p>
                    The information contained on the site www.gthconsult.com (the “Site”) is for general purposes only and do not have any contractual value. Access to and use of this Site is subject to the detailed “terms of use“ set out below, and to applicable laws and/or regulations. By connecting to this Site you unconditionally and unreservedly accept these terms of use in full.
                     GTHCONSULT reserves the right to correct at anytime and without notice the information displayed on this Site.
                   </p>
                   <br>
                   <p>
                    لمعلومات الواردة على الموقع ("الموقع") هي للأغراض العامة فقط وليس لها أي قيمة تعاقدية. يخضع الوصول إلى هذا الموقع واستخدامه "لشروط الاستخدام" المفصلة أدناه ، والقوانين و / أو اللوائح المعمول بها. من خلال الاتصال بهذا الموقع ، فإنك تقبل دون قيد أو شرط ودون تحفظ شروط الاستخدام هذه بالكامل. تحتفظ بالحق في تصحيح المعلومات المعروضة على هذا الموقع في أي وقت ودون سابق إنذار.


                   </p>
                   <br><br>
                <h3>L’OBJET DU SITE</h3>
                <p>Le site Internet de GTHCONSULT constitue une plate-forme de ressources,
                     traduisant sa principale mission de vérifications des installations techniques
                      et des risques professionnels .
                       Ce site est un des vecteurs de la diffusion d’informations et offrants des 
                       fonctionnalités aux client de GTHCONSULT.</p> 
                       <br>
                <h3>PUBLICATION</h3>
                <p>
                Le Site www.gthconsult.com est édité par :<br>
                GTHCONSUT (IT department)<br>
                GTHCONSULT BOULEVARD MOHAMMED VI, LOT AL MASSIRA, LOT N°187, APPT 5-6 3EME ETAGE, Mohammédia - (Maroc)
                </p><br>
                <h3>CREDITS</h3>
                <p>
                        Design and production<br>
                        Staff GTHCONSULT (IT department)<br>

                        Photo/Video Credits<br>
                        Staff GTHCONSULT (IT department)<br>

                        Video Rebrand<br>
                        Staff GTHCONSULT (IT department)<br>

                        Photo<br>
                        Fotolia, iStockphotos
                </p><br>
                <h3>HOSTING</h3>
                <p>
                    DigitalOcean<br>
                    Created: June 24, 2011

                    Number of employees: 1204 (2022)<br>

                    Revenue: 576 million USD (2022)<br>

                    CEO: Yancey L. Spruill (Jul 30, 2019–)<br>

                    Founders: Moisey Uretsky, Alec Hartman, Mitch Wainer, Ben Uretsky, Jeff Carr<br>

                    Brand: DigitalOcean<br>

                    Headquarters: New York, U.S
                </p><br>
                <h3> LIMITATION DE RESPONSABILITÉ</h3>
                <p>GTHCONSULT ne peut être tenu responsable, en aucun cas, de tout dommage direct ou indirect ou de toute perte résultant
                     de l'utilisation des pages web disponibles sur son site. 
                    En particulier, GTHCONSULT ne saurait être responsable des détériorations ou virus qui pourraient
                     affecter votre équipement informatique ou tout autre équipement suite à la consultation du site. 
                     Les utilisateurs du site s'engagent à respecter les présentes conditions d'utilisation 
                     et utiliseront le site sous leur propre responsabilité. L'utilisateur est présumé avoir accepté les présentes 
                     conditions d'utilisation du simple fait de la consultation du site. La consultation du site implique automatiquement 
                     l'acceptation de la dernière version des présentes conditions d'utilisation.

                </p><br>
                <h3> PROPRIÉTÉ INTELLECTUELLE</h3>
                <p>La présentation et le contenu de ce Site sont protégés par les lois applicables sur la propriété intellectuelle, 
                    dont GTHCONSULT est soumis. Le nom, le logo et les devises de GTHCONSULT sont tous, sauf indication contraire, 
                    des marques déposées de GTHCONSULT. Les dessins, photographies, images, textes, séquences animées sonores ou non 
                    et tous autres éléments présentés sur ce Site sont protégés par des droits de propriété intellectuelle 
                    et/ou industrielle de GTHCONSULT ou de tout tiers autorisant GTHCONSULT à utiliser les leurs. Toute reproduction, 
                    représentation, utilisation y compris l'exploitation commerciale et/ou modification par tous procédés et
                    sur tous supports, de tout ou partie du Site, tels que textes, images ou sons, est strictement interdite
                    sauf autorisation expresse préalable et écrite de GTH CONSULT. GTHCONSULT se réserve le droit d'engager des
                    poursuites contre toute personne qui porterait atteinte à ses droits de propriété intellectuelle,
                    y compris des poursuites pénales.
                </p><br>
                <h3> DONNÉES PERSONNELLES</h3>
                <p>Vous pouvez consulter ce Site sans vous identifier, c'est-à-dire sans donner votre nom ou toute autre 
                    information vous concernant. Néanmoins, vous pouvez être invité à nous communiquer des données
                     personnelles telles que votre nom, prénom, nom de votre société, adresse postale,
                      e-mail ou numéro de téléphone afin d’utiliser des fonctionnalités du site, d'obtenir des 
                      informations de la part de GTHCONSULT, de postuler à une offre d'emploi ou d'envoyer une 
                      candidature spontanée. Vous reconnaissez alors que vous fournissez volontairement à GTHCONSULT
                       des données personnelles identifiables vous concernant ou concernant votre société pour le cas 
                       des clients de GTHCONSULT. Les données collectées font l'objet d'un traitement informatique. 
                       Toutes les informations collectées résultant des demandes ci-dessus sont traitées de manière 
                       strictement confidentielle par le GTHCONSULT conformément aux lois marocaines relatives à la 
                       protection des données, vous disposez d'un droit d'accès, de suppression et de rectification 
                       de vos données personnelles. Pour cela, il vous suffit d'en faire la demande en ligne ou par 
                       courrier en indiquant vos nom, prénom et adresse à l'adresse suivante : service.supports@gthconsult.ma
                </p><br>
                <h3>RESPONSABILITÉS</h3>
                <p>GTHCONSULT ne pourra pas être tenu responsable des dommages résultant de l’usage de ce site, 
                    de son contenu ou de ses fonctionnalités ainsi que d’autres sites qui lui sont liés, notamment 
                    et sans limitation, tout préjudice financier ou commercial, perte de programme ou de données du système 
                    d’information de l’utilisateur. La responsabilité de GTHCONSULT ne peut pas être engagée, 
                    directement ou indirectement, du fait d’inexactitudes, d’omissions ou d’erreurs ou tous faits équivalents
                     relatifs aux informations fournies sur le site. La diffusion d’une information sur
                      le site ne comporte aucune obligation d’actualisation notamment au vu d’évolutions postérieures 
                      à sa date d’établissement.

                    Il relève de la responsabilité de l’utilisateur de contrôler, par tous les moyens,
                     l’adéquation des informations à ses besoins et de s’assurer qu’il ne causera pas de
                      dommages aux personnes et aux biens.
                    
                    Les utilisateurs sont seuls responsables de l’interprétation et de l’utilisation qu’ils font 
                    des informations. Ils veilleront ainsi notamment à la conformité aux réglementations et normes en vigueur.
                     Chaque utilisateur s’engage à ce que son utilisation du site : Ne contrevienne en aucune façon aux lois et
                      réglementations en vigueur au Maroc, dans son pays de résidence ou dans le pays où ces données sont destinées à être reçues. Ne contienne ou génère de manière intentionnelle ni virus, ni programmes susceptibles de causer des dommages aux personnes ou à leurs biens.
                    
                    Il est interdit à l’utilisateur de transmettre sur ce site, toute information illégale ou contraire à l’ordre public. La transmission d’informations par un utilisateur sur le site est assimilée à un transfert au domaine public, sauf mention contraire. Toutes informations transmises par l’utilisateur sur ce site Internet est librement utilisable par GTHCONSULT, sauf contraire faite par l’utilisateur.
                    
                    
                </p><br>
                <h3> HYPERLINKS</h3>
                <p>Les liens hypertextes du site GTHCONSULT vers d'autres sites sont indépendants. En aucun cas, ces liens hypertextes ne constituent une approbation ou un partenariat entre GTHCONSULT et ces sites. Dès lors, GTHCONSULT ne saurait être responsable de leurs contenus, produits, publicités ou tous autres éléments ou services présentés sur ces sites. Toute création de lien hypertexte avec le site de GTHCONSULT est soumise à l'autorisation expresse préalable et écrite de GTHCONSULT.
                </p><br>
                <h3> USE OF COOKIES (UTILISATION DES COOKIES)</h3>
                <p>See Cookie Policy_ _ <b><a href="">En savoir plus</a></b></p><br>
                <h3>DROIT APPLICABLE – JURIDICTION</h3>
                <p>Le Site et les présentes conditions d'utilisation sont régis par le droit marocain, à l'exclusion des règles relatives aux conflits de lois. Tout litige survenant à l'occasion de l'interprétation ou de l'exécution des présentes conditions d'utilisation sera de la compétence exclusive des tribunaux de Casablanca, sous réserve toutefois de toutes dispositions impératives contraires.</p><br>
            </div>
       
        </div>
  </div>
</template>

<script>
export default {
    name : "MentionsLegalesView",
    data() {
        return {
            
        }
    }

}
</script>

<style  scoped>
   *{
        margin:0; 
      padding: 0;
      box-sizing: border-box;
        font-family: Cairo,Verdana,Tahoma,Arial;
      text-decoration: none;
      list-style: none;
    }
    .parent{
        height: max-content;
    }
    .screen{
        height: 80vh;
        position: relative;
    }
    .screen img{
        width: 100%;
        height:100%;
    }
    .screen .opacity{
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(36, 48, 100,0.4);
    }
    .screen h1{
        color: #fff;
        position: absolute;
        top: 50%;
        left:35%;
        font-size: 30px;
    }
    .screen a{
        color:#fff;
        position:absolute;
        top: 45%;
        left: 35%;
        text-transform: uppercase;
    }
    .screen a:hover{
        border-bottom: 1px solid #fff;   
    }
    .containt{
        height:max-content ;
        position: relative;
       width: 90%;
    }
    .containt img{
        width: 100%;
        position: absolute;
        opacity: 0.1;
        top:200px;
        height: 900px;
    }
    .site{
        margin:20px 0px 0px 80px;
        /* position: absolute; */
    }
    .site h3 {
        color:rgb(207,31,33);
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
    }
    
    .site p{
        line-height: 20px;
        font-size: 16px;
        color: #616161;
        
    }
    
</style>
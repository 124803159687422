<template>
  <div>
     <div class="parent text-left mt-14 grid  ml-6">
      
     <h1 class="text-3xl text-zinc-600 font-bold">
      Trouver les meilleurs opportunité GTHCONSULT
     </h1>
     <p class="text-zinc-400">
        Parcourez notre liste d'emplois GTHCONSULT, trouvez votre profil idéal et postulez. Utilisez les filtres de la barre latérale pour des résultats plus précis</p>
    </div>
  </div>
   
</template>
  
<script>
export default {
  name: "titreCarrieres",
};
</script>
  
<style scoped>
@media screen  and (max-width:750px){
 
  .parent h1{
   font-size: 18px;

  }
  .parent p{
   font-size: 16px;
  }
 }
@media screen  and (max-width:650px){
 
   .parent h1{
    font-size: 16px;

   }
   .parent p{
    font-size: 12px;
   }
  }
</style>
import axios from 'axios'
import config from "@/config"

// class Messages
class Prestations {

    //--------------------------------------- read Prestations --------------------------------------------
    static getPrestationByTitre(titre) {

        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/prestations/getPrestationByTitre`,
            {
                titre : titre
            },
            {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    static read() {

        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/prestations/read`,
            {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    static insert(missions) {


        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/prestations/insert`,
            {
                missions : missions
            },
            {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }
}

export default Prestations;
<template>
  <div class="parent">

    <div class="imgg">
      <div class="text-overlay w-full text-center text-white text-3xl font-extrabold">
        <h2>Nos Moyens</h2>
      </div>
    </div>
    
        <div class="containt_3">
            <img src="../assets/logogth.png" class="logo">
            <div class="engagements">
                  <h1>Découvrez nos moyens</h1> 
                  <div class="engagement">
                    <h2>NOS MOYENS – MOYENS HUMAINS</h2>
                    <ul class="rows">
                      <li class="columns">
                        <img src="https://gthpdf.fra1.digitaloceanspaces.com/catalogue-min%20(1).jpg">
                      </li>
                      <li class="columns">
                          <p>En plus de son réseau de compétence au Maroc et à l’étranger. GTHCONSULT met à votre disposition une équipe de spécialistes compétents dans les domaines de vérifications réglementaires , d’inspections techniques, de formations, et d’accompagnement.
                            <b>GTHCONSULT</b> dispose d’une équipe pluridisciplinaire, composée des techniciens et ingénieurs, de techniciens spécialisés et d’assistants (aides techniciens et administratifs).
                              La diversité de nos domaines d’intervention nous interpelle à assurer des sessions de formation dans tous les domaines en lien avec nos activités (Contrôles réglementaires des installations techniques, risques au travail, formation techniques…), nous permettant de répondre aux exigences de nos clients et de leur offrir le meilleur service possible en termes d’inspections et une qualité de prestations irréprochable.

                          </p>
                      </li> 
                    </ul>
                  </div>
                  <div class="security">
                      <h2>NOS MOYENS – MOYENS MATERIELS</h2>
                      <ul class="rows">
                        <li class="columns">
                          <p><b>GTHCONSULT</b> dispose des équipements et des moyens d’inspections et de vérification pour assurer ses missions : contrôles dimensionnelle, mesures des grandeurs physiques, contrôle non destructif ( ressuage, magnétoscopie, endoscopie).</p>
                        </li>
                        <li class="columns">
                          <img src="https://gthpdf.fra1.digitaloceanspaces.com/moyens-materiels-min.jpg">
                        </li>
                      </ul>
                  </div>
                  <div class="environnement">
                      <h2>NOS MOYENS – OUTILS DE TRAVAIL – TABLETTE INSPECTION  </h2>
                      <h3>DE GTHCONSULT</h3>
                      <p><b>GTHCONSULT</b> investit dans le développement des outils numériques et informatiques pour une meilleure qualité de ses prestations : des logiciels d'inspections, des espaces clients, automatisation des rapports, etc.</p><br>
                      <p>NOUVEAU DE <b>GTHCONSULT</b> ‘’E-GTH CLIENT’’
                      1ÉR BUREAU DE CONTRÔLE AU MAROC, QUI DONNE UN ACCÈS AU CLIENT SUR UNE PLATE-FORME, POUR LE SUIVI, L’ARCHIVAGE DES RAPPORTS D’INSPECTION ET TRAITEMENT DES OBSERVATIONS.
                      </p><br>
                      <p>Pour la planification, le suivi et l’amélioration continue de la qualité de nos prestations d’inspection,<b>GTHCONSULT</b> a mis en place une plate-forme informatique ‘’E-GTH Client’’ avec un accès aux interlocuteurs de nos clients pour l’archivage et le suivi des livrables. </p>
                      <ul class="rows">
                        <li class="columns">
                          <img src="https://gthpdf.fra1.digitaloceanspaces.com/Pc.png">
                        </li>
                        <li class="columns">
                          <ul class="number">
                            <h4>Un accès E-GTH Client</h4>
                            <li>N 01- Un accès sur le site web de GTHCONSULT <a href="">www.gthconsult.com</a> / <a href="">www.gthconsult.ma</a> </li>
                            <h4>Une diffusion et suivi des rapports en ligne par courriel Email. </h4>
                            <li>N 02- Un espace ‘’ E-GTH Client’’ dédié à chaque client sur notre plate-forme web pour le stockage des rapports et des documents et lui permettre de consulter ses rapports en ligne et être alerté par courrier e-mail en cas d’envois de nouveaux rapports. </li>
                            <h4>Des éléments toujours à jour </h4>
                            <li>N 03- Un rappel des dates des prochains contrôles pour le suivi des plannings et le respect des périodicité réglementaires.</li>
                            <li>N 04- A chaque nouvelle édition, vous recevez instantanément vos rapports modifiés par courriel émail. Ces éléments sont numérotés et incrémentés spécifiquement pour votre opération. </li>
                          </ul>
                        </li>
                      </ul>
                  </div>
            </div>
      
       </div>

  </div>
</template>

<script>
export default {
    name :"NosValeurs" ,
    data() {
        return {

        }
    }
}
</script>

<style scoped>
*{
  font-family: Cairo,Verdana,Tahoma,Arial;
}
  .imgg {
    width: 100%;
    height: 300px;
    overflow:hidden;
    position: relative;
    background-image: url("https://gthpdf.fra1.digitaloceanspaces.com/mentions-legales-min.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .text-overlay {
      padding: 5%;
      margin: auto;
      position: absolute;
      z-index: 999;
      text-align: center;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      background-color: rgb(1, 1, 1, 0.6);
  }
  .text-overlay > h2 {
    color : #56be25;
    font-size: 40px;
  }
  .parent{
        height:max-content;
    }
    .screen_3{
        height: 80vh;
        position: relative;
    }
    .screen_3 img{
        width: 100%;
        height:100%;
    }
    .screen_3 .opacity{
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(36, 48, 100,0.4);
    }
    .screen_3 h1{
        color: #fff;
        position: absolute;
        top: 50%;
        left:35%;
        font-size: 30px;
    }
    .screen_3 a{
        color:#fff;
        position:absolute;
        top: 45%;
        left: 35%;
        text-transform: uppercase;
    }
    .screen_3 a:hover{
        border-bottom: 1px solid #fff;   
    }
    .containt_3{
        height:max-content ;
        position: relative;
       width: 90%;
       margin-left: 4%;
    }
    .containt_3 .logo{
        width: 100%;
        position: absolute;
        opacity: 0.1;
        top:200px;
        height: 900px;
        z-index: -20;
    }
    .containt_3 div{
        width:100%;
        height:25%;
        margin-top:50px ;
    }
    .security p{
      color: #616161;
    }
    .containt_3 .rows{
      display: flex;
      flex-direction:row ;
      justify-content: space-around;
      align-items: center; 
    }
    .containt_3 .rows .columns{
      width:50%;
        height:25%;
        margin-left: 20px;
    }
    .containt_3 img{
      height: 350px;
    }
    .containt_3 h1{
      color:rgb(36, 48, 100);
      font-size:30px;
      font-weight: bold;
      margin-left: 20px;
      text-transform: uppercase;
    }
    .containt_3 h2{
      color:rgb(207,31,33);
      margin-left: 20px;
      margin-bottom: 20px;
      text-align: left;
      font-size: 22px;
      font-family: Cairo,Verdana,Tahoma,Arial;
      font-style: normal;
      font-weight: 400;
    }
    .containt_3 h3{
      font-size: 17px;
      margin-left: 20px;
      margin-bottom: 15px;
      color: rgb(36, 48, 100);
      font-weight: bold;
    }
    .containt_3 h4{
      font-size: 22px;
      margin-bottom: 10px;
      margin-top: 10px;
      color: rgb(207,31,33);
      font-weight: bold;
      font-family: Cairo,Verdana,Tahoma,Arial;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
    .containt_3 .number li{
      color: rgb(36, 48, 100);
      font-family: Cairo,Verdana,Tahoma,Arial;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
    }
    .containt_3 p{
      color: #616161;
      font-size: 17px;
    }
    .presentation{
      width: 100%;
      height: max-content;
      background-color: rgba(36, 48, 100,0.2);
    }
    .containt_3 .rows{
      margin-top: 20px;
    }
    .presentation p{
      padding: 50px;
      font-size: 18px;
    }
    .environnement p{
      margin-left: 20px;
    }
    @media (max-width:700px){
      .containt_3 .rows{
      display: flex;
      flex-direction:column;
    }
    .containt_3 .rows .columns{
      width:100%;
        height:25%;
        margin-left: 20px;
        margin-bottom: 20px;
    }
    .containt_3 h1{
      font-size: 25px;
    }
  }
  
</style>
<template>
  <div class="parent">

    <div class="imgg">
      <div class="text-overlay w-full text-center text-white text-3xl font-extrabold">
        <h2>Évaluation des risques au travail</h2>
        <h3>PREVENIR LES RISQUES PROFESSIONNELS : SANTE & SECURITE PRESERVER LA SANTE ET LA SECURITE AU TRAVAIL</h3>

      </div>
    </div>

    <div class="w-full bg-white-100 text-left p-4">Nos Services <span class="text-orange-700 font-extrabold font" id="textes-reglementaires">|</span> Évaluation des risques au travail</div>


    <div class="w-full bg-zinc-100 p-10">
      <div class=" w-4/6 mx-auto mt-10 text-center text-zinc-500  ">
        <h2 class="titre text-lg font-extrabold text-black mb-10">
          De nombreux travailleurs sont exposés à des agents dangereux, quel que soit leur domaine d’activité. Ces agents sont divers : substances chimiques, bruit, vibration… De plus les activités exposantes sont variées : manutention, conduite de véhicule, utilisation de machines….        </h2>
        <p class="resume">
          La santé et la sécurité au travail occupe une place importante dans la bonne marche des entreprises en termes de protection des travailleurs, de performance et de durabilité de l’activité à long terme.
        </p>
        <p class="resume">
          Pour les chefs d’entreprise, les répercussions des négligences dans la gestion de la santé et de la sécurité au travail entrainent systématiquement des coûts supplémentaires et souvent imprévus. On trouve, notamment, les pénalités prévues par les textes réglementaires, la révision des primes d’assurance, les dépenses inhérentes aux dommages causés, l’impact négatif sur la production, les effets indirects dus à la démotivation des travailleurs, la mauvaise ambiance de travail et la perte d’expertise en cas d’accident.        </p>
      </div>
    </div>


    <div class="bottom-div">
      <div class="left-content">
        <ul>
            <li><a href="#" @click="scrollToContent('textes-reglementaires')">Les textes réglementaires</a></li>
            <li><a href="#" @click="scrollToContent('nouvelle-constitution')">Nouvelle constitution</a></li>
            <li><a href="#" @click="scrollToContent('mise-en-place')">Mise en place</a></li>
            <li><a href="#" @click="scrollToContent('prerequis')">Prérequis</a></li>
            <li><a href="#" @click="scrollToContent('conseils-pratiques')">Conseils pratiques</a></li>
            <li><a href="#" @click="scrollToContent('mission')">Les missions</a></li>
        </ul>
      </div>
      <div class="right-content">

        <div id="nouvelle-constitution" class="content-section mb-16">

         <div>
          <h5 class="font-bold text-slate-800 mt-6 mb-4">Les textes réglementaires marocains Code du travail: </h5>
          <div class="w-5/6  text-left text-zinc-500">
              <div class="flex gap-2 mb-2 items-start">  
                <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> •  </div> 	
                Le code du travail, Loi n° 65 – 99 de décembre 2003, consacre tout un chapitre à la santé et à la sécurité au travail, organisé en quatre sections :
              </div> 
              <ul>
                  <li>- Les dispositions générales (articles 281 –301) ;</li>
                  <li>- Les services médicaux du travail (articles 304 – 331) ; </li>
                  <li>- Les Comités de sécurité et d’hygiène et comités d’entreprise (articles 336 -344) ;</li>
                  <li>- Les dispositions concernant les salariés victimes d’accident de travail ou de maladie professionnelle (articles 265 – 267).</li>
              </ul>

              <div class="flex gap-2 mb-2 mt-5 items-start">  <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> •  </div> 	 	Arrêté du ministre de l’emploi et de la formation professionnelle n° 93-08 du 6 joumada I 1429 (12 mai 2008) fixant les mesures d’application générales et particulières relatives aux principes énoncés par les articles de 281 à 291 du code du travail.</div>  
           </div>
         </div>

         <div id="mise-en-place">
          <h5 class="font-bold text-slate-800 mt-6 mb-4">Nouvelle constitution marocaine : </h5>
          <div class="w-5/6  text-left text-zinc-500">
            <div class="flex gap-2 mb-2 items-start">  <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> •  </div> 	Article 31 : « L’état, les établissements publics et collectivités territoriales œuvrent à la mobilisation de tous les moyens disponibles pour faciliter l’égal accès des citoyennes et des citoyens aux conditions leur permettant de jouir du droit : aux soins de santé, à la protection sociale, à la couverture médicale et à la solidarité mutualiste ou organisée par l’Etat, à une éducation moderne accessible et de qualité, ... ». </div> 
            <div class="flex gap-2 mb-2 items-start">  <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> •  </div> 	Article 71 : « Sont du domaine de la loi, outre les matières qui lui sont expressément dévolues par d’autres articles de la constitution : les principes et règles du système de santé, les relations de travail, la sécurité sociale, les accidents de travail et les maladies professionnelles, le régime des banques, des sociétés d’assurances et des mutuelles, … ».</div>  
           </div>
         </div>

         <div id="prerequis">
          <h5 class="font-bold text-slate-800 mt-6 mb-4">MISE EN PLACE D’UN SYSTÈME DE MANAGEMENT DE LA SANTÉ ET LA SÉCURITÉ AU TRAVAIL : </h5>
          <div class="w-5/6  text-left text-zinc-500">
             <div class="text-base text-justify	 mb-2 "> Après avoir présenté la réglementation et les textes de lois auxquelles les entreprises peuvent se référer pour accentuer leur une mise en conformité, celles-ci peut aller vers la mise en place, progressive, d’un système de management de la santé et de la sécurité au travail.  </div> 	
            </div>
         </div>


         <div id="conseils-pratiques">
          <h5 class="font-bold text-slate-800 mt-6 mb-4">Prérequis: </h5>
          <div class="w-5/6  text-left text-zinc-500">
            <div class="flex gap-2 mb-2 items-start">  <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> •  </div>La volonté du chef d’entreprise, dont témoignent les moyens prêts à être engagés (personnes disponibles, budgets, programme de formation, supports de communication...) ;</div> 
            <div class="flex gap-2 mb-2 items-start">  <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> •  </div>Un objectif de conformité réglementaire ; </div> 
            <div class="flex gap-2 mb-2 items-start">  <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> •  </div>L’existence d’une culture relative à la sécurité au travail (connaissance des principes de prévention, analyse des accidents...)</div> 
            <div class="flex gap-2 mb-2 items-start">  <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> •  </div>La volonté de prise en compte des valeurs essentielles et des bonnes pratiques préconisées par le réseau prévention (voir ci-dessous). Seules des valeurs partagées et intégrées par le personnel peuvent faire évoluer les pratiques. Toute situation non conforme devra faire l’objet d’un débat ;</div> 
            <div class="flex gap-2 mb-2 items-start">  <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> •  </div>La connaissance de démarches de management similaires, basées sur l’amélioration continue des performances, pourra constituer un atout dans la mise en œuvre d’une telle démarche.</div> 
           </div>
         </div>

         <div id="mission">
          <h5 class="font-bold text-slate-800 mt-6 mb-4">Conseils pratiques pour la promotion de la santé en entreprise: </h5>
          <div class="w-5/6  text-left text-zinc-500">
            <div class="flex gap-2 mb-2 items-start">  <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> •  </div>Intégrer la santé au plus haut niveau du management de l’entreprise comme un pilier stratégique de la performance ;</div> 
            <div class="flex gap-2 mb-2 items-start">  <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> •  </div>Réaliser une analyse de l’existant et lancer un programme d’amélioration ;</div> 
            <div class="flex gap-2 mb-2 items-start">  <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> •  </div>Renforcer les compétences en santé au travail : former les managers à leur rôle ;</div> 
            <div class="flex gap-2 mb-2 items-start">  <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> •  </div>Identifier les attentes, les obstacles, les préoccupations des employés en termes de santé et bien être ;</div> 
            <div class="flex gap-2 mb-2 items-start">  <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> •  </div>Réaliser une enquête santé et consulter les employés ; </div> 
            <div class="flex gap-2 mb-2 items-start">  <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> •  </div>Adapter (quand c’est possible) le travail aux personnes ;</div> 
            <div class="flex gap-2 mb-2 items-start">  <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> •  </div>Avant chaque action, prévoir du temps pour bien définir les besoins ;</div> 
            <div class="flex gap-2 mb-2 items-start">  <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> •  </div>Associer les acteurs santé internes (médecin du travail, membres du CSH,) pour leur permettre de jouer pleinement leurs rôles.</div> 
           </div>
         </div>

         <div>
          <h5 class="font-bold text-slate-800 mt-6 mb-4">Mission : </h5>
          <div class="text-base text-justify mb-2 "> GTHCONSULT vous accompagne dans les différents domaines Santé,  et Sécurité.</div> 	
          <div class="w-5/6  text-left text-zinc-500">
            <table class="table-auto border  p-4">
              <thead class="border  p-4">
                <tr>
                  <th class="border p-4">Désignation de la Mission</th>
                  <th class="border p-4">Code</th>
                 
                </tr>
              </thead>
              <tbody class="border  p-2 ">
                <tr v-for="(item, index) in tableData" :key="index" class="border  p-2 hover:bg-zinc-100 ">
                  <td class="border  p-2 ">{{ item.disignation }}</td>
                  <td class="border  p-2 ">{{ item.code }}</td>
                </tr>
               
              </tbody>
            </table>	 
            </div>
         </div>
         
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import VueScrollTo from 'vue-scrollto';

export default {
  
  name: "NosValeurs",
   data() {
    return {
     
      tableData: [
        { disignation: "Vérification des doses de bruits absorbées par les travailleurs par dosimétrie", code: "BRUIT- VDBD" },
        { disignation: "Vérification des doses de bruits absorbées par les travailleurs code du travail", code: "BRUIT- VDBCT" },
        { disignation: "Elaboration de la cartographie bruit", code: "BRUIT- CRT" },
        { disignation: "Mesures des taux d’éclairement au poste du travail", code: "ECRM- MTE" },
        { disignation: "Mesure de la qualité de l’air intérieur", code: "AIR- MQA" },
        { disignation: "Analyse de la potabilité de l’eau", code: "EAU- POT" },
        { disignation: "Analyse des légionnelles", code: "EAU- LEG" }
      ]

    };
  },
  methods: {
    scrollToContent(link) {
      VueScrollTo.scrollTo(`#${link}`, 500, { easing: 'ease-in-out' });
    }
  }
};
</script>

<style scoped>

h5 {
    font-size: 20px;
    color: #2fd820;
}

*{
  font-family: Cairo,Verdana,Tahoma,Arial;
 }

  .titre {
      font-family: Cairo,Verdana,Tahoma,Arial;
      text-align: center;
      font-weight: 400;
      font-size: 20px;
      line-height: 120%;
      color: #00153C;
  }
  .resume {
      font-family: Cairo,Verdana,Tahoma,Arial;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      color: #616161;
      text-align: justify;
      margin-top: 10px;
  }

  .badget {
      margin-top: 20px;
      font-family: Cairo,Verdana,Tahoma,Arial;
      font-style: normal;
      font-weight: 200;
      font-size: 20px;
      line-height: 150%;
      letter-spacing: 0em;
      color: #616161;
  }
.imgg {
  width: 100%;
  height: 700px;
  overflow: hidden;
  position: relative;
  background-image: url("https://gthpdf.fra1.digitaloceanspaces.com/1.3cfb99a0.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.text-overlay {
  padding: 5%;
  margin: auto;
  position: absolute;
  z-index: 999;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(1, 1, 1, 0.6);
}

.text-overlay h1 {
    width:fit-content;
}

.text-overlay > h2 {
  color: #56be25;
  font-size: 40px;
}

.text-overlay h3 {
    margin-top: 10px;
    color: #d3d3d3;
}

.bottom-div {
  display: flex;
  flex-wrap: wrap;
}



.left-content {
  width: 25%;
  position: sticky;
  display: grid;
  align-items: center;
  top: 0;
  height: calc(100vh - 30px);
  padding: 10px;
}

.left-content ul{
  background: white;
  padding: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}.left-content ul li a{
 font-size: 12px;
}
.left-content ul li {
  padding-left: 12px;
  padding: 5px;
 }
 .left-content ul li:hover {
  background: rgb(235, 234, 234);
 }
 .left-content ul li:hover a{
  color: rgb(84, 72, 253);
 }

.right-content {
  width: 75%;
}

.content-section {
  margin-top: 20px;
}


@media screen and (max-width:1090px) {
  .bottom-div{
    display: grid;
  }
  .left-content{
    width: 80%;
    height: auto;
    margin-top: 50px;
    margin: auto;
    position: relative;
  }
  .right-content{
    width: 80%;
    margin: auto;
  }
  
}
</style>

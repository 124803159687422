<template>
  <div class="NouveauProgramme">

    <div class="box">

      <h1>Nouveau Media</h1>

        <label for="Titre">Titre</label>
        <input type="text" maxlength="400" name="text" v-model="media.titre" />

        <label for="Auteur">Auteur</label>
        <input type="text" maxlength="400" name="text" v-model="media.auteur" />

        <label for="Auteur">File ( Jpeg | video | Pdf | png )</label>
        <input type="file" multiple="multiple" id="file" ref="file" @change="previewFile">

        <button @click="enregistrer">Enregistrer</button>

    </div>

        <!--  Start Confirmation Model   -->
        <ErrorModel v-if="flagErrorModel == true" :text="text" :titre="titre" />
        <!--  End Confirmation Model   -->

        <!--  Start Info Delete Model   -->
        <LandingModel v-if="flagLandingModel == true" />
        <!--  End Info Delete Model   -->
  </div>
</template>
  
<script>
import Medias from "@/connection/Medias";
import ErrorModel from "@/components/models/ErrorModel.vue";
import LandingModel from "@/components/models/LandingModel.vue";
export default {
  name: "NouveauProgramme",
  data() {
    return {

      media: {
        titre : null,
        auteur: null
      },
      flagLandingModel : false,
      error : null,
      file : null
    }
  },
  components : {
    ErrorModel,
    LandingModel
  },
  methods : {

    previewFile() {
        this.file = this.$refs.file.files[0];
    },

    enregistrer() {
      this.flagLandingModel = true;
      Medias.create(this.media.titre, this.media.auteur, this.file)
      .then((result) => {

          if(result.data.success == true){
            this.flagLandingModel = false;
            this.media.titre = null;
            this.media.auteur = null;
            this.$refs.file.files[0] = null;
            this.file = null;
          }

      })
      .catch((error) => {
          this.flagErrorModel = true;
          this.error = error.message;
      });
    }
  },
  created() {

  } 
}
</script>
  
<style scoped>
.NouveauProgramme {
  display: flex;
  flex-direction: column;
}

.NouveauProgramme .box {
  width: auto;
  margin: 20px;
  border-radius: 8px;
  border: 2px #ddd solid;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}


.NouveauProgramme .box h1 {
  text-align: center;
  margin-bottom: 10px !important;
  font-size: x-large;
  font-weight: bold;
  font-family: "Amazon Ember", Arial, sans-serif;
}


.NouveauProgramme .box label {
  margin-bottom: 5px !important;
}

.NouveauProgramme .box input[type=text], .NouveauProgramme .box input[type=password], .NouveauProgramme .box select {
  width: 1000px;
  height: 40px;
  margin-bottom: 5px !important;
  background-color: #dddddd54;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid;
  outline: 0px;
}

.NouveauProgramme .box input[type=text]:hover,
.NouveauProgramme .box input[type=password]:hover {
  background-color: white;
  outline: 0px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  outline: 0px;
}


div.NouveauProgramme>div>button {
  margin-bottom: 5px !important;
  margin-top: 20px !important;
  background-color: #0057e3;
  color: white;
  font-size: small;
  font-weight: bold;
  font-family: "Amazon Ember", Arial, sans-serif;
  cursor: pointer;
  width: 300px;
  height: 40px;
  border-radius: 5px;
}
</style>
<template>
  <div class="parent">

    <div class="imgg">
      <div class="text-overlay w-full text-center text-white text-3xl font-extrabold">
        <h2>Contrôle réglementaire des appareils et accessoires de levage</h2>
      </div>
    </div>

    <div class="w-full bg-white-100 text-left p-4 ">LE GROUPE <span class="text-orange-700 font-extrabold font">|</span> Contrôle réglementaire et volontaire <span class="text-orange-700 font-extrabold font">|</span> Contrôle réglementaire des appareils et accessoires de levage</div>

    <div class="w-full bg-zinc-100 p-10" id="Objectif">
      <div class=" w-4/6 mx-auto mt-10 text-center text-zinc-500  ">
        <h2 class="titre text-xl font-extrabold text-black mb-10">METTRE EN CONFORMITÉ DE VOS ÉQUIPEMENTS ET INSTALLATIONS TECHNIQUE AU REGARD DE LA RÉGLEMENTATION ET AUX NORMES EN VIGUEURS</h2>
        <p class="resume">Les équipements de travail et les moyens de protection mis en service ou utilisés dans les établissements doivent être équipés, installés, utilisés, réglés et maintenus de manière à préserver la sécurité et la santé des travailleurs.
          Les équipements et les installations techniques doivent faire l’objet de contrôles, de vérifications ou d’inspections selon la réglementation en vigueur pour assurer leurs conformités réglementaires, leurs performances et leurs utilisations en toute sécurité.
          </p>
      </div>
    </div>

    <div class="bottom-div">
      <div class="left-content">
        <ul>
          <li><a href="#" @click="scrollToContent('Objectif')">Objectif</a></li>
          <li><a href="#" @click="scrollToContent('obligation-reglementaire')">Obligation réglementaire</a></li>
          <li><a href="#" @click="scrollToContent('missions')">Les missions</a></li>
        </ul>
      </div>
      <div class="right-content">

        <div id="obligation-reglementaire" class="content-section mb-16">
          <h3 class="text-2xl font-bold text-blue-700 ">CONTRÔLE RÉGLEMENTAIRE DES APPAREILS ET ACCESSOIRES DE LEVAGE</h3>
         <div>
              <h5 class="font-bold text-slate-800 mt-6 mb-4">Objectif : </h5>
              <div class="w-5/6  text-left text-zinc-500">
                <div class="text-sm mb-2 "> Les appareils (palans, ponts roulants, ponts élévateurs de véhicules, grues, hayons élévateurs…) et accessoires (élingues, clés de levage…) de levage entraînent de nombreux accidents du travail.  </div> 	
                <div class="text-sm mb-2 ">GTHCONSULT vous accompagne pour vérifier ces équipements, en accord avec la réglementation en vigueur, afin d’assurer des conditions de travail plus sûres. </div> 	 
              </div>
         </div>

         <div id="missions">
          <h5 class="font-bold text-slate-800 mt-6 mb-4">Obligation réglementaire : </h5>
          <div class="w-5/6  text-left text-zinc-500">
            <div class="flex gap-2 mb-2 items-start">  <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> •  </div> 	Arrête viziriel du 3 novembre 1953 fixant les conditions de vérification des appareils de levage autres que les ascenseurs et monte-charges, (BO n° 2142 du 13 novembre 1953, p1628). </div> 
            <div class="flex gap-2 items-start">  <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> •  </div> 	Arrête viziriel du 9 septembre 1953 déterminant les mesures particulières de sécurité relatives aux appareils de levage autres que les ascenseurs et monte-charges (BO n° 2142 du 13 novembre 1953, p1625), modifié par l’arrêté du 28 septembre 1955 (BO n° 2247 du 18 novembre).</div> 
            </div>
         </div>

         <div>
          <h5 class="font-bold text-slate-800 mt-6 mb-4">Mission : </h5>
          <div class="w-5/6  text-left text-zinc-500">
            <div class="flex gap-2 mb-2 items-start">  <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> •  </div> 	Assurer la sécurité des personnes et des biens ;</div> 
            <div class="flex gap-2 mb-2 items-start">  <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> •  </div>	Garantir la disponibilité de votre parc matériel ;</div> 
            <div class="flex gap-2 mb-2 items-start">  <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> •  </div> Optimiser vos coûts ;</div> 
            <div class="flex gap-2 mb-2 items-start">  <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> •  </div>	Vous assurer de la conformité réglementaire de vos équipements de travail.         </div> 
           </div>
         </div>

         <div>
          <h5 class="font-bold text-blue-400 mt-6 mb-4">GTHCONSULT peut vous accompagner pour les vérifications réglementaires sur tous types de matériels : </h5>
          <div class="w-5/6  text-left text-zinc-500">
            <div class="flex gap-2 mb-2 items-start">  <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> •  </div> Vérifications générales périodiques ;</div> 
            <div class="flex gap-2 mb-2 items-start">  <div class="text-2xl mb-3 font-bold rounded-full text-lime-500"> •  </div>	Vérifications préalables à la mise ou remise en service comportant des examens de montage et d’installation, des épreuves statiques et dynamiques, voire une assistance aux examens d’adéquation ;</div> 
            <div class="flex gap-2 mb-2 items-start">  <div class="text-2xl mb-2 font-bold rounded-full text-lime-500"> •  </div> Vérifications de l’état de conformité des appareils neufs ou en service.</div> 
           </div>
         </div>

         <div>
          <h5 class="font-bold text-slate-800 mt-6 mb-4">Pour assurer le maintien en état de la conformité des appareils de levage et de manutention, Retrouvez ci-dessous l'ensemble des missions afférentes assurées par GTHCONSULT: </h5>
          <div class="w-5/6  text-left text-zinc-500">
            <table class="table-auto border  p-4">
             
              <tbody class="border  p-2 ">
                <tr v-for="(item, index) in tableDataTwo" :key="index" class="border  p-2 hover:bg-zinc-100 ">
                  <td class="border  p-2 ">{{ item.disignation }}</td>
                  <td class="border  p-2 ">{{ item.code }}</td>
                </tr>
               
              </tbody>
            </table>	 
            </div>
         </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import VueScrollTo from 'vue-scrollto';

export default {
  
  name: "NosValeurs",
   data() {
    return {
     
      tableData: [
        { disignation: "Vérification réglementaire initial d’installation électrique ", code: "ELEC-VRI" },
        { disignation: "Vérification réglementaire périodique d’installation électrique", code: "	ELEC-VRP" },
        { disignation: "Vérification réglementaire périodique d’installation photovoltaïque", code: "ELEC-VRPPV" },
        { disignation: "Vérification réglementaire périodique d’installation parafoudre - paratonnerre ", code: "ELEC-VRPPP" },
        { disignation: "Vérification de levée des réserves suite vérification réglementaire d’installation électrique", code: "ELEC-VLRVR" },
        { disignation: "Vérification d'installation électrique par thermographie infrarouge sans Q19", code: "ELEC-VTH" },
        { disignation: "Vérification d'installation électrique par thermographie infrarouge avec Q19", code: "ELEC- VTHQ19" },
        { disignation: "Vérification d’installation électrique par analyseur de réseau	ELEC- VAR", code: "v" },
        { disignation: "Diagnostique d’une installation électrique", code: "ELEC- D" },
        { disignation: "Assistance technique installation électrique", code: "ELEC- AT" },
      ],
      tableDataTwo: [
        { disignation: " Vérification réglementaire de mise ou remise en service d’appareils et accessoires de levage ", code: "LEV-VRMRS" },
        { disignation: "Vérification réglementaire périodique d’appareils et accessoires de levage", code: "LEV- VRP" },
        { disignation: "Vérification de levée des réserves suite Vérification réglementaire d’appareils et accessoires de levage", code: "LEV- VLRVR" },
        { disignation: "Diagnostique d’appareils et accessoires de levage	 ", code: "LEV- DIAG" },
       
      ],

      tableDataThre: [
        { disignation: "  Vérification Générale Périodique Trimestriel machine ", code: "MAC-VGPT" },
        { disignation: "Vérification Générale Périodique Annuelle machine", code: "MAC-VGPA" },
        { disignation: "Vérification de la conformité CE machine", code: "MAC-VCCE" },
        { disignation: "Analyses des risques d'une machine	 ", code: "MAC-AR" },
       
      ],

      tableDataFourth: [
        { disignation: "  Vérification Générale Périodique Trimestriel machine ", code: "MAC-VGPT" },
        { disignation: "Vérification Générale Périodique Annuelle machine", code: "MAC-VGPA" },
        { disignation: "Vérification de la conformité CE machine", code: "MAC-VCCE" },
        { disignation: "Analyses des risques d'une machine	 ", code: "MAC-AR" },
       
      ],
      
      tableDataFifth: [
        { disignation: "  Vérification Générale Périodique Trimestriel machine ", code: "MAC-VGPT" },
        { disignation: "Vérification Générale Périodique Annuelle machine", code: "MAC-VGPA" },
        { disignation: "Vérification de la conformité CE machine", code: "MAC-VCCE" },
        { disignation: "Analyses des risques d'une machine	 ", code: "MAC-AR" },
       
      ],
	
	


	
                                                                                                         
                                                                                                                      


    };
  },
  methods: {
    scrollToContent(link) {
      VueScrollTo.scrollTo(`#${link}`, 500, { easing: 'ease-in-out' });
    }
  }
};
</script>

<style scoped>

h5 {
    font-size: 20px;
    color: #2fd820;
}
.titre {
    text-align: center;
    font-size: xx-large;
    font-weight: 700;
    font-size: 1.77777778rem;
    line-height: 120%;
    color: #00153C;
}
.resume {
    font-family: Cairo,Verdana,Tahoma,Arial;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #616161;
    text-align: justify;
}

.badget {
    margin-top: 20px;
    font-family: Cairo,Verdana,Tahoma,Arial;
    font-style: normal;
    font-weight: 200;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0em;
    color: #616161;
}
.imgg {
  width: 100%;
  height: 700px;
  overflow: hidden;
  position: relative;
  background-image: url("https://gthpdf.fra1.digitaloceanspaces.com/construction-site-building-min.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.text-overlay {
  padding: 5%;
  margin: auto;
  position: absolute;
  z-index: 999;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(1, 1, 1, 0.6);
}

.text-overlay h1 {
    width:fit-content;
}

.text-overlay > h2 {
  color: #56be25;
  font-size: 40px;
}

.bottom-div {
  display: flex;
  flex-wrap: wrap;
}



.left-content {
  width: 25%;
  position: sticky;
  display: grid;
  align-items: center;
  top: 0;
  height: calc(100vh - 30px);
  padding: 10px;
}

.left-content ul{
  background: white;
  padding: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}.left-content ul li a{
 font-size: 12px;
}
.left-content ul li {
  padding-left: 12px;
  padding: 5px;
 }
 .left-content ul li:hover {
  background: rgb(235, 234, 234);
 }
 .left-content ul li:hover a{
  color: rgb(84, 72, 253);
 }

.right-content {
  width: 75%;
}

.content-section {
  margin-top: 20px;
}


@media screen and (max-width:1090px) {
  .bottom-div{
    display: grid;
  }
  .left-content{
    width: 80%;
    height: auto;
    margin-top: 50px;
    margin: auto;
    position: relative;
  }
  .right-content{
    width: 80%;
    margin: auto;
  }
  
}
</style>
import axios from 'axios'
import config from "@/config"

// class Interlocuteur
class Observations {

    //--------------------------------------- Envoyer Observation --------------------------------------------
    static envoyer(token, observation) {

        return new Promise((resolve, reject) => {

            axios.defaults.headers.common["Authorization"] = token;
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+token
                },
            };

            axios.post(`${config.launch}/observations/envoyer`,
            {
                observation : observation,
            },
             axiosConfig
            )
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        });

    }

}

export default Observations;
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faEyeSlash, faHeadset, faCircleDown, faPersonDigging, faMedal, faBriefcase, faBuilding, faMap, faChevronLeft, faChevronRight, faAngleRight, faHandshake, faPhone, faCode, faFilePdf, faUserSecret, faArrowRight, faEnvelope, faKey, faEye, faUser, faAnglesLeft, faAnglesRight, faChessRook ,faChessKnight,faChess,faGlobe,faBrain,faChessBoard} from '@fortawesome/free-solid-svg-icons'
/* add icons to the library */
library.add(faEyeSlash, faHeadset, faCircleDown, faPersonDigging, faMedal, faBriefcase, faBuilding, faMap, faChevronLeft, faChevronRight, faAngleRight, faHandshake, faPhone, faCode, faFilePdf, faUserSecret, faArrowRight, faEnvelope, faKey, faEye, faUser, faAnglesLeft, faAnglesRight ,faChessRook , faChessKnight,faChess,faGlobe,faBrain,faChessBoard);

// import Style Géneral
import "./assets/style.css"
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

createApp(App).use(router).component('font-awesome-icon', FontAwesomeIcon).mount('#app')

import axios from 'axios'
import config from "@/config"

// class Messages
class Medias {

    //--------------------------------------- create Medias --------------------------------------------
    static create(titre, auteur, file) {

        var FormData = require('form-data');

        let form = new FormData();

        form.append(`file`, file);
        form.append(`titre`, titre);
        form.append(`auteur`, auteur);

        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/medias/create`, form,
             {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                    resolve(response);
            })
            .catch(error => {
                    reject(error);
            });
    
        });
        
    }

    // get Rapport
    static displayMedia(filename) {
        return new Promise((resolve, reject) => {
            axios.get(`${config.launch}/medias/display/${filename}`, {
                headers: this.headers,
                responseType: 'blob',
            })
            .then(response => {
                resolve(window.open(URL.createObjectURL(response.data)));
            })
            .catch(error => {
                reject(error.response.data);
            });
        })
    }

    static read() {
        return new Promise((resolve, reject) => {
            axios.get(`${config.launch}/medias/read`, {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    static update(mediaId, titre, auteur, filename, file) {

        var FormData = require('form-data');
        let form = new FormData();
        form.append(`file`, file);
        form.append(`titre`, titre);
        form.append(`auteur`, auteur);
        form.append(`mediaId`, mediaId);
        form.append(`filename`, filename);

        return new Promise((resolve, reject) => {
            axios.put(`${config.launch}/medias/update`, form,
             {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                    resolve(response);
            })
            .catch(error => {
                    reject(error);
            });
    
        });
        
    }


    static delete(mediaId, filename) {
        return new Promise((resolve, reject) => {
            axios.delete(`${config.launch}/medias/delete/${mediaId}/${filename}`,)
            .then(response => {
                    resolve(response);
            })
            .catch(error => {
                    reject(error);
            });
        })
    }
}

export default Medias;
<template>
  <div class="NouveauProgramme">

    <div class="box">

      <h1>Modifier Programme</h1>

        <label for="Titre de Programme">Titre de Programme</label>
        <input type="text" maxlength="400" name="text" v-model="programme.titre" />

        <label for="Auteur">Auteur</label>
        <input type="text" maxlength="400" name="text" v-model="programme.auteur" />

        <button @click="modifier">Modifier</button>

    </div>

        <!--  Start Confirmation Model   -->
        <ErrorModel v-if="flagErrorModel == true" :text="text" :titre="titre" />
        <!--  End Confirmation Model   -->

        <!--  Start Info Delete Model   -->
        <LandingModel v-if="flagLandingModel == true" />
        <!--  End Info Delete Model   -->
  </div>
</template>
  
<script>
import Programmes from "@/connection/Programmes";
import ErrorModel from "@/components/models/ErrorModel.vue";
import LandingModel from "@/components/models/LandingModel.vue";
export default {
  name: "NouveauProgramme",
  data() {
    return {

      programme: {
        titre: null,
        auteur: null,
        formationId : null
      },
      formations : [],
      flagLandingModel : false,
      error : null,
    }
  },

  props : {
      programmeId : String
  },

  components : {
    ErrorModel,
    LandingModel
  },
  methods : {
    modifier() {
      this.flagLandingModel = true;
      Programmes.update(this.programme, this.programmeId)
      .then((result) => {
          if(result.data.success) {
            const router = this.$router
            router.go({path: '/elearning'});
          }
      })
      .catch((error) => {
          this.flagErrorModel = true;
          this.error = error.message;
      });
    }
  },
  created() {

    Programmes.select(this.programmeId)
    .then((result) => {
      this.programme.titre = result.data.programme.titre;
      this.programme.auteur = result.data.programme.auteur;
    })
    .catch((error) => {
      console.log(error);
    });
  } 
}
</script>
  
<style scoped>
.NouveauProgramme {
  display: flex;
  flex-direction: column;
}

.NouveauProgramme .box {
  width: auto;
  margin: 20px;
  border-radius: 8px;
  border: 2px #ddd solid;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}


.NouveauProgramme .box h1 {
  text-align: center;
  margin-bottom: 10px !important;
  font-size: x-large;
  font-weight: bold;
  font-family: "Amazon Ember", Arial, sans-serif;
}


.NouveauProgramme .box label {
  margin-bottom: 5px !important;
}

.NouveauProgramme .box input[type=text], .NouveauProgramme .box input[type=password], .NouveauProgramme .box select {
  width: 1000px;
  height: 40px;
  margin-bottom: 5px !important;
  background-color: #dddddd54;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid;
  outline: 0px;
}

.NouveauProgramme .box input[type=text]:hover,
.NouveauProgramme .box input[type=password]:hover {
  background-color: white;
  outline: 0px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  outline: 0px;
}


div.NouveauProgramme>div>button {
  margin-bottom: 5px !important;
  margin-top: 20px !important;
  background-color: #0057e3;
  color: white;
  font-size: small;
  font-weight: bold;
  font-family: "Amazon Ember", Arial, sans-serif;
  cursor: pointer;
  width: 300px;
  height: 40px;
  border-radius: 5px;
}
</style>


<template>
  <div class="carrieres w-full text-zinc-500">

    <div class="imgg">
      <div class="text-overlay w-full text-center text-white text-3xl font-extrabold">
        <h2> Nos Valeurs </h2>
      </div>
    </div>

    <!-- <div class="titre text-left p-4 bg-slate-300">LE GROUPE <span class="text-orange-700 font-extrabold font">|</span> Nos Valeurs</div> -->


    <div class="w-full mx-auto mt-10">
      <div class="w-4/6 mx-auto text-center ">
       <h2 class="titre font-bold text-4xl text-blue-600"> UNE PARFAITE MAITRISE DES RISQUES</h2>
       <p class="resume mt-4 mb-4">
        La réussite de <san class="gthconsult">GTHCONSULT</san> est fondée sur le haut niveau des compétences techniques de ses collaborateurs et sur le respect scrupuleux d'une éthique professionnelle basée sur l'intégrité, l'impartialité et de professionnalisme.
        GTHCONSULT travaille tous les jours pour une parfaite maîtrise de risque.
       </p>
        <p class="resume mt-1 mb-10">Si toutes ces actions ci-dessous combinées finissent par donner un mot "RISQUE"</p>
     
      </div>
    </div>
   <div class="all w-5/6 mt-24 mx-auto  flex justify-center">
    <div class="div w-1/3">
        <div class="top">
          <h1 class="font-bold text-2xl text-blue-600 " data-aos="fade-up-right">RÉACTIVITÉ - DISPONIBILITÉ</h1>
          <p class="badget" data-aos="fade-up-right" data-aos-duration="1000">Nos collaborateurs placent le client au cœur de chaque action en faveur de la trinité suivante : plus rapide, meilleure qualité et meilleur prix. </p>
        </div>
        <div class="bottom mt-24">
          <h1 class="font-bold text-2xl text-blue-600"  data-aos="fade-up-right">ESPRIT D'ÉQUIPE</h1>
          <p class="badget"  data-aos="fade-up-right" data-aos-duration="1000">L'esprit d'équipe est un élément fédérateur chez tous les collaborateurs de GTHCONSULT.
            Chacun de nos membres participe à un objectif commun pour créer un monde plus sûr.
            </p>
        </div>
    </div>
    <div class="img w-1/3" data-aos="fade-up" data-aos-duration="1000"><img src="@/assets/valeur.png" alt=""></div>
    <div class="div w-1/3">
      <div class="top">
        <h1 class="font-bold text-2xl text-blue-600" data-aos="fade-up-left">INDÉPENDANCE - IMPARTIALITÉ        </h1>
        <p class="badget" data-aos="fade-up-left " data-aos-duration="1000">En tant qu'organisme tierce partie, nous garantissons impartialité et transparence vis-à-vis de nos clients et des acteurs économiques (fournisseurs, fabricants, etc.). Des approches transversales co-construites avec nos clients permettent d’accroitre la valeur apportée.
        </p>
      </div>
      <div class="bottom mt-24">
        <h1 class="font-bold text-2xl text-blue-600" data-aos="fade-up-left">SENS CLIENT</h1>
        <p class="badget" data-aos="fade-up-left"  data-aos-duration="1000">
          GTHCONSULT tisse des liens de confiance forts avec chacun de ses clients, en ayant une écoute attentive de leurs besoins.

          </p>
      </div>
  </div>
   </div>
  <div class=" w-5/6 mt-24 mx-auto  flex justify-center">
    <div class="grid w-2/6 fifth">
    <h1 class="font-bold text-2xl text-blue-600" data-aos="fade-up">QUALITÉ    </h1>
    <p class="badget" data-aos="fade-up">
      C'est par notr e connaissance du métier, notre exigence de qualité et de transparence que nous gagnerons la confiance et fidéliserons nos clients.

      </p>
    </div>
    </div>
  </div>
</template>

<script>

export default {
  name : "OffreEmploiView",
 

}
</script>

<style scoped>

*{
  font-family: Cairo,Verdana,Tahoma,Arial;
}

.gthconsult {
        font-weight: bold;
}

.titre {
    text-align: center;
    font-size: xx-large;
    font-weight: 700;
    font-size: 1.77777778rem;
    line-height: 120%;
    color: #00153C;
    letter-spacing: .02em;
}

.resume {
    font-family: Cairo,Verdana,Tahoma,Arial;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0em;
    color: #616161;
    text-align: justify;
}

.badget {
    margin-top: 20px;
    font-family: Cairo,Verdana,Tahoma,Arial;
    font-style: normal;
    font-weight: 200;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0em;
    color: #616161;
}

.imgg {
  
  width: 100%;
  height: 500px;
  overflow:hidden;
  position: relative;
  background-image: url("https://gthpdf.fra1.digitaloceanspaces.com/mentions-legales-min.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.text-overlay {
    padding: 5%;
    margin: auto;
    position: absolute;
    z-index: 999;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: rgb(1, 1, 1, 0.6);
}
.text-overlay > h2 {
  color : #56be25;
  font-size: 40px;
}

.fifth{
margin-top: -90px;
margin-bottom: 100px;
}


@media screen  and (max-width:1300px) {
  .bottom{
    margin-top: -0px;
  }
  
}
@media screen  and (max-width:1200px) {
  .img{
   display:none;
  }
  .all{
    display: grid;
  }
  .div{
    width:90%;
    margin: auto;
  }
  .div div {
    margin-bottom: 30px;
   
  }
  .fifth{
    width: 90%;
    text-align: left;
    margin-top: -90px;
  }

}

</style>
import axios from 'axios'
import config from "@/config"

class Offres {

    // get Rapport
    static read() {
        return new Promise((resolve, reject) => {
            axios.get(`${config.launch}/offres/read`, {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    static selectJob(jobId) {
        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/offres/select`,
            {
                offreId : jobId
            },
            {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }
}

export default Offres;
<template>
  <div class="parent text-center">

    <div class="imgg">
      <div class="text-overlay w-full text-center text-white text-3xl font-extrabold">
        <h2>À propos de GTHCONSULT</h2>
      </div>
    </div>

    <!-- <div class="titre text-left p-4 bg-slate-300">LE GROUPE <span class="text-orange-700 font-extrabold font">|</span> À propos</div> -->

    <div class="contenu p-20">
      <h2 class="titre text-3xl font-bold text-indigo-800">NOTRE CHEMIN VERS UNE HISTOIRE DE CONFIANCE ET DE LA COMPÉTENCE</h2>
      <p class="resume text-zinc-500 mt-10">Depuis sa création, <span class="gthconsult">GTHCONSULT</span> est grandir pas à pas, entre l’accroissement de nos personnels et l’acquisition de nouvelles compétences pour chaque de nos métiers. Nous avançons chaque jour vers un avenir encore plus prometteur. Découvrez toutes nos années-clés et notre évolution de carrière.
    </p>
    </div>
    <div class="propos">
    <div class="flex flex-row ">
      <div class="w-full  flex items-center  justify-center pb-4 pt-4">
        <div class="grid justify-center w-3/6" data-aos="fade-right">
          <h1 class="text-2xl font-bold ">2019</h1>
          <p class="badget text-lg text center text-zinc-500 ">Création de la société GTHCONSULT 
            et obtention de notre 
            premier agrément 
            Ascenseurs & Monte-charge accompagnée
            Délivré par le Ministère de l'Equipement et de Transport.
            </p>
        </div>
      </div>
      <div class="borderCenter bg-orange-500" >
        <div class="cercle bg-orange-500  flex items-center justify-center">
          <div class="cercle2 bg-white"></div>
        </div>
      </div>
      <div class="w-full flex items-center justify-center">
        <div class="mx-auto" data-aos="fade-left">
          <div class="content1 moving-element2 grid items-center pl-4 bg-orange-500 ">
            <div class="img bg-white ">

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row ">
      <div class="w-full flex items-center justify-center pt-4 pb-4" data-aos="fade-left">
        <div class="flex items-center justify-right ">
         <div class="content moving-element grid items-center pl-4 bg-red-600">
          <div class="img bg-white">

          </div>
         </div>
        </div>
      </div>
      <div class="borderCenter bg-red-600">
        <div class="cercle bg-red-600  flex items-center justify-center">
          <div class="cercle2 bg-white"></div>
        </div>
      </div>
      <div class="w-full  flex items-center justify-center">
        <div class="grid justify-center w-3/6" data-aos="fade-right">
          <h1 class="text-2xl font-bold">2020</h1>
          <p class="badget text-lg pb-10 text-zinc-500  ">Obtention 2éme agrément Appareil et accessoire de levage 
            Délivré par le Ministère de L'Emploi.
            </p>
        </div>
      </div>
    </div>
    <div class="flex flex-row ">
      <div class="w-full  flex items-center  justify-center pb-4 pt-4">
        <div class="grid justify-center w-3/6" data-aos="fade-right">
          <h1 class="text-2xl font-bold">2021</h1>
            <p class="badget text-lg text center pb-10 text-zinc-500">
              Obtention 3éme agrément Electricité 
              Délivré par le Ministère de L'Emploi.
            </p>
        </div>
      </div>
      <div class="borderCenter bg-rose-400">
        <div class="cercle bg-rose-400  flex items-center justify-center">
          <div class="cercle2 bg-white"></div>
        </div>
      </div>
      <div class="w-full flex items-center justify-center">
        <div class="mx-auto" data-aos="fade-left">
          <div class="content1 moving-element2 grid items-center pl-4 bg-rose-400 ">
            <div class="img bg-white ">

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row ">
      <div class="w-full flex items-center justify-center pt-4 pb-4">
        <div class="flex items-center justify-right " data-aos="fade-left">
         <div class="content moving-element grid items-center pl-4 bg-fuchsia-600">
          <div class="img bg-white">

          </div>
         </div>
        </div>
      </div>
      <div class="borderCenter bg-fuchsia-600">
        <div class="cercle bg-fuchsia-600  flex items-center justify-center">
          <div class="cercle2 bg-white"></div>
        </div>
      </div>
      <div class="w-full  flex items-center justify-center">
        <div class="grid justify-center  w-3/6" data-aos="fade-right">
          <h1 class="text-2xl font-bold">2022</h1>
          <p class="badget text-lg pb-10 text-zinc-500 ">Obtention 4éme agrément Machine
            Délivré par le Ministère de L'Emploi.
            </p>
        </div>
      </div>
    </div>
    <div class="flex flex-row ">
      <div class="w-full  flex items-center  justify-center pb-4 pt-4">
        <div class="grid justify-center w-3/6" data-aos="fade-right">
          <h1 class="text-2xl font-bold ">2023</h1>
          <p class="badget text-lg text center pb-10 text-zinc-500">
            Certification ISO 9001 AFNOR
            </p>
        </div>
      </div>
      <div class="borderCenter bg-blue-700">
        <div class="cercle bg-blue-700  flex items-center justify-center">
          <div class="cercle2 bg-white"></div>
        </div>
      </div>
      <div class="w-full flex items-center justify-center">
        <div class="mx-auto" data-aos="fade-left">
          <div class="content1 moving-element2 grid items-center pl-4 bg-blue-700 ">
            <div class="img bg-white ">

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row ">
      <div class="w-full  flex items-center  justify-center pb-4 pt-4">
        <div class="grid justify-center w-3/6">
         
        </div>
      </div>
      <div class=" ">
        <div class="triangle   flex items-center justify-center">
         
        </div>
      </div>
      <div class="w-full flex items-center justify-center">
        <div class="mx-auto">
         
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped>

*{
  font-family: Cairo,Verdana,Tahoma,Arial;
}


.gthconsult {
        font-weight: bold;
}

.contenu {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.contenu > p {
  max-width: 80%;
}

.titre {
    text-align: center;
    font-size: xx-large;
    font-weight: 700;
    font-size: 1.77777778rem;
    line-height: 120%;
    color: #00153C;
}
.resume {
    font-family: Cairo,Verdana,Tahoma,Arial;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #616161;
    text-align: justify;
}

.badget {
    margin-top: 20px;
    font-family: Cairo,Verdana,Tahoma,Arial;
    font-style: normal;
    font-weight: 200;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0em;
    color: #616161;
}

.imgg {
  width: 100%;
  height: 500px;
  overflow:hidden;
  position: relative;
  background-image: url("https://gthpdf.fra1.digitaloceanspaces.com/mentions-legales-min.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.borderCenter {
  width:10px;
  height: auto;
}
.text-overlay {
    padding: 5%;
    margin: auto;
    position: absolute;
    z-index: 999;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: rgb(1, 1, 1, 0.6);
}
.text-overlay > h2 {
  color : #56be25;
  font-size: 40px;
}

.cercle{
  position: absolute;
  width:30px;
  height: 30px;
  margin-top: 60px;
  margin-left: -12px;
  border-radius: 50px;
}
.cercle2{
  width:19px;
  height: 19px;
  border-radius: 50px;
}
.content{
  width: 80px;
  height: 80px;
  transform: rotate(50deg);
  border-radius: 50% 0 50% 50%;
}
.content1{
  width: 80px;
  height: 80px;
  transform: rotate(50deg);
  border-radius: 50% 50% 50% 0%;
  animation: move 4s infinite;
}
.img{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 30px solid rgb(38, 60, 201); /* Change color as needed */
}
.moving-element {

  position: relative;
  animation: move 1.5s infinite;
}
.moving-element2{
  position: relative;
  animation: movee 1.5s infinite;
}

@keyframes move {
  0% {
    left: 0;
  }
  50% {
    left: -6px;
  }
  100% {
    left: 0;
  }
}

@keyframes movee {
  0% {
    right: 0;
  }
  50% {
    right: -6px;
  }
  100% {
    right: 0;
  }
}
@media screen and (max-width: 1170px) {
 
  .text-overlay {
  
   margin-top:-400px;
   
  }
}
@media screen and (max-width: 900px) {
  .cercle{
  display: none;
  }
  .cercle2{
   display: none;
  }
  .text-overlay {
  
   margin-top:-300px;
   
  }
}
@media screen and (max-width: 700px) {
 
  .text-overlay {
  
   margin-top:-200px;
   
  }
}
@media screen and (max-width: 500px) {
 
  .text-overlay {
  
   margin-top:-100px;
   
  }
}

</style>

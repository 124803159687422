import axios from 'axios'
import config from "@/config"

// class Devis
class Devis {

    //--------------------------------------- send Devis --------------------------------------------
    static sendDevis(titrePrestation, codePrestation, typePrestation, categoriePrestation, name, entreprise, location, codePostal, email, telephone) {

        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/devis/send`,
            {
                name : name,
                email : email,
                entreprise : entreprise,
                location : location,
                codePostal : codePostal,
                titrePrestation : titrePrestation,
                codePrestation : codePrestation,
                typePrestation : typePrestation,
                categoriePrestation : categoriePrestation,
                telephone : telephone
            },
            {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }


}


export default Devis;

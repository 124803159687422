<template>
    <div class="parent">
    </div>
  </template>
  
  <script>
  export default {
    name : "ErrorView",
    data() {
      return {

      }
    }
  }
  </script>
  
  <style scoped>
    .parent {
      background-image: url("https://gthpdf.fra1.digitaloceanspaces.com/404-V3.png");
      background-repeat: no-repeat;
      height: 100vh;
      width: 100%;
      background-size: cover;
    }
  </style>